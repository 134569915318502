import { FormGroup, FormControl, Validators } from "@angular/forms";

export class StateRecordModel {

    id: string;
    countryId: {
        id:string,
        name:string,
        code:string
    };
    stateId: {
        id:string,
        name:string,
        code:string,
        country:string
    };
    speciesId: {
        id: string;
        commonName: string;
        scientificName: string;
    }
    majorValue: string;
    majorUnit: string;
    minorValue: string;
    minorUnit: string;    
}

export class StateRecordModelFormBuilder {
    stateRecord: StateRecordModel = {
        id: null,
        countryId: null,
        speciesId: null,
        stateId: null,
        majorValue: null,
        majorUnit: '\u0027',
        minorValue: null,
        minorUnit:  '\u0027',
    }

    stateRecordForm: FormGroup = new FormGroup({
        id: new FormControl(this.stateRecord.id),
        countryId: new FormControl(this.stateRecord.countryId, [Validators.required]),
        speciesId: new FormControl(this.stateRecord.speciesId, [Validators.required]),
        stateId: new FormControl(this.stateRecord.stateId, [Validators.required]),
        majorValue: new FormControl(this.stateRecord.majorValue, [Validators.required]),
        majorUnit: new FormControl(this.stateRecord.majorUnit, [Validators.required]),
        minorValue: new FormControl(this.stateRecord.minorValue),
        minorUnit: new FormControl(this.stateRecord.minorUnit),
    });
   
    constructor() {
        this.stateRecordForm.valueChanges.subscribe(val => {
            this.stateRecord.id = val.id;
            this.stateRecord.countryId = val.countryId;
            this.stateRecord.speciesId = val.speciesId;
            this.stateRecord.stateId = val.stateId;
            this.stateRecord.majorValue = val.majorValue;
            this.stateRecord.majorUnit = val.majorUnit;
            this.stateRecord.minorValue = val.minorValue;
            this.stateRecord.minorUnit = val.minorUnit;
        });
    }

    setModelVals(res: any) {
            this.stateRecordForm.controls.id.setValue(res.id),
            this.stateRecordForm.controls.countryId.setValue(res.countryId),
            this.stateRecordForm.controls.speciesId.setValue(res.speciesId),
            this.stateRecordForm.controls.stateId.setValue(res.stateId);
            this.stateRecordForm.controls.majorValue.setValue(res.majorValue);
            this.stateRecordForm.controls.majorUnit.setValue(res.majorUnit);
            this.stateRecordForm.controls.minorValue.setValue(res.minorValue);
            this.stateRecordForm.controls.minorUnit.setValue(res.minorUnit);
    }
}


