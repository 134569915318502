import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { PingService } from './ping.service';
import { UserModel } from '../models/user-model';
import { UserService } from './user.service';
import { Subscription, interval } from 'rxjs';

@Injectable()
export class AuthenticationService {
    userData: firebase.User;
    subscription: Subscription;

    constructor(private angularFireAuth: AngularFireAuth,
        private route: Router, private msgService: NzMessageService,private pingService: PingService,private modalService: NzModalService,private userService: UserService) {    
    }

    getIdToken(){
        // this firebase method returns token if available, if not available it do refresh and get new token        
        //convert promise into observable 
        if(this.angularFireAuth.auth.currentUser){
            return from(this.angularFireAuth.auth.currentUser.getIdToken());
        }else{
            return this.angularFireAuth.idToken;
        }
    }

    signIn(email: string, password: string) {
        this.angularFireAuth
            .auth
            .signInWithEmailAndPassword(email, password)
            .then(res => {
                //console.log("USER DATA WHILE SIGN IN => "+JSON.stringify(res));
                sessionStorage.setItem('userName', res.user.displayName ? res.user.displayName : '');
                sessionStorage.setItem('email', res.user.email);
                sessionStorage.setItem('emailVerified', JSON.stringify(res.user.emailVerified));
                sessionStorage.setItem('isLoggedIn', 'true');
                sessionStorage.setItem('phoneNumber', res.user.phoneNumber);
                sessionStorage.setItem('uid', res.user.uid);
                sessionStorage.setItem('photoURL', res.user.photoURL);
                this.subscription = this.angularFireAuth.idTokenResult.subscribe( res => {

                    //console.log(" Get id token result =>"+JSON.stringify(res));
                    // In case of change password execute this code so add fix for issue of redirection during change password.
                    if (window.location.hash == '#/profile') {
                        this.route.navigate(['/profile']);
                    } else {
                    // we don't use token from session storage but just kept it
                //    sessionStorage.setItem('accessToken', res.token);
                    this.subscription.unsubscribe();
                    this.pingService.ping().subscribe((res) => {
                    });
                    if (res.claims.TRIP_RESEARCHER) {
                        sessionStorage.setItem('role', "TRIP_RESEARCHER");
                        this.route.navigate(['/dashboard']);
                    }
                    else if (res.claims.TRIP_ADMIN) {
                        sessionStorage.setItem('role', "TRIP_ADMIN");
                        this.route.navigate(['/dashboard']);
                    }
                    else if (res.claims.REGULATION_MANAGER) {
                        sessionStorage.setItem('role', "REGULATION_MANAGER");
                        this.route.navigate(['/regulations']);
                    }
                    else if (res.claims.ADMIN) {
                        sessionStorage.setItem('role', "ADMIN");
                        this.route.navigate(['/dashboard']);
                    }
                }
                }); 
            })
            .catch(err => {
                // Remove the existing error msgs
                this.msgService.remove();
                console.log('Incorrect email or password.', err.message);
                var message = err.message;
                if(err.code && (err.code == 'auth/user-not-found' || err.code == 'auth/wrong-password')){
                    this.msgService.error("Username or password is invalid.",{ nzDuration: 2000 });    
                }else{
                    this.msgService.error(err.message,{ nzDuration: 2000,nzAnimate: false });
                }    
            });
             
    }

    signOut() {
        this.msgService.remove();
        this.angularFireAuth
            .auth
            .signOut();
        sessionStorage.clear();
        this.route.navigate(['/signIn']);
    }


    forgetPassword(email:any) : Promise<any> {
        return this.angularFireAuth.auth.sendPasswordResetEmail(email).then(res => {
            return Promise.resolve(res);
        }).catch(err => { 
            return Promise.reject(err);
        });
    }

    /**
     * Update the existing password.
     * @param newPassword 
     */
    changePassword(newPassword:any) {
            if (sessionStorage.getItem('isLoggedIn')) {
                this.angularFireAuth.auth.currentUser.updatePassword(newPassword).then(res => {
                    this.msgService.remove();                    
                    this.changePasswordConfirmBox();
                }).catch(err => { 
                    this.msgService.error(err,{nzDuration:0});
                });    
            } else {
                this.msgService.error("Please signin to continue.",{nzDuration:0});
            }         
    }

    /**
     * Reauthenticate user credential and then update the password. 
     * @param email 
     * @param password 
     * @param newPassword 
     */
    userAuthenticationForChangePassword(email:any,password:any,newPassword:any): Promise<any>{
        var msgId = this.msgService.loading("Updating...").messageId;
        return this.angularFireAuth
            .auth
            .signInWithEmailAndPassword(email, password).then( res => {
                this.changePassword(newPassword);       
                return Promise.resolve(res);
            })
            .catch( err => {
                this.msgService.remove(msgId);  
                console.log("Error in case of re-sign while change password ! "+JSON.stringify(err));
                this.msgService.error(err.message);
                setTimeout(() => {
                   this.msgService.remove();
                }, 2000);
                return Promise.reject(err);
            })
            
    }

    /**
     * To show success confirmation box after change password process
     */
    changePasswordConfirmBox() {
        this.modalService.success({
            nzTitle: 'Password updated successfully.'
        });
    }

    isRegulationManager(){
        return sessionStorage.getItem('role') == "REGULATION_MANAGER";
    }
    isAmin(){
        return sessionStorage.getItem('role') == "ADMIN";
    }
    isResearcher(){
        return sessionStorage.getItem('role') == "TRIP_RESEARCHER";
    }
    isTripAdmin(){
        return sessionStorage.getItem('role') == "TRIP_ADMIN";
    }

    /**
     * This method is responsible for updating user details
     */
    //updateUserDetails(userModel:UserModel) : Promise<any> {
        
        // AuthCredential credential;
       // this.angularFireAuth.auth.currentUser.updatePhoneNumber(userModel.phoneNumber(String));

        // this.angularFireAuth.auth.currentUser.updateEmail(userModel.email).then(function () {
        //     console.log("Email updated successfully !")
        // }).catch(function (error) {
        //     console.log("Error while update user email: " + error)
        //     this.msgService.error(error);
        //     setTimeout(() => {
        //        this.msgService.remove();
        //     }, 2000);
        //     return Promise.reject(error);
        // });

        //    return this.angularFireAuth.auth.currentUser.updateProfile({
        //     displayName: userModel.name,
        //     photoURL: userModel.photoURL
        // }).then(res => {
        //     this.userService.updateUserProfile(userModel).subscribe(res => {
        //         return Promise.resolve(res);
        //     });           
        // })
        // .catch( error => {
        //     this.msgService.error(error);
        //     setTimeout(() => {
        //        this.msgService.remove();
        //     }, 2000);
        //     return Promise.reject(error);
        // })
        // refreshAuthToken(){
        //     this.angularFireAuth.auth.currentUser.getIdTokenResult().then( res => {
        //         sessionStorage.setItem('accessToken', res.token);
        //    //     console.log(JSON.stringify(res));
        //     })
        //    .catch(err => {
        //     console.log("Error = "+err);
        //  })     
        // }
    
}