import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { MeasureModel } from '../models/measure-model';
import { publishReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class MeasureService {

  private stateUrl = 'api/v1/admin/measures';

  constructor(private httpRequest: HttpRequestService) { }

  measureList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl);
  }

  saveMeasure(measureModel: MeasureModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl, measureModel);
  }

  updateMeasure(measureModel: MeasureModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl, measureModel);
  }

  deleteMeasure(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + "/" + id);
  }
    
}



