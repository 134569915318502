import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalService, isTemplateRef } from 'ng-zorro-antd';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { TableService } from 'src/app/shared/services/table.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { StateModel } from 'src/app/shared/models/state-model';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

  indeterminate: boolean = false;
  search: any;
  displayData;
  RegionList:any;
  searchValue = '';
  searchAddress = [];
  sortName = null;
  sortValue = null;
  sortAttribute={ key: 'name', value: 'ascend'};
  regionLoading = false;
  iconVisible: boolean;
  OverlapRegionList:any;
  isContentVisible: boolean;
  formHeaderText: string;
  overlapMessage: string;

  constructor(private tableSvc: TableService,
    private regionService: RegionService,
    private msgService: NzMessageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
       this.displayData = this.RegionList;
  }

  ngOnInit() {
    this.fetchRegionList();
  }

  goToAddEditRegion(region) {
    let navigationExtras: NavigationExtras = {
      state: {
        region: region
      }
    };
    this.router.navigate(['/regions', region.id], navigationExtras);
  }

  downloadKmlFile(kmlFilePath:any){
    if (kmlFilePath) {
      window.open(kmlFilePath, '_blank');
    }else{
      this.msgService.info("KML file not uploaded",{ nzDuration: 2000 });
    }
  }

  fetchRegionList() { 
    this.msgService.remove();
    this.regionLoading = true;
   // var msgId = this.msgService.loading("Loading...",{ nzDuration: 0 }).messageId;
    this.regionService.regionList().subscribe((res) => {
      this.regionLoading = false;
      //this.msgService.remove(msgId);
      this.RegionList = res.body;
      this.displayData = this.RegionList;
    }
    );   
  }

  removeRegion(id){
    
    this.RegionList.forEach( (region, index) => {
      if(region.id === id){
        this.RegionList.splice(index,1);
        return false;
      } 
    });

    this.RegionList = [...this.RegionList];
    this.displayData =this.tableSvc.sort(this.sortAttribute, this.RegionList);
 }


  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    if(this.sortAttribute.key == 'state') {
      this.displayData = this.tableSvc.sortInList(sortAttribute, this.RegionList);
    }
    else {
      this.displayData = this.tableSvc.sort(sortAttribute, this.RegionList);
    }
  }

  // searchInData(){
  //   alert("call");
  //    this.displayData = this.tableSvc.search(this.searchText,this.displayData);
  //     alert(JSON.stringify(this.displayData));
  //   }


  deleteRegion(id: any){
        var msgId = this.msgService.loading("Deleting...",{ nzDuration: 0 }).messageId;
        this.regionService.deleteById(id).subscribe((res) => {      
          this.msgService.remove(msgId);        
          //this.fetchRegionList();
          this.removeRegion(id);
        });
  }


  showDeleteConfirm(id,type): void {

    if (type=="REGION") {
      type = "Region";
    }
    else{
      type = "Zone"
    }
    this.modalService.confirm({
      nzTitle: 'Delete this ' +type+ '?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteRegion(id),
      nzCancelText: 'No'
    });
  }



  /**
   * This method is responsible for searching functionality
   */
  // searchInData(): void {
  //   if(this.search.trim() != ""){
  //    console.log("search term => "+JSON.stringify(this.search)); 
  //   const filterFunc = (item) => {
  //    return (this.search.length ? this.search.some(address => item.name.toLowerCase().indexOf(address.toLowerCase()) !== -1) : true) &&
  //       (item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
  //   };

  //   const data = this.displayData.filter(item => filterFunc(item));
  //   this.displayData = data.sort((a, b) => (this.sortValue === 'ascend') ? (a[ this.sortName ] > b[ this.sortName ] ? 1 : -1) : (b[ this.sortName ] > a[ this.sortName ] ? 1 : -1));
  //   }
  //   else{
  //     this.displayData = this.RegionList;
  //   }
  //   console.log("search data => "+this.displayData);
  // }

  searchInData(): void {
    /** filter data **/

    const filterFunc = item => (this.search ? item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 : true) 
    || (this.search ? item.type.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 : true) 
    ||  (this.search ? item.country.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 : true)
    || this.filterState(item);
    
    const data = this.RegionList.filter(item => filterFunc(item));
    /** sort data **/
    if (this.sortName) {
      this.displayData = data.sort((a, b) => (this.sortValue === 'ascend') ? (a[this.sortName] > b[this.sortName] ? 1 : -1) : (b[this.sortName] > a[this.sortName] ? 1 : -1));
    } else {
      this.displayData = data;
    }
  }

  filterState(item){
    var isMatch = false;

    item.state.forEach(s => {
    
      if(this.search){
         if(s.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1){
          isMatch = true;
         } 
       }
      });

    return isMatch;  
  };

  /**
   * Reset the Data List
   */
  resetData(){
      this.fetchRegionList();
      this.search = null;
  }

  /* To copy any Text */
  copyValue(val: string){
  let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * To check Overlap region
   */
  checkOverlap() {
    var content="";
    var overLappingMsgId = this.msgService.loading("Loading...", {nzDuration:0}).messageId;
    this.regionService.getAllOverlapRegions().subscribe((res) => {
      this.msgService.remove(overLappingMsgId);
      this.OverlapRegionList = res.body;
      this.isContentVisible = true;
      this.formHeaderText = 'Overlapping Regions';
     
      this.OverlapRegionList.forEach(value => {
        content = content+value.name+"<br/>";
      });
      this.overlapMessage = content;
    });   
    
    
  }
}
