import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { TitlesFormBuilder, TitlesModel } from 'src/app/shared/models/paywall-titles-model';
import { PaywallTitlesService } from 'src/app/shared/services/paywall-titles.service';

@Component({
  selector: 'paywall-titles',
  templateUrl: './paywall-titles.component.html',
  styleUrls: ['./paywall-titles.component.css']
})
export class PaywallTitlesComponent implements OnInit {

    titles: TitlesFormBuilder = new TitlesFormBuilder();
    titlesModel: TitlesModel = this.titles.titlesModel;
    titlesForm: FormGroup = this.titles.titlesForm;
    titlesModelList: TitlesModel[] = [];
    processSubmitForm: boolean = false;
    titlesDataList: any[] = [];
    translationForm: FormGroup;
    transResBody: {};
    showTranslationPopUp: boolean = false;
    translationPopUpKey: String = '';

    constructor(private titlesService: PaywallTitlesService,
      private messageService: NzMessageService, private formBuilder: FormBuilder
    ) {
        this.titlesForm = this.titles.titlesForm;
        this.translationForm = this.formBuilder.group({
          fieldId: new FormControl(null, [Validators.required]),
          fieldName: new FormControl(null, [Validators.required]),
          fieldValue: new FormControl(null, [Validators.required])
        });
    }

    ngOnInit() {
        this.fetchTitlesData();
    }

    fetchTitlesData() {
      this.titlesService.fetchAllTitles().subscribe(
        (res: any) => {
          this.titlesDataList = res;
          this.titles.setModelVals(this.titlesDataList);
          console.log(this.titles);
        },
        (err) => {
          console.error('Error fetching titles:', err);        
        });
    }

  submitTitlesForm() {
    this.processSubmitForm = true;
    if (this.titlesForm.valid) {
      const titleMsgId = this.messageService.loading('Saving Titles...').messageId;
      const titlesDTOList: TitlesModel[] = [];

       // Iterate over form controls to build DTO list
      Object.keys(this.titlesForm.controls).forEach(controlName => {
          if (controlName === 'idTitle1' || controlName === 'idTitle2') {
            return;
          }
          const existingTitle = this.titlesDataList.find(title => title.name === controlName);
          var tempId = controlName ==="title1" ? this.titlesForm.value["idTitle1"] : this.titlesForm.value["idTitle2"]
          const titleDTO: TitlesModel = {
              id: tempId === "" ? null : tempId,
              name: controlName, 
              value: this.titlesForm.value[controlName].trim()
          };
          titlesDTOList.push(titleDTO);
      });
        
      this.titlesService.saveTitles(titlesDTOList).subscribe((res: any) => {
            setTimeout(() => {
              this.messageService.remove(titleMsgId);
              this.fetchTitlesData();
              this.processSubmitForm = false;
            }, 800);
        },
        (error: any) => {
          console.error('Error while updating titles:', error);
        }
      );
    }
  }


  handleCancel() {
    this.showTranslationPopUp = false;
  }

  getTranslation(fieldName: string) {
    if(fieldName != '' || fieldName != null){    
      var feild_Name; var id;

      if(fieldName=="Headline 1"){
        feild_Name = "title1"; 
        id = this.titlesForm.value["idTitle1"];
      }
      else if(fieldName=="Headline 2"){
        feild_Name = "title2"; 
        id = this.titlesForm.value["idTitle2"];
      }

      let bodyObj = {
        "adminModuleName": "PAYWALL_TITLES",
        "fieldName": `${feild_Name}`,
        "value": "",
        "targetLanguage": "ES",
        "sourceLanguage": "ES"
      }

      this.titlesService.fetchTranslation(id, bodyObj).subscribe((res) => {
        this.transResBody = res.body;
        this.translationPopUpKey = fieldName
        this.showTranslationPopUp = true;

        this.translationForm.patchValue({
          fieldId: id,
          fieldName: fieldName,
          fieldValue: this.transResBody['value']
        });
      }, error => {
        this.messageService.error("something missing, Try Again", { nzDuration: 4000 });
      })
    }
  }  


  updateTranslation() {
    const fieldN = this.translationForm.get('fieldName').value;
    const fieldV = this.translationForm.get('fieldValue').value;
    const rID = this.translationForm.get('fieldId').value;
    const titleName = fieldN === 'Headline 1' ? 'title1' : fieldN === 'Headline 2' ? 'title2' : null;

    let bodyObj = {
      "adminModuleName": "PAYWALL_TITLES",
      "fieldName": `${titleName}`,
      "value": "",
      "targetLanguage": "ES",
      "targetText": `${fieldV}`,
      "sourceLanguage": "ES"
    }
    var bodyObjList = [];
    bodyObjList.push(bodyObj);
    this.titlesService.updateTranslation(rID, bodyObjList).subscribe((res) => {
      this.showTranslationPopUp = false;
      this.messageService.success("Successfully updated.", { nzDuration: 4000 });
    }, error => {
      this.messageService.error("Your Text is not update, Try Again", { nzDuration: 4000 });
    })

  }
}
