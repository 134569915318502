import { Component, OnInit } from '@angular/core';
import { RegulationModelFormBuilder, RegulationModel } from 'src/app/shared/models/regulation-model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router'
import { SeasonFormBuilder, SeasonModel } from 'src/app/shared/models/season-model';
import { RestrictioFormBuilder, RestrictionModel } from 'src/app/shared/models/restriction-model';
import { RegulationService } from 'src/app/shared/services/regulation.service';
import { NzMessageService } from 'ng-zorro-antd';
import { RegionService } from 'src/app/shared/services/region.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { ReturnStatement } from '@angular/compiler';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { DatePipe } from '@angular/common';
import { AIRegulationService } from 'src/app/shared/services/ai-regulation.service';

@Component({
  selector: 'app-add-edit-ai-regulation',
  templateUrl: './add-edit-ai-regulation.component.html',
  styleUrls: ['./add-edit-ai-regulation.component.css']
})
export class AddEditAIRegulationComponent implements OnInit {

  dateRange: any[];
  seasonLoadIndex: number = 0;
  selectedSeason: number = 0;
  showAutoTranslate: boolean;
  feildNamesList = [];
  gearRegulations: []
  updateTransalate = [];
  translateSpane = {};
  transformedData: any[] = [];
  gearing: boolean;
  snagging: boolean;
  spears: boolean;
  multipleHooks: boolean;
  visibleHarvestOverrideForm: boolean = false;
  isVisibleRegulation: boolean = false;
  regionList: any = [];
  spanishRegulationKey: String = '';
  measurementList: any = [];
  speciesList: any = [];
  loadingData: boolean = false;
  processSubmitForm = false;
  prevSeasonIndex: number = 0;
  disableAllForm: boolean = false
  regulationModel: RegulationModel = new RegulationModelFormBuilder().regulationModel;
  seasonModel: SeasonModel = new SeasonFormBuilder().seasonModel
  restrictionModel: RestrictionModel = new RestrictioFormBuilder().restrictionModel;
  disableAllFormControls = false;
  seasonDateValid: boolean = true;
  checkedGearRegulations;
  filteredRegionListForSearch: any = this.regionList;
  filterDataOfRegion = () => true;
  selectedRegions: any;
  selectedSpecies: any;
  selectedStates: any;
  pageIndex: number;
  selectedDates: any;
  selectedDatesTemp : any = [];
  //Pop-Up Form Creation
  regulationESForm: FormGroup;
  setRegularField: any;
  updatedReguId: any;

  /**
   * Constructor
   * @param formBuilder 
   * @param aiRegulationService 
   * @param messageService 
   * @param regionService 
   * @param speciesService 
   */
  constructor(
    private messageService: NzMessageService, private regionService: RegionService, private aiRegulationService: AIRegulationService,
    private speciesService: SpeciesService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private router: Router,
    private datePipe: DatePipe) {
    this.getRegions();
    this.getSpecies();
    this.getMeasurements();

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
          if(this.router.getCurrentNavigation().extras.state.selectedRegions){
              const selectedRegions = this.router.getCurrentNavigation().extras.state.selectedRegions;
              if (Object.keys(selectedRegions).length > 0) {
                this.selectedRegions = selectedRegions;
              }
          }
          if(this.router.getCurrentNavigation().extras.state.selectedSpecies){
            const selectedSpecies = this.router.getCurrentNavigation().extras.state.selectedSpecies;
            if (Object.keys(selectedSpecies).length > 0) {
              this.selectedSpecies = selectedSpecies;
            }
          }
          if(this.router.getCurrentNavigation().extras.state.selectedStates){
            const selectedStates = this.router.getCurrentNavigation().extras.state.selectedStates;
            if (Object.keys(selectedStates).length > 0) {
              this.selectedStates = selectedStates;
            }
          }
          if(this.router.getCurrentNavigation().extras.state.pageIndex){
            const pageIndex = this.router.getCurrentNavigation().extras.state.pageIndex;
              this.pageIndex = pageIndex;
          }
          if(this.router.getCurrentNavigation().extras.state.selectedDates){
              this.selectedDatesTemp = this.router.getCurrentNavigation().extras.state.selectedDates;
              this.selectedDates = this.selectedDatesTemp;
          }
      }      
    });

    // => Form Cretion:
    this.regulationESForm = this.fb.group({
      fieldName: new FormControl(null, [Validators.required]),
      fieldValue: new FormControl(null, [Validators.required])
    });
  }

  /**
   *  On component initialze
   */
  ngOnInit() {
    var isEditMode = this.route.snapshot.params.regulationId ? true : false;
    this.showAutoTranslate = this.route.snapshot.params.regulationId ? true : false;
    var newSeason = {
      index: this.seasonLoadIndex, id: '', startDate: null, endDate: null, startDateStr: '', endDateStr: '', type: 'OPEN', isDefault: true, dateRange: null, restrictions: {
        aggregateLimit: null,
        bagLimit: null,
        gearRegulations: [],
        maxSize: null,
        minSize: null,
        trophyLimit: null,
        trophySize: null,
        vesselLimit: null,
        dailyLimit: null,
        measurementId: null,
        trophyUnit: '\u0022',
        maxSizeUnit: '\u0022',
        minSizeUnit: '\u0022'
      } as RestrictionModel
    };

    this.regulationModel.seasons = [newSeason];
    this.updateSeasonIndexes();
    if (isEditMode) {
      this.findRegulationById(this.route.snapshot.params.regulationId);
    }
  }

  /**
  * Add new form groups for dynamic form generation of season 
  */
  addSeason() {
    var nextIndex = 0
    if (this.regulationModel.seasons != null) {
      nextIndex = this.regulationModel.seasons.length;
    }
    var newSeason = {
      index: nextIndex, id: '', startDate: null, endDate: null, startDateStr: '', endDateStr: '', type: '', isDefault: false, dateRange: null, restrictions: {
        aggregateLimit: this.regulationModel.seasons[0].restrictions.aggregateLimit,
        bagLimit: this.regulationModel.seasons[0].restrictions.bagLimit,
        gearRegulations: [],
        maxSize: this.regulationModel.seasons[0].restrictions.maxSize,
        minSize: this.regulationModel.seasons[0].restrictions.minSize,
        trophyLimit: this.regulationModel.seasons[0].restrictions.trophyLimit,
        trophySize: this.regulationModel.seasons[0].restrictions.trophySize,
        vesselLimit: this.regulationModel.seasons[0].restrictions.vesselLimit,
        measurementId: null,
        trophyUnit: this.regulationModel.seasons[0].restrictions.trophyUnit,
        maxSizeUnit: this.regulationModel.seasons[0].restrictions.maxSizeUnit,
        minSizeUnit: this.regulationModel.seasons[0].restrictions.minSizeUnit,
      } as RestrictionModel
    };
    this.regulationModel.seasons.push(newSeason);
    this.selectedSeason = newSeason.index;
    this.updateSeasonIndexes();
  }

  /**
   * To remove dynamic form for season
   * @param index 
   */
  removeSeason(season: any) {
    this.regulationModel.seasons.splice(season.index, 1);
    this.updateSeasonIndexes();
  }

  updateSeasonIndexes() {
    if (this.regulationModel.seasons) {
      var seasons = this.regulationModel.seasons
      seasons.forEach(function (season, index) {
        season.index = index;
      });
    }
  };

  modelChanged(value) {
    this.selectedSeason = value;
  }

  /**
   * To add new regulation
   */
  addRegulation(saveDraft) {
    this.regulationModel.seasons.forEach(season => {
      if (season.dateRange && season.dateRange.length > 1) {
        // season.startDate = season.dateRange[0].toString();
        // season.endDate = season.dateRange[1].toString();
        season.startDate = null;
        season.endDate = null;
        var startD = new Date(season.dateRange[0]);
        var endD = new Date(season.dateRange[1]);
        season.startDateStr = startD.getFullYear() + "-" +
          ("0" + (startD.getMonth() + 1)).slice(-2) + "-" +
          ("0" + startD.getDate()).slice(-2) + " " +
          "00:00:00";
        season.endDateStr = endD.getFullYear() + "-" +
          ("0" + (endD.getMonth() + 1)).slice(-2) + "-" +
          ("0" + endD.getDate()).slice(-2) + " " +
          "23:59:59";
      }
    });
    this.regulationModel.id = this.route.snapshot.params.regulationId ? this.route.snapshot.params.regulationId : null;
    if (this.regulationModel.type == 'PROHIBITED' || this.regulationModel.type == 'DEFAULT') {
      this.regulationModel.customLimit = null;
      this.regulationModel.measurementId = null;
    } else if (this.regulationModel.type == 'CUSTOM') {
      this.regulationModel.measurementId = null;
    } else {
      this.regulationModel.customLimit = null;
      this.regulationModel.seasons.filter(function (season) {
        if (season.isDefault && season.restrictions.measurementId != null) {
          this.regulationModel.measurementId = season.restrictions.measurementId;
        }
      })
    }

  if (this.regulationModel.id) {
      switch (saveDraft) {
        case true:
          var saveMessageId = this.messageService.loading("Moving to Regulations...", { nzDuration: 0 }).messageId;
          this.aiRegulationService.saveDraftRegulation(this.regulationModel).subscribe((res) => {
            this.messageService.remove(saveMessageId);
            this.messageService.success("Saved");
            this.navigateToRegulationsPage();
          });
        break;  
          
        case false:
          var updateMessageId = this.messageService.loading("Updating...", { nzDuration: 0 }).messageId;
          this.aiRegulationService.updateRegulation(this.regulationModel).subscribe((res) => {
            this.messageService.remove(updateMessageId);
            this.messageService.success("updated");
            this.navigateToRegulationsPage();
          });
        break;  
      }
    } 
  }

  navigateToRegulationsPage() {
    this.transformedData = [];
    const navigationExtras: NavigationExtras = {
      state: {
        region: this.selectedRegions,
        species: this.selectedSpecies,
        state: this.selectedStates,
        pageIndex: this.pageIndex,
        selectedDates: this.selectedDates,
      },
    };
    this.router.navigate(['/ai-regulations'], navigationExtras);
  }


  /**
   * Set the value of gear regulation to restriction model
   * @param value 
   */
  addGearRegulation(gearRegulations: [], season) {
    season.restrictions.gearRegulations = gearRegulations;
  }
  isGearRegularionIn(season, selectedGear) {

    return season.restrictions.gearRegulations ? season.restrictions.gearRegulations.includes(selectedGear) : false;

  }
  
  /**
   * To get list of regions for combobox dropdown
   */
  getRegions() {
    this.loadingData = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionList = res.body;
      this.filteredRegionListForSearch = this.regionList;
      this.loadingData = false;
    });
  }

  /**
   * To get list of species for combobox dropdown
   */
  async getSpecies() {
    this.loadingData = true;
    this.speciesList = await this.speciesService.getAllLiteSpecies();
    this.loadingData = false;
  }

  /**
   * To get list of measurments for combobox dropdown 
   */
  getMeasurements() {
    this.speciesService.getAllMeasurements().subscribe(res => {
      this.measurementList = res.body;
    })
  }

  /**
   * This method is responsible for redirecting to regions page
   */
  forwardToRegulationsPage() {
    let navigationExtras: NavigationExtras = {
      state: {
        region: this.selectedRegions,
        species: this.selectedSpecies,
        state: this.selectedStates,
        pageIndex: this.pageIndex,
        selectedDates: this.selectedDates
      }
    }
    this.router.navigate(['/ai-regulations'], navigationExtras);
  }

  /**
   * Disable all form controls in case of inProhibited 
   * @param isProhibited 
   */
  disableFormControls(type: string) {
    if (type == 'PROHIBITED' || type == 'CUSTOM' || type == 'DEFAULT') {
      this.disableAllForm = true;
      this.disableAllFormControls = true;
    } else {
      this.disableAllForm = false;
      this.disableAllFormControls = false;
    }
  }

  findRegulationById(id: string) {
    var fetchMessageId = this.messageService.loading("Loading...").messageId;
    this.aiRegulationService.findById(id).subscribe((res) => {
      this.messageService.remove(fetchMessageId);
      this.setRegulationValue(res.body);
    });
  }
  datePickerModelChanged(seasonDate: Date[]) {
  }
  setRegulationValue(res) {
    res.seasons.forEach(season => {
      season.restrictions.trophyUnit = season.restrictions.trophyUnit != null ? season.restrictions.trophyUnit : '\u0022';
      season.restrictions.maxSizeUnit = season.restrictions.maxSizeUnit != null ? season.restrictions.maxSizeUnit : '\u0022';
      season.restrictions.minSizeUnit = season.restrictions.minSizeUnit != null ? season.restrictions.minSizeUnit : '\u0022';
      season.restrictions.aggregateLimit = this.replaceNoneWithZero(season.restrictions.aggregateLimit);
      season.restrictions.bagLimit = this.replaceNoneWithZero(season.restrictions.bagLimit);
      season.restrictions.vesselLimit = this.replaceNoneWithZero(season.restrictions.vesselLimit);
      season.restrictions.trophyLimit = this.replaceNoneWithZero(season.restrictions.trophyLimit);
      season.restrictions.trophySize = this.replaceNoneWithZero(season.restrictions.trophySize);
      season.restrictions.minSize = this.replaceNoneWithZero(season.restrictions.minSize);
      season.restrictions.maxSize = this.replaceNoneWithZero(season.restrictions.maxSize);
      if (season && season.isDefault == false) {
        const browser = this.getBrowserName();
        if (browser === 'safari') {
          // For safari
          season.startDate = this.datePipe.transform(season.startDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
          season.endDate = this.datePipe.transform(season.endDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
        }
        else {
          // For other browser
          season.startDate = this.datePipe.transform(season.startDateStr, 'yyyy-MM-ddTHH:mm:ss');
          season.endDate = this.datePipe.transform(season.endDateStr, 'yyyy-MM-ddTHH:mm:ss');
        }
        season.dateRange = [season.startDate, season.endDate];
      }
    });
    this.regulationModel = res;
    this.updateSeasonIndexes();
    this.disableFormControls(this.regulationModel.type);
  }

  getDefaultSeason() {
    var defaultSeason = null;
    if (this.regulationModel.seasons) {
      defaultSeason = this.regulationModel.seasons.filter(function (season) {
        return season.isDefault;
      })
    }
    if (defaultSeason) {
      defaultSeason = defaultSeason[0];
    }
    return defaultSeason;
  }

  replaceNoneWithZero(value) {
    return value == "None" ? null : value;
  }

  /**
   * This method is responsible for searching on region list.
   * @param search 
   */
  searchInRegionList(search: any) {
    this.filteredRegionListForSearch = this.utilityService.searchInRegionsWithCountryAndState(search, this.regionList);
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  handleCancel() {
    this.isVisibleRegulation = false;
  }


}

