import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/shared/services/table.service';
import { ParticipantService } from 'src/app/shared/services/participant.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.css']
})
export class ParticipantComponent implements OnInit {

  indeterminate: boolean = false;
  search: any;
  displayData;
  participantList:any;
  // searchValue = '';
  // searchAddress = [];
  // sortName = null;
  // sortValue = null;
  participantLoading = false;
   sortAttribute={ key: 'name', value: 'ascend'};


  constructor(private tableSvc: TableService,
    private participantService: ParticipantService,
    private msgService: NzMessageService,
    private modalService: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
       this.displayData = this.participantList;
  }

   ngOnInit() {
      this.fetchParticipantList();
   }


  goToAddEditParticipant(id,participant) {
    let navigationExtras: NavigationExtras = {
      state: {
        participant: participant
      }
    };
    this.router.navigate(['/participants',id],navigationExtras);
  }

  
  fetchParticipantList() { 
    this.msgService.remove();
   //var msgId = this.msgService.loading("Loading...",{ nzDuration: 0 }).messageId;
    this.participantLoading = true;
    this.participantService.participantList().subscribe((res) => {
      //this.msgService.remove(msgId);
      this.participantLoading = false;
      this.participantList = res.body;
      this.displayData = this.participantList;
    }
    );   
  }


  removeParticipant(id){
    this.participantList.forEach( (region, index) => {
      if(region.id === id){
        this.participantList.splice(index,1);
        return false;
      } 
    });

    this.participantList = [...this.participantList];
    this.displayData =this.tableSvc.sort(this.sortAttribute, this.participantList);
 }


  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableSvc.sort(sortAttribute, this.participantList);
  }

  deleteParticipant(id: any){
        var msgId = this.msgService.loading("Deleting...",{ nzDuration: 0 }).messageId;
        this.participantService.deleteById(id).subscribe((res) => {      
          this.msgService.remove(msgId); 
          this.removeParticipant(id);
        });
  }


  showDeleteConfirm(id): void {

    this.modalService.confirm({
      nzTitle: 'Delete this Participant ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteParticipant(id),
      nzCancelText: 'No'
    });
  }

  searchInData(){
     this.displayData  =  this.tableSvc.search(this.search,this.participantList);
  }


  // /**
  //  * This method is responsible for searching functionality
  //  */
  // searchInData(): void {

  //   if(this.search.trim() != ""){
  //   const filterFunc = (item) => {
  //    return (this.searchAddress.length ? this.searchAddress.some(address => item.name.toLowerCase().indexOf(address.toLowerCase()) !== -1) : true) &&
  //       (item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
  //   };
  //   const data = this.participantList.filter(item => filterFunc(item));
  //   this.displayData = data.sort((a, b) => (this.sortValue === 'ascend') ? (a[ this.sortName ] > b[ this.sortName ] ? 1 : -1) : (b[ this.sortName ] > a[ this.sortName ] ? 1 : -1));
  //   }
  //   else{
  //     this.displayData = this.participantList;
  //   }
  // }

  /**
   * Reset the Data List
   */
  resetData(){
      this.fetchParticipantList();
      this.search = null;
  }
  

}
