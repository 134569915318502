import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataExportService {

  private participantsUrl = 'api/v1/admin/participants';

  private dataExportUrl = 'api/v1/admin/data-export';
  constructor(
    private httpRequest: HttpRequestService,
    private http: HttpClient
  ) { }

  participantsList(): Observable<Response> {
    return this.httpRequest.get(this.participantsUrl);
  }

  exportData(exportData: any) : Observable<any>{
    // return this.httpRequest.post(this.dataExportUrl,exportData);
    return this.http.post(environment.apiUrl + this.dataExportUrl,exportData,{responseType: 'blob' })
    // window.open('https://firebasestorage.googleapis.com/v0/b/fishverifygcp.appspot.com/o/all-data-report.zip?alt=media&token=995b5b45-1892-4638-87f4-58dcccc2c960', '_blank');
  }
}
