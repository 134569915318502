import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { UsersReviewModel, UsersReviewModelFormBuilder } from 'src/app/shared/models/users-review-model';
import { UsersReviewService } from 'src/app/shared/services/users-review.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-add-edit-users-review',
  templateUrl: './add-edit-users-review.component.html',
  styleUrls: ['./add-edit-users-review.component.css']
})
export class AddEditUsersReviewComponent implements OnInit {

  usersReview: UsersReviewModelFormBuilder = new UsersReviewModelFormBuilder();
  usersReviewModel: UsersReviewModel = this.usersReview.usersReviewModel;
  usersReviewForm: FormGroup = this.usersReview.usersReviewForm;
  processSubmitForm:boolean = false;
  imageFileList: UploadFile[] = [];
  file: UploadFile;
  previewImage = '';
  previewVisible = false;
  transResBody: {};
  showTranslationPopUp: boolean = false;
  translationPopUpKey: String = '';
  translationForm: FormGroup;

  
  ngOnInit() {
    var isEditMode = this.route.snapshot.params.usersReviewId ? true : false;
    if(isEditMode){
      this.findUsersReviewById(this.route.snapshot.params.usersReviewId);    
    }
  }

  constructor(
    private usersReviewService: UsersReviewService,
    private modelService: NzModalService,
    private messageService: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private formBuilder: FormBuilder

   ) {
    this.translationForm = this.formBuilder.group({
      fieldName: new FormControl(null, [Validators.required]),
      fieldValue: new FormControl(null, [Validators.required])
    });

      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
          this.usersReview.setModelVals(this.router.getCurrentNavigation().extras.state.featuresData);
        }
      });
  }

  /**
   * Add and update the region form
   */
  submitUserReviewsForm() {
    var usersReviewDtoBlob = new Blob([JSON.stringify(this.usersReviewModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('usersReviewDto', usersReviewDtoBlob);
    this.imageFileList.forEach((file: any) => {
      formData.append('file', file.originFileObj);
    });

    if (this.usersReviewModel.id != null) {
      var updateMsgId = this.messageService.loading('Updating...').messageId;
      this.usersReviewService.updateUsersReview(formData).subscribe(res => {
        this.messageService.remove(updateMsgId);
        this.modelService.closeAll();
        this.forwardToUsersReviewPage()
      })

    } else {
      const loadingData = this.messageService.loading('Saving ...').messageId;
      this.usersReviewService.saveUsersReview(formData).subscribe((res) => {
        this.messageService.remove(loadingData);
        this.modelService.closeAll();
        this.forwardToUsersReviewPage()
      });
    }
}

  /**
   * find region by id
   */
  findUsersReviewById(usersReviewId: string) {
    this.usersReviewService.findById(usersReviewId).subscribe((res) => {
      if (res.body.imageUrl) {
        this.previewImage = res.body.imageUrl;
        this.previewVisible = false; 
        let uploadedFile: UploadFile = {
          url: res.body.imageUrl,
          uid: '',
          name: '',
          size: 10,
          type: '',
          status: 'done'
        };
        this.imageFileList = [uploadedFile];
      }
      this.usersReview.setModelVals(res.body);
    });
  }
  
  /**
   * This method is responsible for redirecting to regions page
   */
  forwardToUsersReviewPage(){
    this.router.navigate(['/users-review']);
  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  }

  /**
 * On change image
 * @param info 
 */
  handleChange(info: any): void {
    if (info.file.status === 'uploading') {
      this.getBase64(info.file.originFileObj, (img: string) => {
        info.file.url = img;
        info.file.status = 'done';
        return true;
      });
    }
  }

  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Remove Image
   */
  deleteImage = (file: any): void => {
    if (!this.usersReviewModel.removedImages) {
      this.usersReviewModel.removedImages = [file.name];
    } else {
      this.usersReviewModel.removedImages.push(file.name);
    }
    this.imageFileList = this.imageFileList.filter(f => f.name !== file.name);
    //this.OnSubmitButton();
  }

  /**
   * Allowed image type for upload image
   */
  imageFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (uploadFileList: UploadFile[]) => {
        var file = uploadFileList[0];
        if (uploadFileList[0].name && !(uploadFileList[0].name.toLowerCase().endsWith("jpg") || uploadFileList[0].name.toLowerCase().endsWith("png") ||
          uploadFileList[0].name.toLowerCase().endsWith("jpeg") || uploadFileList[0].name.toLowerCase().endsWith("gif") ||
          uploadFileList[0].name.toLowerCase().endsWith("bmp"))) {
          this.messageService.error("Only image file upload is supported", { nzDuration: 2000 });
          return [];
        }
        return uploadFileList;
      }
    }];

    handleCancel() {
      this.showTranslationPopUp = false;
    }
  
    getTranslation(fieldName: string) {
      if(fieldName != '' || fieldName != null){       
        let bodyObj = {
          "adminModuleName": "PAYWALL_REVIEWS",
          "fieldName": "review",
          "value": "",
          "targetLanguage": "ES",
          "sourceLanguage": "ES"
        }
  
        this.translationService.fetchTranslation(this.usersReviewModel.id, bodyObj).subscribe((res) => {
          this.transResBody = res.body;
          this.translationPopUpKey = fieldName
          this.showTranslationPopUp = true;
  
          this.translationForm.patchValue({
            fieldName: fieldName,
            fieldValue: this.transResBody['value']
          });
        }, error => {
          this.messageService.error("something missing, Try Again", { nzDuration: 4000 });
        })
      }
    }  
  
  
   updateTranslation() {
      const fieldN = this.translationForm.get('fieldName').value;
      const fieldV = this.translationForm.get('fieldValue').value;
      const id = this.usersReviewModel.id;
      let bodyObj = {
        "adminModuleName": "PAYWALL_REVIEWS",
        "fieldName": "review",
        "value": "",
        "targetLanguage": "ES",
        "targetText": `${fieldV}`,
        "sourceLanguage": "ES"
      }
      var bodyObjList = [];
      bodyObjList.push(bodyObj);
      this.translationService.updateTranslation(id, bodyObjList).subscribe((res) => {
        const data = res.body;
        this.showTranslationPopUp = false;
        this.messageService.success("Successfully updated.", { nzDuration: 4000 });
      }, error => {
        this.messageService.error("Your Text is not updated, Please Try Again", { nzDuration: 4000 });
      })
    }
}
