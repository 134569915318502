import { FormGroup, FormControl, Validators } from "@angular/forms";

export class DefaultRegulationModel {

    id: string;
    regions: any[];
    defaultLimit: string;
    hideSeason: boolean = false;
}

export class DefaultRegulationFormBuilder {
    defaultRegulationModel: DefaultRegulationModel = {
        id: null,
        regions: []  as any,
        defaultLimit: null,
        hideSeason: false
    }

    defaultRegulationForm: FormGroup = new FormGroup({
        id: new FormControl(this.defaultRegulationModel.id),
        regions: new FormControl(this.defaultRegulationModel.regions,[Validators.required]),
        defaultLimit: new FormControl(this.defaultRegulationModel.defaultLimit,[Validators.required]),
        hideSeason: new FormControl(this.defaultRegulationModel.hideSeason),
    });
 
    constructor() {
        this.defaultRegulationForm.valueChanges.subscribe(val => {
            this.defaultRegulationModel.id = val.id;
            this.defaultRegulationModel.regions = val.regions;
            this.defaultRegulationModel.defaultLimit = val.defaultLimit;
            this.defaultRegulationModel.hideSeason = val.hideSeason;
        });
    }

    setModelVals(res: any) {
            this.defaultRegulationForm.controls.id.setValue(res.id),
            this.defaultRegulationForm.controls.regions.setValue(res.regions),
            this.defaultRegulationForm.controls.defaultLimit.setValue(res.defaultLimit);
            this.defaultRegulationForm.controls.hideSeason.setValue(res.hideSeason);
    }
}