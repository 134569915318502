import { OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { RegulationModel, RegulationModelFormBuilder } from 'src/app/shared/models/regulation-model';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { RegulationService } from 'src/app/shared/services/regulation.service';
import { Component} from '@angular/core';
import { TableService } from 'src/app/shared/services/table.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { SpeciesModel, SpeciesFormBuilder } from 'src/app/shared/models/species.model';
import { StateService } from 'src/app/shared/services/state.service';
import { StateFormBuilder, StateModel } from 'src/app/shared/models/state-model';
import { format } from "date-fns";
import { FormGroup } from '@angular/forms';
import { RegionFormBuilder, RegionModel } from 'src/app/shared/models/region-model';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.css']
})
export class RegulationComponent implements OnInit {

  regulationList:RegulationModel[];
  regionFilterList: any = [];
  //speciesFilterList: any = [];
  speciesFilterList: [];
  lessonsCounter: any
  currentSelectedRegulation: any = null;
  searchAddress: any
  disableAllFormControls = true;
  filteredRegionListForSearch:any = this.regionFilterList;
  filterDataOfRegion = () => true;
  isVisible : boolean;
  formHeaderText : string;
  species : any = [];
  regions : any = [];
  regulationId : any;
  submitButton : boolean;
  seasons: any = [];
  quickEditSubmitButton = true;
  //checked : true
 // regulationForm:FormGroup;
 // seasonLoadIndex:number = 0;
  pageIndex = 1;
  pageSize = 10;
  total = 1;;
  loading = false;
  sortBy = "name";
  sortDirection = "ASC";
  search : any;
  selectedRegions: any = [];
  selectedSpecies: any = [];
  regulationsLoading = false;
  regulation: RegulationModelFormBuilder = new RegulationModelFormBuilder();
  regulationModel: RegulationModel = this.regulation.regulationModel;
  regulationForm: FormGroup = this.regulation.regulationForm;

  dateFilterList: any =  [];
  selectedDates:any  = [];
  region: RegionFormBuilder = new RegionFormBuilder();
  regionObject : RegionModel = this.region.regionModel;

  specie : SpeciesFormBuilder = new SpeciesFormBuilder();
  speciesObject : SpeciesModel = this.specie.speciesModel;

  state : StateFormBuilder = new StateFormBuilder();
  stateObject : StateModel = this.state.stateModel;

  updatedAt : StateFormBuilder = new StateFormBuilder();
  updatedAtObject : StateModel = this.state.stateModel;

  selectedStates: any = [];
  stateFilterList: any = [];
  selectedWatchlist: any;
  selectedUpdatedStartDate: any;
  selectedUpdatedEndDate: any;
  changeSummaryRes: any = [];
  isMouseHoverLoaded: boolean = false;
  filterObj: any;

  selectedRegionsTemp: any = [];
  selectedSpeciesTemp: any = [];
  selectedStatesTemp: any = [];
  selectedDatesTemp: any = [];

  searchObj = {
    "lastUpdatedTime" : ""
  }

  // season: SeasonFormBuilder = new SeasonFormBuilder();
 // seasonModel: SeasonModel = this.season.seasonModel;
 // seasonForm: FormGroup = this.season.seasonForm;

 constructor(
  private msgService: NzMessageService,
  private regulationService: RegulationService,
  private modalService: NzModalService,
  private router: Router,
  private route: ActivatedRoute,
  private tableSvc: TableService,
  private speciesService: SpeciesService,
  private regionService: RegionService,
  private utilityService : UtilityService,
  private datePipe: DatePipe,
  private stateService : StateService
  ) { 
    this.route.queryParams.subscribe(params => {

      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
        if (Object.keys(this.router.getCurrentNavigation().extras.state.species).length > 0) {
          this.selectedSpeciesTemp = this.router.getCurrentNavigation().extras.state.species.id;
          this.selectedSpecies = this.selectedSpeciesTemp;
        }
        if (Object.keys(this.router.getCurrentNavigation().extras.state.region).length > 0) {
          this.selectedRegionsTemp = this.router.getCurrentNavigation().extras.state.region.id;
          this.selectedRegions = this.selectedRegionsTemp;
          // var regionIds = this.router.getCurrentNavigation().extras.state.region.id;
          // for (var i = 0; i < regionIds.length; i++) {
          //   this.selectedRegions.push(regionIds[i]);
          // }
        }
        if (Object.keys(this.router.getCurrentNavigation().extras.state.state).length > 0) {
          this.selectedStatesTemp = this.router.getCurrentNavigation().extras.state.state.id;
          this.selectedStates = this.selectedStatesTemp;
          // var stateIds = this.router.getCurrentNavigation().extras.state.state.id;
          // for (var i = 0; i < stateIds.length; i++) {
          //   this.selectedStates.push(stateIds[i]);
          // }
        }
        if (this.router.getCurrentNavigation().extras.state.pageIndex) {
          this.pageIndex = this.router.getCurrentNavigation().extras.state.pageIndex;
        }
        if(this.router.getCurrentNavigation().extras.state.selectedDates){
          this.selectedDatesTemp = this.router.getCurrentNavigation().extras.state.selectedDates;
          this.selectedDates = this.selectedDatesTemp;
        }
      }
    });
  }

  ngOnInit() {
    this.getRegions();
    this.getFilterSpeciesList();
    this.getFilterDateRanges();
    this.fetchAllRegulations();
    this.getFilterStateList();
    //  this.regulationForm = this.formBuilder.group({
    //        seasons : this.formBuilder.array([this.formBuilder.group({startDate:'',endDate:'',type:''})])
    //    });
  }

  searchInData11(column: any) {
    this.fetchAllRegulations();
  }

  reset(column) {
    this.searchObj[column] = "";
    this.fetchAllRegulations();
  }

  resetWatchlistSearch(column) {
    this.selectedWatchlist = null;
    this.fetchAllRegulations();
  }

  collapseExpand(data) {
    if (data.type != 'PROHIBITED' && data.type != 'CUSTOM' && data.type != 'DEFAULT') {
      data.expand = data.expand ? false : true;
      //this.QuickEditUpdateButton(data.totalSeasons);
    }
  }


  //  sort(sort: { key: string, value: string }): void {
  //   this.sortKey = sort.key;
  //   this.sortValue = sort.value;
  //   this.fetchAllRegulations();
  // }

  fetchAllRegulations(reset: boolean = false) {

    this.msgService.remove();
    this.pageIndex = reset ? 1 : this.pageIndex;


    let searchRequest = { ...this.searchObj };
    if (searchRequest['lastUpdatedTime'][0]) {
      this.selectedUpdatedStartDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][0], 'yyyy-MM-dd');
      this.selectedUpdatedEndDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][1], 'yyyy-MM-dd');
    }

    this.selectedUpdatedStartDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][0], 'yyyy-MM-dd');
    this.selectedUpdatedEndDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][1], 'yyyy-MM-dd');

    let filteredRegions = this.selectedRegions ? this.selectedRegions.join() : null;
    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null;
    let filteredStates = this.selectedStates ? this.selectedStates.join() : null;
    let filteredWatchList = this.selectedWatchlist ? this.selectedWatchlist : null;
    let filteredUpdatedStartDate = null;
    let filteredUpdatedEndDate = null;
    let filteredDateOperation = "in";

    if(this.selectedUpdatedStartDate != null && this.selectedUpdatedEndDate != null ){
      filteredUpdatedStartDate =  this.selectedUpdatedStartDate; 
      filteredUpdatedEndDate = this.selectedUpdatedEndDate;
    } 
    else if(this.selectedDates && this.selectedDates.length > 0){     
      let filteredDate = this.dateFilterList.filter(f => f.selectedDate === this.selectedDates[0])[0];

      filteredUpdatedStartDate = formatDate(new Date(new Date().setMonth(new Date().getMonth() - filteredDate.selectedDateValue)), 'yyyy-MM-dd', 'en');
      filteredUpdatedEndDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      if(filteredDate.selectedDate === "Last 3 months" || filteredDate.selectedDate === "Last 6 months"
        || filteredDate.selectedDate === "Last 12 months" || filteredDate.selectedDate === "Last 24 months"
        || filteredDate.selectedDate === "Last 36 months"){
            filteredDateOperation = "in";
      }
      else if(filteredDate.selectedDate === "Before 3 months" || filteredDate.selectedDate === "Before 6 months"
        || filteredDate.selectedDate === "Before 12 months" || filteredDate.selectedDate === "Before 24 months"
        || filteredDate.selectedDate === "Before 36 months"){        
            filteredDateOperation = "not in";
      }
    }

    let request = {
      "pageNumber": this.pageIndex,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "boundaries": filteredRegions,
      "species": filteredSpecies,
      "states": filteredStates,
      "lastUpdatedStartDate": filteredUpdatedStartDate,
      "lastUpdatedEndDate": filteredUpdatedEndDate,
      "watchList": filteredWatchList,
      "dateOperation": filteredDateOperation
    }
    this.regulationsLoading = true;
    this.regulationService.getAllRegulations(request).subscribe((res) => {
      this.regulationsLoading = false;
      if (res.body) {
        this.total = res.body.totalCount;
        this.setSeasonDateRange(res.body.data);
        this.regulationList = res.body.data;
      } else {
        this.total = 0
        this.regulationList = [];
      }
    },
      (err) => {
        this.regulationsLoading = false;
      }
    );
  }


  sort(sortAttribute: any) {
    this.sortBy = null;
    this.sortDirection = null;
    if (sortAttribute.value) {
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC" : "");
    }
    this.fetchAllRegulations(false);
  }

  searchInData() {
    this.regulationList = this.tableSvc.search(this.search, this.regulationList);
  }


  // get seasons() { return this.regulationForm.get('seasons') as FormArray; }

  // addSeason(){
  //   if (this.seasons == null) {
  //       this.seasonLoadIndex == 0;
  //   }
  //   this.seasonLoadIndex++;

  //   this.seasons.push(this.formBuilder.group({startDate:'',endDate:'',type:''}))
  // }

  // removeSeason(index:any){
  //  //alert("Delete from index: "+index);
  //   this.seasons.removeAt(index);
  // }

  action(action: string) {

    switch (action) {
      case 'edit':
        this.goToAddEditRegulationPage(this.currentSelectedRegulation);
        break;
      case 'quickEdit':
        console.log("quick edit");
        break;
      case 'duplicate':
        console.log("duplicate");
        break;
      case 'delete':
        this.showDeleteConfirm(this.currentSelectedRegulation.id);
        console.log("delete => " + this.currentSelectedRegulation.id);
        break;
      case 'watchlist':
        this.addToWatchlist(this.currentSelectedRegulation.id, this.currentSelectedRegulation.watchlist);
        console.log("watchlist => " + this.currentSelectedRegulation);
        break;
      default:
        break;
    }

  }

  goToAddEditRegulationPage(regulation) {
    this.regionObject.id = this.selectedRegions,
      this.speciesObject.id = this.selectedSpecies;
    this.stateObject.id = this.selectedStates;

    let navigationExtras: NavigationExtras = {
      state: {
        regulation: regulation,
        selectedRegions: this.regionObject,
        selectedSpecies: this.speciesObject,
        selectedStates: this.stateObject,
        pageIndex: this.pageIndex,
        selectedDates: this.selectedDates
      }
    };
    this.router.navigate(['/regulations', regulation.id], navigationExtras);
  }


  /**
   * Show delete confirm box 
   * @param id 
   */
  showDeleteConfirm(id): void {
    this.modalService.confirm({
      nzTitle: 'Delete this regulation ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteRegulationById(id),
      nzCancelText: 'No'
    });
  }

  /**
   * Show delete confirm box 
   * @param id 
   */
  addToWatchlist(id: any, watchlist): void {
    this.addWatchListById(id, !watchlist)
  }

  /**
   * Delete the regulation by id
   * @param id 
   */
  deleteRegulationById(id) {
    var msgId = this.msgService.loading("Deleting...", { nzDuration: 0 }).messageId;
    this.regulationService.deleteById(id).subscribe((res) => {
      this.msgService.remove(msgId);
      this.fetchAllRegulations();
    });
  }

  /**
 * Delete the regulation by id
 * @param id 
 */
  addWatchListById(id, watchlist) {
    //var msgId = this.msgService.loading("Adding to watchlist...", { nzDuration: 0 }).messageId;
    this.regulationService.addToWatchList(id, watchlist).subscribe((res) => {
      //this.msgService.remove(msgId);
      this.updateWatchlist(id, watchlist);
    });
  }

  updateWatchlist(id: any, watchlist) {
    var obj = this.regulationList.filter(obj => obj.id === id)[0].watchList = watchlist;
  }

  currentSelectedData(data: any) {
    console.log("Regulation => " + data.id);
    this.currentSelectedRegulation = data;
  }


  /**
* To get list of species for combobox dropdown
*/

  async getFilterSpeciesList() {
    this.speciesFilterList = await this.speciesService.getAllLiteSpecies();
  }

  async getFilterDateRanges() {    
    this.dateFilterList = [
      {selectedDate: "Last 3 months", selectedDateValue: 3}, 
      {selectedDate: "Last 6 months", selectedDateValue: 6},
      {selectedDate: "Last 12 months", selectedDateValue: 12},
      {selectedDate: "Last 24 months", selectedDateValue: 24},
      {selectedDate: "Last 36 months", selectedDateValue: 36},
      {selectedDate: "Before 3 months", selectedDateValue: 3},
      {selectedDate: "Before 6 months", selectedDateValue: 6},
      {selectedDate: "Before 12 months", selectedDateValue: 12},
      {selectedDate: "Before 24 months", selectedDateValue: 24},
      {selectedDate: "Before 36 months", selectedDateValue: 36}
    ];}

  // getFilterSpeciesList() {
  //   //this.loading= true;
  //   this.speciesService.getAllLiteSpecies().subscribe(res => {
  //     this.speciesFilterList = res.body;
  //     //this.loading= false;
  //   });
  // }



  /**
   * To get list of regions for combobox dropdown
   */
  getRegions() {
    //this.loading = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionFilterList = res.body;
      this.filteredRegionListForSearch = this.regionFilterList;
      //this.loading = false;
    });
  }

  /**
   * This method is responsible for searching on region list.
   * @param search 
   */
  searchInRegionList(search: string) {
    this.filteredRegionListForSearch = this.utilityService.searchInRegionsWithCountryAndState(search, this.regionFilterList);
  }

  /**
   * This method is Responsible for Open Model window for duplicate Regulation
   * @param id 
   */
  openDuplicateForm(id) {
    this.isVisible = true;
    this.submitButton = true;
    this.formHeaderText = "Duplicate Regulation"
    this.regulationId = id;
  }

  /**
   * This method is responsible for close model window for Duplicate Regulation
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * This method is responsible to handle model submit button event
   */
  onDuplicate() {
    var messageId = this.msgService.loading("Duplicating...", { nzDuration: 0 }).messageId;
    let request = {
      "species": this.species,
      "regions": this.regions,
      "id": this.regulationId
    }
    this.regulationService.duplicateRegulation(request).subscribe((res) => {
      this.msgService.remove(messageId);
      this.msgService.success("saved");
      this.modalService.closeAll();
      this.fetchAllRegulations();
    });
  }

  /**
   * Disable - Enable Model Submit Button
   */
  OnSubmitButton() {
    if (this.species != null && this.regions != null && this.species != "" && this.regions != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
 * Set Date Range of Seasons
 * @param data 
 */
  setSeasonDateRange(data) {

    data.forEach(regu => {
      if (regu.totalSeasons) {

        this.updateSeasonIndexes(regu);
        regu.totalSeasons.forEach(s => {
          s.startDate = new Date(s.startDateStr);
          s.endDate = new Date(s.endDateStr);

          const browser = this.getBrowserName();
          console.log("Console => " + browser);
          if (browser === 'safari') {

            // For safari
            s.startDate = this.datePipe.transform(s.startDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
            s.endDate = this.datePipe.transform(s.endDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
          }
          else {
            // For other browser
            s.startDate = this.datePipe.transform(s.startDateStr, 'yyyy-MM-ddTHH:mm:ss');
            s.endDate = this.datePipe.transform(s.endDateStr, 'yyyy-MM-ddTHH:mm:ss');
          }

          s.dateRange = [s.startDate, s.endDate];

          s.restrictions.minSizeUnit = s.restrictions.minSize ? this.getUnit(s.restrictions.minSize) : '\u0022';
          s.restrictions.maxSizeUnit = s.restrictions.maxSize ? this.getUnit(s.restrictions.maxSize) : '\u0022';
          s.restrictions.trophyUnit = s.restrictions.trophySize ? this.getUnit(s.restrictions.trophySize) : '\u0022';
          s.restrictions.aggregateLimit = this.replaceNoneWithZero(s.restrictions.aggregateLimit);
          s.restrictions.bagLimit = this.replaceNoneWithZero(s.restrictions.bagLimit);
          s.restrictions.vesselLimit = this.replaceNoneWithZero(s.restrictions.vesselLimit);
          s.restrictions.trophyLimit = this.replaceNoneWithZero(s.restrictions.trophyLimit);
          s.restrictions.trophySize = s.restrictions.trophySize ? this.replaceNoneWithZero(s.restrictions.trophySize) : s.restrictions.trophySize;
          s.restrictions.minSize = s.restrictions.minSize ? this.replaceNoneWithZero(s.restrictions.minSize) : s.restrictions.minSize;
          s.restrictions.maxSize = s.restrictions.maxSize ? this.replaceNoneWithZero(s.restrictions.maxSize) : s.restrictions.maxSize;
        });
      }

      if (regu.defaultSeason) {
        var s = regu.defaultSeason;
        s.restrictions.minSizeUnit = s.restrictions.minSize ? this.getUnit(s.restrictions.minSize) : '\u0022';
        s.restrictions.maxSizeUnit = s.restrictions.maxSize ? this.getUnit(s.restrictions.maxSize) : '\u0022';
        s.restrictions.trophyUnit = s.restrictions.trophySize ? this.getUnit(s.restrictions.trophySize) : '\u0022';
        s.restrictions.aggregateLimit = this.replaceNoneWithZero(s.restrictions.aggregateLimit);
        s.restrictions.bagLimit = this.replaceNoneWithZero(s.restrictions.bagLimit);
        s.restrictions.trophyLimit = this.replaceNoneWithZero(s.restrictions.trophyLimit);
        s.restrictions.trophySize = s.restrictions.trophySize ? this.replaceNoneWithZero(s.restrictions.trophySize) : s.restrictions.trophySize;
        s.restrictions.minSize = s.restrictions.minSize ? this.replaceNoneWithZero(s.restrictions.minSize) : s.restrictions.minSize;
        s.restrictions.maxSize = s.restrictions.maxSize ? this.replaceNoneWithZero(s.restrictions.maxSize) : s.restrictions.maxSize;
      }
    });
  }
  /**
   * Replace value Zero to NULL and get value without Unit
   * @param value 
   */
  replaceNoneWithZero(value) {
    if (value) {
      if (value == "None")
        value = null;
      else if (value == "None'")
        value = null;
      else if (value.includes("\'"))
        value = value.replace("\'", " ");
      else if (value.includes("lbs"))
        value = value.replace(" lbs", " ");
      else if (value.includes("\""))
        value = value.replace("\"", " ");
      else if (value.includes("mm"))
        value = value.replace(" mm", " ");
      else if (value.includes("cm"))
        value = value.replace(" cm", " ");
      else if (value.includes("m"))
        value = value.replace(" m", " ");
      else if (value.includes("gal"))
        value = value.replace(" gal", " ");
      else if (value.includes("ltr"))
        value = value.replace(" ltr", " ");
    }
    return value;
  }

  /**
   * This method is used to get Unit from Size Value
   * 
   * @param size 
   */
  getUnit(size) {
    var unit;
    if (size.includes("'")) {
      unit = '\u0027';
    } else if (size.includes("lbs")) {
      unit = 'lbs';
    } else if (size.includes("\"")) {
      unit = '\u0022';
    } else if (size.includes("mm")) {
      unit = 'mm';
    } else if (size.includes("cm")) {
      unit = 'cm';
    } else if (size.includes("m")) {
      unit = 'm';
    } else if (size.includes("gal")) {
      unit = 'gal';
    } else if (size.includes("ltr")) {
      unit = 'ltr';
    } else
      unit = '\u0022';
    return unit;
  }

  /**
   * This method is used to set index in seasons
   * 
   * @param regulation 
   */
  updateSeasonIndexes(regulation) {
    var seasons = regulation.totalSeasons;
    seasons.forEach(function (ss, index) {
      ss.index = index;
    });
  };

  /**
   * This method is used to handle submit button event of Quick Edit
   */
  quickEdit(id: any, totalSeasons: any, defaultSeason: any) {
    if (totalSeasons != null) {
      totalSeasons.forEach(season => {
        if (season.dateRange && season.dateRange.length > 1) {
          season.startDate = null;
          season.endDate = null;
          var startD = new Date(season.dateRange[0]);
          var endD = new Date(season.dateRange[1]);
          season.startDateStr = startD.getFullYear() + "-" +
            ("0" + (startD.getMonth() + 1)).slice(-2) + "-" +
            ("0" + startD.getDate()).slice(-2) + " " +
            "00:00:00";
          season.endDateStr = endD.getFullYear() + "-" +
            ("0" + (endD.getMonth() + 1)).slice(-2) + "-" +
            ("0" + endD.getDate()).slice(-2) + " " +
            "23:59:59";
        }
        this.seasons.push(season)
      });
    }
    let request = {
      "seasons": this.seasons,
      "id": id,
      "defaultSeason": defaultSeason
    }
    var msg = this.msgService.loading("Updating...").messageId;
    this.regulationService.quickEditRegulation(request).subscribe(res => {
      this.msgService.remove(msg);
      this.msgService.success("Regulation updated successfully.");
      //this.fetchAllRegulations();
    })
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  /**
   * This method handle Quick edit submit button Event.
   * @param data 
   */
  // QuickEditUpdateButton(data) {
  //   console.log("size = "+data.size);
  //  data.forEach(season => {
  //     if(season.restrictions.minSize != null && season.restrictions.maxSize != null && season.restrictions.bagLimit != null
  //       && season.restrictions.minSize != "" && season.restrictions.minSize != "" && season.restrictions.bagLimit != "") {
  //       console.log("in if");
  //       this.quickEditSubmitButton = false;
  //     } else {
  //       console.log("in else");
  //       this.quickEditSubmitButton = true;
  //     }
  //  });
  // }

  /**
   * This method is used to get All states 
   */
  async getFilterStateList() {
    this.stateFilterList = await this.stateService.getAllLiteStates();
  }

  onChange1(result: Date): void {
    this.selectedUpdatedStartDate = formatDate(new Date(result[0]), 'yyyy-MM-dd', 'en_US');
    this.selectedUpdatedEndDate = formatDate(new Date(result[1]), 'yyyy-MM-dd', 'en_US');
  }

  onCellMouseEnter(data: any): void {

    if (!data.changeSummaryRes) {
      this.isMouseHoverLoaded = false;
    }
    this.regulationService.getLastChangeSummery(data.speciesId + ":" + data.region.id)
      .subscribe(res => {
        data.changeSummaryRes = res.body;
        this.isMouseHoverLoaded = true;
      });
  }
}

