import { FormGroup, FormControl, FormControlName, Validators } from "@angular/forms"

export interface SpeciesModel {
    id: string;
    commonName: string;
    scientificName: string;
    appearance: string;
    habitat: string;
    size: string;
    behaviour: string;
    referenceImagesUrls: [any];
    edibility: number;
    poisonous: boolean;
    kosher: boolean;
    freshWater: boolean;
    saltWater: boolean;
    locationsExclude: [any];
    locationsInclude: [any];
    baitsAndLures: [];
    tips: string;
    fileName: string;
    thumbImageUrl: any;
    classId: string;
    removedRefImages: [string];
    removedThumbImages:[string]
}

export class SpeciesFormBuilder {
        speciesModel: SpeciesModel = {
        id: null,
        commonName:'',
        scientificName:'',
        appearance:'',
        habitat:'',
        size:'',
        behaviour:'',
        referenceImagesUrls:null,
        edibility:0,
        poisonous: false,
        kosher:false,
        freshWater:false,
        saltWater: false,
        locationsExclude: null,
        locationsInclude: null,
        baitsAndLures: null,
        tips: '',
        fileName: '',
        thumbImageUrl:null,
        classId:null,
        removedRefImages: null,
        removedThumbImages: null
    }

    speciesForm: FormGroup = new FormGroup({
        id: new FormControl(this.speciesModel.id),
        commonName: new FormControl(this.speciesModel.commonName,[Validators.required]),
        scientificName: new FormControl(this.speciesModel.id,[Validators.required]),
        appearance: new FormControl(this.speciesModel.appearance,[Validators.required]),
        habitat: new FormControl(this.speciesModel.habitat),
        size:  new FormControl(this.speciesModel.size),
        behaviour:  new FormControl(this.speciesModel.behaviour),
        edibility: new FormControl(this.speciesModel.edibility),
        poisonous:  new FormControl(this.speciesModel.poisonous),
        kosher:  new FormControl(this.speciesModel.kosher),
        // waterTypes:  new FormControl(this.speciesModel.waterTypes),
        freshWater: new FormControl(this.speciesModel.freshWater),
        saltWater: new FormControl(this.speciesModel.saltWater),
        referenceImagesUrls: new FormControl(this.speciesModel.referenceImagesUrls),
        locationsExclude: new FormControl(this.speciesModel.locationsExclude),
        locationsInclude: new FormControl(this.speciesModel.locationsInclude),
        baitsAndLures: new FormControl(this.speciesModel.baitsAndLures),
        tips: new FormControl(this.speciesModel.tips),
        thumbImageUrl: new FormControl(this.speciesModel.thumbImageUrl),
        classId: new FormControl(this.speciesModel.classId)
    });

    constructor(){
        this.speciesForm.valueChanges.subscribe(val => {
            this.speciesModel.id = val.id;
            this.speciesModel.commonName = val.commonName;
            this.speciesModel.scientificName = val.scientificName;
            this.speciesModel.appearance = val.appearance;
            this.speciesModel.habitat = val.habitat;
            this.speciesModel.size = val.size;
            this.speciesModel.behaviour = val.behaviour;
            this.speciesModel.poisonous = val.poisonous;
            this.speciesModel.kosher = val.kosher;
            this.speciesModel.saltWater = val.saltWater;
            this.speciesModel.freshWater = val.freshWater;
            this.speciesModel.referenceImagesUrls = val.referenceImagesUrls;
            this.speciesModel.locationsExclude = val.locationsExclude;
            this.speciesModel.locationsInclude = val.locationsInclude;
            this.speciesModel.baitsAndLures = val.baitsAndLures;
            this.speciesModel.edibility = val.edibility;
            this.speciesModel.tips = val.tips;
            this.speciesModel.thumbImageUrl = val.thumbImageUrl;
            this.speciesModel.classId = val.classId;
        })
    }

    setModelValues(res: any){
        this.speciesForm.controls.id.setValue(res.id);
        this.speciesForm.controls.commonName.setValue(res.commonName);
        this.speciesForm.controls.scientificName.setValue(res.scientificName);
        this.speciesForm.controls.appearance.setValue(res.appearance);
        this.speciesForm.controls.habitat.setValue(res.habitat);
        this.speciesForm.controls.size.setValue(res.size);
        this.speciesForm.controls.behaviour.setValue(res.behaviour);
        this.speciesForm.controls.poisonous.setValue(res.poisonous);
        this.speciesForm.controls.kosher.setValue(res.kosher);
        this.speciesForm.controls.saltWater.setValue(res.saltWater);
        this.speciesForm.controls.freshWater.setValue(res.freshWater);
        this.speciesForm.controls.referenceImagesUrls.setValue(res.referenceImagesUrls);
        this.speciesForm.controls.locationsExclude.setValue(res.locationsExcluded);
        this.speciesForm.controls.locationsInclude.setValue(res.locationsIncluded);
        this.speciesForm.controls.baitsAndLures.setValue(res.baitsAndLures);
        this.speciesForm.controls.edibility.setValue(res.ediblity);
        this.speciesForm.controls.tips.setValue(res.tips);
        this.speciesForm.controls.thumbImageUrl.setValue(res.thumbImageUrl);
        this.speciesForm.controls.classId.setValue(res.classId);
    }
}