import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd';
import { adminUserFormBuilder, AdminUserModel } from 'src/app/shared/models/admin-user-model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(private userService: UserService,
    private datePipe: DatePipe,
    private messageService : NzMessageService  
    ) { }

  userList:any=[];
  search:any
  loader:boolean;
  pageIndex = 1;
  pageSize = 10;
  sortBy = "signupDate";
  sortDirection = "DESC";
  total = 100;
  updateUserPopup: boolean = false;
  createUserPopup : boolean = false;
  disableSubmitButton: boolean;
  disableCancelButton : boolean; 
  user: adminUserFormBuilder = new adminUserFormBuilder();
  adminUserModel: AdminUserModel = this.user.adminUserModel;
  adminUserCreateForm: FormGroup = this.user.adminUserCreateForm;
  adminUserUpdateForm: FormGroup = this.user.adminUserUpdateForm;

  searchObj = {
    "name":"",
    "email":"",
    "isEmailVerified":"",
    "enabled":"",
    "role":"",
    "signup": "",
 //   "lastSignin":"",
    "lastKnownLocation":"",
    "lastActivityIn":""
  }; 
  
  ngOnInit() {
     this.users(true);
  }

  /**
   * This method is responsible for fetching users
   */
  users(reset: boolean = false){

    if (reset) {
      this.pageIndex = 1;
    }
    let searchRequest = {...this.searchObj};

  //   if (searchRequest['isEmailVerified']) {
  //     if (searchRequest[column].toLocaleLowerCase().search('yes') != -1) {
  //       searchRequest[column] = true;
  //     } else {
  //       searchRequest[column] = false;
  //     }
  //  }
  
    if(searchRequest['signup'][0]){
      searchRequest["signupMinDate"] = this.datePipe.transform(searchRequest['signup'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["signupMaxDate"] =  this.datePipe.transform(searchRequest['signup'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['signup'] ="";
    }
    // if(searchRequest['lastSignin'][0]){
    //   searchRequest["lastSigninMinDate"] = this.datePipe.transform(searchRequest['lastSignin'][0], 'yyyy-MM-dd').concat(' 00:00:00');
    //   searchRequest["lastSigninMaxDate"] =  this.datePipe.transform(searchRequest['lastSignin'][1], 'yyyy-MM-dd').concat(' 23:59:59');
    //   searchRequest['lastSignin'] ="";
    // }
    if(searchRequest['lastActivityIn'][0]){
      searchRequest["lastActivityInMinDate"] = this.datePipe.transform(searchRequest['lastActivityIn'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["lastActivityInMaxDate"] =  this.datePipe.transform(searchRequest['lastActivityIn'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['lastActivityIn'] ="";
    }
  

    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search,
      "parameters": searchRequest
    }

    this.loader = true;
    this.userService.getAllUsers(request).subscribe((res) =>{
      this.loader = false;
      this.userList = res.body.users;
      this.total = res.body.totalUsers;
     },
     (res) =>{
      this.loader = false;
     });
  }

  collapseExpand(data){
    if (!data.isProhibited) {
      data.expand = data.expand ? false: true;   
    }
  }
  
  /**
   * Responsible for searching on table
   */
  searchInData(column:any){
    this.users(true)

 /*   let searchRequest = {...this.searchObj};

    if (column=='isEmailVerified' || column=='enabled') {
        if (searchRequest[column].toLocaleLowerCase().search('yes') != -1) {
          searchRequest[column] = true;
        } else {
          searchRequest[column] = false;
        }
    }
    
    if (column=='signup' || column=='lastSignin') {
      searchRequest[column+"MinDate"] = this.datePipe.transform(searchRequest[column][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest[column+"MaxDate"] =  this.datePipe.transform(searchRequest[column][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest[column] ="";
    }

    let request = {
      "pageNumber":1,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search,
      "parameters": searchRequest
    }


    this.userService.getAllUsers(request).subscribe((res) =>{
      this.loader = false;
      this.userList = res.body.users;
      this.total = res.body.totalUsers;
     });*/
  }

  /**
   * Responsible for sorting support.
   */
  sort(sort: { key: string; value: string }){
    this.sortBy = sort.key;
    this.sortDirection = sort.value == 'ascend' ? 'ASC' : 'DESC';
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search
    }
    this.users(false);
  }

  // sort(sortAttribute: any) {
  //   this.sortBy=null;
  //   this.sortDirection=null;
  //   if(sortAttribute.value){
  //     this.sortBy = sortAttribute.key;
  //     this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
  //   }
  //   this.users(false);
  // }

  reset(column){
    //this.search = null;
    this.searchObj[column] = "";
    console.log(" Reset search controls => "+JSON.stringify(this.searchObj));
    this.users(true);
  }

  
  /**
   * Create the user.
   */
  submitUserCreateForm(){
    var createMsgId = this.messageService.loading("Creating...").messageId;
    this.disableSubmitButton = true;
    this.disableCancelButton = true;
    this.userService.createUser(this.adminUserModel).subscribe(res => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
      this.messageService.remove(createMsgId);
      this.createUserPopup = false;
      this.users(true);
    },
      err => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
    }
    )       
  }
  

  /**
   * Update the existing user.
   */
  submitUserUpdateForm(){
    this.disableSubmitButton = true;
    this.disableCancelButton = true;
    var updateMsgId = this.messageService.loading("Updating...").messageId;
    this.userService.updateUser(this.adminUserModel).subscribe(res => {
      this.messageService.remove(updateMsgId);
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
      this.updateUserPopup = false;
      this.users(true);
    },
    err => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
    }
  )
  }

    /**
     * Popup the modal
     * @param data 
     */
   makeModelVisible(data,createUserForm:boolean){
    
    if (createUserForm) {
      this.adminUserCreateForm.reset();
      this.createUserPopup = true  
      this.user.setadminUserCreateFormModelVals(data);
    } else {
      this.updateUserPopup = true;
      console.log(" Check admin user data => "+JSON.stringify(this.adminUserModel));
      this.user.setadminUserUpdateFormModelVals(data);
    }
}

  /**
   *  Close the form when click on cancel
   */
   handleCancel(){
     this.updateUserPopup = false;
     this.createUserPopup = false;
   }
}
