import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private stateUrl = 'api/v1/admin/countries';
  countries: [];
  
  constructor(private httpRequest: HttpRequestService) { }
   
    async fetchCountries() {
        if(this.countries == null || this.countries.length == 0){
          let response = await this.httpRequest.get(this.stateUrl).toPromise();
          if(response && response.length > 0){
            this.countries = response;
          }
        }        
    return this.countries;
  }

  // fetchCountries(): Observable<Response[]> {
  //   console.log("existing countries = "+this.countries);
  //   if (!this.countries) {
  //     console.log("in if =="+this.countries);
  //     this.countries = this.httpRequest.get(this.stateUrl).pipe(
  //       publishReplay(1),
  //       refCount()
  //       );
  //     console.log("after call api contries = "+this.countries);
  //   }
    
    // Clear configs
  clearCache() {
      this.countries = null;
      
  }

}
