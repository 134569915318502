import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { QuestionModel } from '../models/question-model';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private stateUrl = 'api/v1/admin/survey-questions';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  questions(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl);
  }

  addQuestion(questionModel:QuestionModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl,questionModel);
  }

  updateQuestion(questionModel:QuestionModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl +'/' +questionModel.id,questionModel);
  }

  deleteById(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl +'/'+id)
  }

  findById(regionId:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/'+regionId);
  }

  updateSortIndexArray(sortIndexArray:[]): Observable<Response> {
    return this.httpRequest.put(this.stateUrl + '/update-orders',sortIndexArray);
  }


  
}
