import { Component, OnInit } from '@angular/core';
import { NzMessageService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { SpeciesFormBuilder, SpeciesModel } from 'src/app/shared/models/species.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CountryService } from 'src/app/shared/services/country.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaitsAndLuresService } from 'src/app/shared/services/baits-and-lures.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-species',
  templateUrl: './add-edit-species.component.html',
  styleUrls: ['./add-edit-species.component.css']
})
export class AddEditSpeciesComponent implements OnInit {
  excLocationsList = [];
  incLocationsList = [];
  updateSpaniesData = [];
  selectedFeildData = {};
  selectedFeildValue: any;
  jsonSpeciesDataArray = [];
  feildNamesList = [];
  field_Name: any;
  isAutoTranslateSpecies: boolean = false;
  showAutoTranslate: boolean;
  fields = [];
  baitsNLuresList: any;
  speciesData: any;
  spESForm: FormGroup;
  species: SpeciesFormBuilder = new SpeciesFormBuilder();
  speciesModel: SpeciesModel = this.species.speciesModel;
  speciesForm: FormGroup = this.species.speciesForm;
  processSubmitForm: boolean;
  fileList: UploadFile[] = [];
  thumbFileList: UploadFile[] = [];
  editSpeciesId: string = '';
  isEditMode: boolean = false;
  isLocIncLoading: boolean = false;
  isLocExcLoading: boolean = false;
  searchChange$ = new BehaviorSubject('');
  editSpanishPopup: boolean = false;
  disableSubmitButton: boolean = false;
  spanishPopupKey: String = '';
  previewImage = '';
  previewVisible = false;
  isAutoTranslateSpeciesControl = new FormControl(false);

  constructor(private messageService: NzMessageService, private fb: FormBuilder,
    private regionService: RegionService,
    private baitsNLuresService: BaitsAndLuresService,
    private router: Router,
    private route: ActivatedRoute,
    private speciesService: SpeciesService,
    private http: HttpClient) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
        this.species.setModelValues(this.router.getCurrentNavigation().extras.state.species);
      }
    });

    this.spESForm = this.fb.group({
      fieldName: new FormControl(null, [Validators.required]),
      fieldValue: new FormControl(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.isEditMode = this.route.snapshot.params.speciesId ? true : false;
    this.showAutoTranslate = this.route.snapshot.params.speciesId ? true : false;
    this.speciesData = this.species.speciesModel;
    this.fetchAllBaitsNLures();
    if (this.isEditMode) {
      this.findSpeciesById(this.route.snapshot.params.speciesId);
      this.editSpeciesId = this.route.snapshot.params.speciesId;
    }
  }

  compareFn(obj1: any, obj2: any) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  /**
   * This method is used to get all the details for species.
   * 
   * @param speciesId 
   */
  findSpeciesById(speciesId: any) {
    var loadMessage = this.messageService.loading("Loading...", { nzDuration: 0 }).messageId;
    this.speciesService.findById(speciesId).subscribe((res) => {

      if (res.body.locationsExcluded) {
        this.excLocationsList = this.excLocationsList.concat(res.body.locationsExcluded);
      }
      if (res.body.locationsIncluded) {
        this.incLocationsList = this.incLocationsList.concat(res.body.locationsIncluded);
      }
      //this.locationsList = this.removeDuplicates(this.locationsList);
      this.species.setModelValues(res.body);

      if (this.speciesModel.referenceImagesUrls) {
        this.speciesModel.referenceImagesUrls.forEach(refImg => {
          let uploadedFile: UploadFile = {
            name: refImg.fileName,
            url: refImg.filePath,
            uid: refImg.fileName,
            size: 10,
            type: '',
            status: 'done'
          };
          this.fileList = this.fileList.concat(uploadedFile);
        });
      }

      if (this.speciesModel.thumbImageUrl) {

        let uploadedFile: UploadFile = {
          name: this.speciesModel.thumbImageUrl.fileName,
          url: this.speciesModel.thumbImageUrl.filePath,
          uid: this.speciesModel.thumbImageUrl.fileName,
          size: 10,
          type: '',
          status: 'done'
        };
        this.thumbFileList = [uploadedFile];
      }
      this.messageService.remove(loadMessage);
    });
  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  }

  removeDuplicates = (array: any): any => {
    let a = []
    let resArr = [];
    array.map((x) => {
      if (!a.includes(x.id)) {
        a.push(x.id)
        resArr.push(x);
      }
    });
    return resArr;
  }

  imageFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (uploadFileList: UploadFile[]) => {
        var file = uploadFileList[0];
        if (uploadFileList[0].name && !(uploadFileList[0].name.toLowerCase().endsWith("jpg") || uploadFileList[0].name.toLowerCase().endsWith("png") ||
          uploadFileList[0].name.toLowerCase().endsWith("jpeg") || uploadFileList[0].name.toLowerCase().endsWith("gif") ||
          uploadFileList[0].name.toLowerCase().endsWith("bmp"))) {
          this.messageService.error("Only image file upload is supported", { nzDuration: 2000 });
          return [];
        }
        return uploadFileList;
      }
    }];

  /**
     * Add and update the region form
     */
  submitSpeciesForm() {
    this.processSubmitForm = true;
    //If auto translate for species check box checked then called API
    if(this.isAutoTranslateSpeciesControl.value){
       const speciesId = this.speciesModel.id;
        this.feildNamesList = ['commonName', 'appearance', 'habitat', 'size', 'behaviour', 'tips'];
        //Create json for indivituals species feilds
        this.feildNamesList.forEach(fieldName => {
          if (fieldName === 'commonName') {
            // Convert 'commonName' to 'name'
            this.spESForm.get('fieldName').patchValue('name');
            this.field_Name = 'name';
          } else {
            this.field_Name = fieldName;
          }
          const jsonObject = {
            adminModuleName: 'SPECIES',
            fieldName: `${this.field_Name}`,
            value: this.speciesForm.get(fieldName).value,
            targetLanguage: 'ES',
            targetText: '',
            sourceLanguage: ''
          };
          this.jsonSpeciesDataArray.push(jsonObject);
        });
  
        this.speciesService.toUpdateSpecies(speciesId, this.jsonSpeciesDataArray).subscribe((res) => {
          const success = res.body;
        }, error => {
          this.messageService.error("Your species are not updated, Try Again", { nzDuration: 4000 });
        })
      }else {
        this.isAutoTranslateSpeciesControl.setValue(false);
      }

    for (const i in this.speciesForm.controls) {
      this.speciesForm.controls[i].markAsDirty();
      this.speciesForm.controls[i].updateValueAndValidity();
    }

    let locationExclude: any = [];
    if (this.speciesModel.locationsExclude) {
      this.speciesModel.locationsExclude.forEach(l => {
        locationExclude.push(l.id);
      });
    }
    this.speciesModel.locationsExclude = locationExclude;

    let locationInclude: any = [];
    if (this.speciesModel.locationsInclude) {
      this.speciesModel.locationsInclude.forEach(l => {
        locationInclude.push(l.id);
      })
    }
    this.speciesModel.locationsInclude = locationInclude;

    // Preparing Model and file to formData
    var regionDTOBlob = new Blob([JSON.stringify(this.speciesModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('speciesDTO', regionDTOBlob);
    this.fileList.forEach((file: any) => {
      if (file.status !== 'done') {
        formData.append('speciesImages', file.originFileObj);
      }
    });
    this.thumbFileList.forEach((file: any) => {
      formData.append('thumbImage', file.originFileObj);
    });

    if (this.speciesModel.id) {
      let updateMsgId = this.messageService.loading("Updating...", { nzDuration: 0 }).messageId;
      this.speciesService.updateSpecies(formData, this.speciesModel.id).subscribe((res) => {
        this.messageService.remove(updateMsgId);
        this.messageService.success("Successfully updated.", { nzDuration: 1000 });
        this.isAutoTranslateSpeciesControl.setValue(false);
        this.speciesService.clearAllLiteSpecies();
        this.jsonSpeciesDataArray = [];

        // this.router.navigate(['/species']);
        this.processSubmitForm = false;
        let tmpList = this.fileList;
        this.fileList = [];
        tmpList.forEach(refImg => {
          refImg.status = 'done'
          this.fileList = this.fileList.concat(refImg);
        });
      },
        err => {
          this.processSubmitForm = false;
        }
      );
    }else {
      let saveMsgId = this.messageService.loading("Saving...", { nzDuration: 0 }).messageId;
      this.speciesService.addSpecies(formData).subscribe((res) => {
        this.messageService.remove(saveMsgId);
        this.speciesService.clearAllLiteSpecies();
        this.router.navigate(['/species']);
      },
        err => {
          this.processSubmitForm = false;
        }
      );
    }

  
  }


  /**
   * Fetch all the countries
   */
  // async fetchCountries(startParam) {
  //     this.regionService.getAllSystemLocations(startParam).subscribe(res => {
  //       this.locationsList = res.body;
  //     });
  // }

  fetchAllBaitsNLures() {
    this.baitsNLuresService.fetchAllBaitsNLures().subscribe((res) => {
      this.baitsNLuresList = res;
    });
  }
  /**
  * This method is responsible for redirecting to species page
  */
  forwardToRegionsPage() {
    this.router.navigate(['/species']);
  }

  beforeUploadThumb = (file: UploadFile): boolean => {
    this.thumbFileList = [];
    this.thumbFileList = this.thumbFileList.concat(file);
    return true;
  };

  handleChange(info: any): void {
    if (info.file.status === 'uploading') {
      // Get this url from response in real world.
      // this.fileList = this.fileList.filter(item => {
      this.getBase64(info.file.originFileObj, (img: string) => {
        info.file.url = img;
        info.file.status = 'pending';
        // });
        //this.fileList.push(info.file);
        return true;
      });
    }
  }

  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  deleteReferenceImage = (file: any): void => {

    if (!this.speciesModel.removedRefImages) {
      this.speciesModel.removedRefImages = [file.name];
    } else {
      this.speciesModel.removedRefImages.push(file.name);
    }
    this.fileList = this.fileList.filter(f => f.name !== file.name);
  }

  deleteThumbImage = (file: any): void => {
    if (!this.speciesModel.removedThumbImages) {
      this.speciesModel.removedThumbImages = [file.name];
    } else {
      this.speciesModel.removedThumbImages.push(file.name);
    }
    this.thumbFileList = this.thumbFileList.filter(f => f.name !== file.name);
  }

  onSearchLocExc(value: string): void {

    this.searchChange$.next(value);
    if (!value || value.trim().length < 3) {
      return;
    }
    this.isLocIncLoading = true;
    this.regionService.getAllSystemLocations(value).subscribe(res => {
      this.excLocationsList = res.body;
      this.isLocIncLoading = false;
    });
  }

  onSearchLocInc(value: string): void {

    this.searchChange$.next(value);
    if (!value || value.trim().length < 3) {
      return;
    }
    this.isLocIncLoading = true;
    this.regionService.getAllSystemLocations(value).subscribe(res => {
      this.incLocationsList = res.body;
      this.isLocIncLoading = false;
    });
  }

  editSpanish(fieldName: string) {
    const fValue = this.speciesForm.get(`${fieldName}`).value;
    if (fieldName === 'commonName') {
      // Convert 'commonName' to 'name'
      this.spESForm.get('fieldName').patchValue('name');
      this.field_Name = 'name';
    } else {
      this.field_Name = fieldName;
    }
    const spaceID = this.speciesModel.id;
    let spaneData = {
      "adminModuleName": "SPECIES",
      "fieldName": `${this.field_Name}`,
      "value": `${fValue}`,
      "targetLanguage": "ES",
      "targetText": `${fValue}`,
      "sourceLanguage": "ES"
    }

    this.speciesService.editTranslateSpanis(spaceID, spaneData).subscribe((resp) => {
      this.selectedFeildData = resp.body;
      this.spESForm.patchValue({
        fieldName: fieldName,
        fieldValue: this.selectedFeildData['value']
      });
      switch (fieldName) {
        case 'commonName':
          this.spanishPopupKey = 'Common Name';
          this.editSpanishPopup = true;
          break;
        case 'appearance':
          this.spanishPopupKey = 'Appearance';
          this.editSpanishPopup = true;
          break;
        case 'habitat':
          this.spanishPopupKey = 'Habitat';
          this.editSpanishPopup = true;
          break;
        case 'size':
          this.spanishPopupKey = 'Size';
          this.editSpanishPopup = true;
          break;
        case 'behaviour':
          this.spanishPopupKey = 'Behaviour';
          this.editSpanishPopup = true;
          break;
        case 'tips':
          this.spanishPopupKey = 'Tips';
          this.editSpanishPopup = true;
          break;
        default:
          this.editSpanishPopup = false;
          break;
      }
    }, error => {
      this.messageService.error("Something Missing, Try Again", { nzDuration: 4000 });
    })


  }

  OnUpdateSpecies() {
    const fieldN = this.spESForm.get('fieldName').value;
    if (fieldN === 'commonName') {
      // Convert 'commonName' to 'name'
      this.spESForm.get('fieldName').patchValue('name');
      this.field_Name = 'name';
    } else {
      this.field_Name = fieldN;
    }
    const fieldV = this.spESForm.get('fieldValue').value;
    const sID = this.speciesModel.id;
    let obj = {
      "adminModuleName": "SPECIES",
      "fieldName": `${this.field_Name}`,
      "value": "",
      "targetLanguage": "ES",
      "targetText": `${fieldV}`,
      "sourceLanguage": "ES"
    }
    this.updateSpaniesData.push(obj);

    this.speciesService.toUpdateSpecies(sID, this.updateSpaniesData).subscribe((res) => {
      const data = res.body;
      this.editSpanishPopup = false;
      this.messageService.success("Successfully updated.", { nzDuration: 1000 });
    }, error => {
      this.messageService.error("Your Text is not update, Try Again", { nzDuration: 1000 });
    });
  }

  onAutoTranslate(event: any) {
    this.isAutoTranslateSpeciesControl.setValue(event.target.checked)
  }

  handleCancel() {
    this.editSpanishPopup = false;
  }
}
