import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService {

  private stateUrl = 'api/v1/admin/users';

  constructor(private httpRequest: HttpRequestService) { }

  userFeedbackList(body: any): Observable<any> {
    return this.httpRequest.post(this.stateUrl + "/feedback", body);
  }

  userFeedbackReply(body : any): Observable<any> {
    return this.httpRequest.post(this.stateUrl + "/feedback-reply", body);
  }

  archiveFeedback(feedbackId : String): Observable<any> {
    return this.httpRequest.delete(this.stateUrl + "/" + feedbackId);
  }
}
