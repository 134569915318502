import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface QuestionModel {
    id:string
    question: string;
    group: string;
    questionType: string;
    sortOrder: Number;
    questionState: string;
    options:any
}

export class QuestionFormBuilder {

    questionModel: QuestionModel = {
        id:null,
        question: null,
        group: null,
        questionType: null,
        sortOrder: null,
        questionState: null,
        options:null
    };

    questionForm: FormGroup = new FormGroup({
        id: new FormControl(this.questionModel.id),
        question: new FormControl(this.questionModel.question, [Validators.required]),
        group: new FormControl(this.questionModel.group),
        questionType: new FormControl(this.questionModel.questionType, [Validators.required]),
        sortOrder: new FormControl(this.questionModel.sortOrder),
        questionState: new FormControl(this.questionModel.questionState, [Validators.required]),
        options: new FormControl(this.questionModel.options)
    });

    constructor() {
        this.questionForm.valueChanges.subscribe(val => {
            this.questionModel.id = val.id;
            this.questionModel.question = val.question;
            this.questionModel.group = val.group;
            this.questionModel.questionType = val.questionType;
            this.questionModel.sortOrder = val.sortOrder;
            this.questionModel.questionState = val.questionState;
            this.questionModel.options = val.options;
        })
    }


    setModelVals(res: any) {
        this.questionForm.controls.id.setValue(res.id);
        this.questionForm.controls.question.setValue(res.question);
        this.questionForm.controls.group.setValue(res.group);
        this.questionForm.controls.questionType.setValue(res.questionType);
        this.questionForm.controls.sortOrder.setValue(res.sortOrder);
        this.questionForm.controls.questionState.setValue(res.questionState);
        this.questionForm.controls.options.setValue(res.options);
    }
}