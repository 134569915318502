import { FormGroup, FormControl, Validators } from "@angular/forms";

export class BaitsModel {

    id: string;
    name: string;
    companyName: string;
    affiliateLinks: string;
    imageUrl: string;
    removedImages: [string];
}

export class BaitsModelFormBuilder {
    baitsModel: BaitsModel = {
        id: null,
        name: null,
        companyName: null,
        affiliateLinks: null,
        imageUrl: null,
        removedImages: null
    }

    baitsForm: FormGroup = new FormGroup({
        id: new FormControl(this.baitsModel.id),
        name: new FormControl(this.baitsModel.name, [Validators.required]),
        companyName: new FormControl(this.baitsModel.companyName, [Validators.required]),
        affiliateLinks: new FormControl(this.baitsModel.affiliateLinks, [Validators.required]),
        imageUrl: new FormControl(this.baitsModel.imageUrl, [Validators.required])
    });

    constructor() {
        this.baitsForm.valueChanges.subscribe(val => {
            this.baitsModel.id = val.id;
            this.baitsModel.name = val.name;
            this.baitsModel.companyName = val.companyName;
            this.baitsModel.affiliateLinks = val.affiliateLinks;
            this.baitsModel.imageUrl = val.imageUrl;
        });
    }

    setModelVals(res: any) {
        this.baitsForm.controls.id.setValue(res.id),
            this.baitsForm.controls.name.setValue(res.name),
            this.baitsForm.controls.companyName.setValue(res.companyName),
            this.baitsForm.controls.affiliateLinks.setValue(res.affiliateLinks),
            this.baitsForm.controls.imageUrl.setValue(res.imageUrl);
    }
}


