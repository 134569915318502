import { FormControl, Validators, FormGroup } from '@angular/forms';

export interface SignInModel {
    email: string;
    password: string;
    remember: boolean;
}

export class SignInFormBuilder {

    signInModel: SignInModel = {
        email: '',
        password: '',
        remember:false
    };

    signInForm: FormGroup = new FormGroup({
        email: new FormControl(this.signInModel.email, [Validators.required]),
        password: new FormControl(this.signInModel.password, [Validators.required])
    });

    constructor() {
            this.signInForm.valueChanges.subscribe(val => {
                this.signInModel.password = val.password;
                this.signInModel.email = val.email;
        })
    }
   setModelVals(res:any) {
        this.signInForm.controls.email.setValue(res.email),
        this.signInForm.controls.name.setValue(res.name)
    }
}