import { Component, OnInit } from '@angular/core';
import { BaitsAndLuresService } from 'src/app/shared/services/baits-and-lures.service'
import { NzMessageService, UploadFilter, UploadFile, NzModalService } from 'ng-zorro-antd';
import { TableService } from 'src/app/shared/services/table.service';
import { BaitsModel } from 'src/app/shared/models/baits-model';

@Component({
  selector: 'app-baits',
  templateUrl: './baits.component.html',
  styleUrls: ['./baits.component.css']
})
export class BaitsComponent implements OnInit {
  BaitList: any;
  displayData: any;
  sortAttribute: any;
  baitsModel: BaitsModel;
  formHeaderText: String;
  isVisible: boolean;
  fileList: UploadFile[] = [];
  imageFileList: UploadFile[] = [];
  submitButton: boolean;
  file: UploadFile;
  previewImage = '';
  previewVisible = false; 
  baitsLoading = false;

  constructor(private baitService: BaitsAndLuresService,
    private messageService: NzMessageService,
    private tableService: TableService,
    private modelService: NzModalService) {
    this.baitsModel = new BaitsModel();
  }

  ngOnInit() {
    this.fetchAllBaitsNLures();
  }

  /**
   * Get list of Baits & Lures
   */
  fetchAllBaitsNLures() {
    //const loadingData = this.messageService.loading('Loading...').messageId;
    this.baitsLoading = true;
    this.baitService.fetchAllBaitsNLures().subscribe(res => {
      //this.messageService.remove(loadingData);
      this.baitsLoading = false;
      this.BaitList = res;
      this.displayData = this.BaitList;
    });
  }

  /**
  * Sort List
  * @param sortAttribute 
  */
  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableService.sort(sortAttribute, this.BaitList);
  }

  /**
   * Open Model Window for Add and Update
   * @param baitsData 
   */
  openModelWindow(baitsData: any) {
    this.isVisible = true;
    if (baitsData == null) {
      this.formHeaderText = 'Create New Baits & Lure';
      this.baitsModel = new BaitsModel();
      this.imageFileList = [];
      this.submitButton = true;
      
    } else {
      this.formHeaderText = 'Update Baits & Lure';
      const baitsDataClone = Object.assign({}, baitsData);
      this.baitsModel = baitsDataClone;
      if (this.baitsModel.imageUrl) {
        let uploadedFile: UploadFile = {
          url: this.baitsModel.imageUrl,
          uid: '',
          name: '',
          size: 10,
          type: '',
          status: 'done'
        };
        this.imageFileList = [uploadedFile];
      }
      this.submitButton = false;
    }
  }

  /**
   * Event on Cancel Button
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Allowed image type for upload image
   */
  imageFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (uploadFileList: UploadFile[]) => {
        var file = uploadFileList[0];
        if (uploadFileList[0].name && !(uploadFileList[0].name.toLowerCase().endsWith("jpg") || uploadFileList[0].name.toLowerCase().endsWith("png") ||
          uploadFileList[0].name.toLowerCase().endsWith("jpeg") || uploadFileList[0].name.toLowerCase().endsWith("gif") ||
          uploadFileList[0].name.toLowerCase().endsWith("bmp"))) {
          this.messageService.error("Only image file upload is supported", { nzDuration: 2000 });
          return [];
        }
        return uploadFileList;
      }
    }];

  /**
   * Event on Submit Button
   */
  onSubmit() {
    var regionDTOBlob = new Blob([JSON.stringify(this.baitsModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('adminBaitsDTO', regionDTOBlob);
    this.imageFileList.forEach((file: any) => {
      formData.append('file', file.originFileObj);
    });

    if (this.baitsModel.id != null) {
      var updateMsgId = this.messageService.loading('Updating...').messageId;
      this.baitService.updateBaits(formData).subscribe(res => {
        this.messageService.remove(updateMsgId);
        this.modelService.closeAll();
        this.fetchAllBaitsNLures();
      })

    } else {
      this.submitButton = true;
      const loadingData = this.messageService.loading('Saving ...').messageId;
      this.baitService.saveBaits(formData).subscribe((res) => {
        this.messageService.remove(loadingData);
        this.modelService.closeAll();
        this.fetchAllBaitsNLures();
      });
    }
  }

/**
 * Preview image
 */
handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
}

  /**
   * On change image
   * @param info 
   */
  handleChange(info: any): void {
    if (info.file.status === 'uploading') {
      this.getBase64(info.file.originFileObj, (img: string) => {
        info.file.url = img;
        info.file.status = 'done';
        return true;
      });
    }
    this.OnSubmitButton();
  }


  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Remove Image
   */
  deleteImage = (file: any): void => {
    if (!this.baitsModel.removedImages) {
      this.baitsModel.removedImages = [file.name];
    } else {
      this.baitsModel.removedImages.push(file.name);
    }
    this.imageFileList = this.imageFileList.filter(f => f.name !== file.name);
    this.OnSubmitButton();
  }

/**
 * Delete Confirmation model
 * @param id
 */
  DeleteBaitsForm(id): void {
    this.modelService.confirm({
      nzTitle: 'Delete this Baits & Lure ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteBaits(id),
      nzCancelText: 'No'
    });
  }

  /**
   * Delete Bait & Lure
   * @param id
   */
  deleteBaits(id) {
    var deleteMsgId = this.messageService.loading("Deleting...").messageId;
    this.baitService.deleteBaits(id).subscribe((res) => {
      this.messageService.remove(deleteMsgId);
      this.removeBaitsFromList(id);
    });
  }

  /**
   * Remove Bait & Lure from list
   * @param id 
   */
  removeBaitsFromList(id) {
    this.BaitList.forEach((baits, index) => {
      if (baits.id === id) {
        this.BaitList.splice(index, 1);
        return false;
      }
    });
    this.displayData = this.BaitList;
  }

  /**
   * Submit button enable - disable on model
   */
  OnSubmitButton() {
    if (this.baitsModel.name != null && this.baitsModel.companyName != null && this.baitsModel.affiliateLinks != null && this.imageFileList.length != 0 &&
      this.baitsModel.name != "" && this.baitsModel.companyName != "" && this.baitsModel.affiliateLinks != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }
}
