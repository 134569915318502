import { Component, OnInit } from '@angular/core';
import { NzMessageService, UploadFilter, UploadFile, NzModalService } from 'ng-zorro-antd';
import { TableService } from 'src/app/shared/services/table.service';
import { UsersReviewModel } from 'src/app/shared/models/users-review-model';
import { UsersReviewService } from 'src/app/shared/services/users-review.service';
import { NgForm } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.css']
})
export class UsersReviewComponent implements OnInit {
  usersReviewList: any;
  displayData: any;
  sortAttribute: any;
  usersReviewModel: UsersReviewModel;
  formHeaderText: String;
  isVisible: boolean = false;
  imageFileList: UploadFile[] = [];
  submitButton: boolean = true;
  file: UploadFile;
  previewImage = '';
  previewVisible = false; 
  usersReviewLoading = false;
  title = '';

  constructor(private usersReviewService: UsersReviewService,
    private router: Router,
    private messageService: NzMessageService,
    private tableService: TableService,
    private modelService: NzModalService) {
    this.usersReviewModel = new UsersReviewModel();
  }

  ngOnInit() {
    this.fetchAllUsersReview();
  }

  /**
   * Get list of Baits & Lures
   */
  fetchAllUsersReview() {
    this.usersReviewLoading = true;
    this.usersReviewService.fetchAllUsersReview().subscribe(res => {
      this.usersReviewLoading = false;
      this.usersReviewList = res;
      this.displayData = this.usersReviewList;
      this.title = this.displayData[0].title;
    });
  }

  /**
  * Sort List
  * @param sortAttribute 
  */
  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableService.sort(sortAttribute, this.usersReviewList);
  }


  openModelWindow(usersReviewData: any) {
    this.isVisible = true;
    if (usersReviewData == null) {
      this.formHeaderText = 'Create New UsersReview';
      this.usersReviewModel = new UsersReviewModel();
      this.imageFileList = [];
      this.submitButton = true;
      
    } else {
      this.formHeaderText = 'Update UsersReview';
      const usersReviewDataClone = Object.assign({}, usersReviewData);
      this.usersReviewModel = usersReviewDataClone;
      if (this.usersReviewModel.imageUrl) {
        let uploadedFile: UploadFile = {
          url: this.usersReviewModel.imageUrl,
          uid: '',
          name: '',
          size: 10,
          type: '',
          status: 'done'
        };
        this.imageFileList = [uploadedFile];
      }
      this.submitButton = false;
    }
  }

  /**
   * Event on Cancel Button
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Allowed image type for upload image
   */
  imageFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (uploadFileList: UploadFile[]) => {
        var file = uploadFileList[0];
        if (uploadFileList[0].name && !(uploadFileList[0].name.toLowerCase().endsWith("jpg") || uploadFileList[0].name.toLowerCase().endsWith("png") ||
          uploadFileList[0].name.toLowerCase().endsWith("jpeg") || uploadFileList[0].name.toLowerCase().endsWith("gif") ||
          uploadFileList[0].name.toLowerCase().endsWith("bmp"))) {
          this.messageService.error("Only image file upload is supported", { nzDuration: 2000 });
          return [];
        }
        return uploadFileList;
      }
    }];

  /**
   * Event on Submit Button
   */
  onSubmit(form?: NgForm) {
    var usersReviewDtoBlob = new Blob([JSON.stringify(this.usersReviewModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('usersReviewDto', usersReviewDtoBlob);
    this.imageFileList.forEach((file: any) => {
      formData.append('file', file.originFileObj);
    });

    if (this.usersReviewModel.id != null) {
      var updateMsgId = this.messageService.loading('Updating...').messageId;
      this.usersReviewService.updateUsersReview(formData).subscribe(res => {
        this.messageService.remove(updateMsgId);
        this.modelService.closeAll();
        this.fetchAllUsersReview();
      })
    } else {
      this.submitButton = true;
      const loadingData = this.messageService.loading('Saving ...').messageId;
      this.usersReviewService.saveUsersReview(formData).subscribe((res) => {
        this.messageService.remove(loadingData);
        this.modelService.closeAll();
        this.fetchAllUsersReview();
      });
    }
    this.handleCancel(form);
  }

/**
 * Preview image
 */
handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
}

  /**
   * On change image
   * @param info 
   */
  handleChange(info: any): void {
    if (info.file.status === 'uploading') {
      this.getBase64(info.file.originFileObj, (img: string) => {
        info.file.url = img;
        info.file.status = 'done';
        return true;
      });
    }
    this.OnSubmitButton();
  }


  private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  /**
   * Remove Image
   */
  deleteImage = (file: any): void => {
    if (!this.usersReviewModel.removedImages) {
      this.usersReviewModel.removedImages = [file.name];
    } else {
      this.usersReviewModel.removedImages.push(file.name);
    }
    this.imageFileList = this.imageFileList.filter(f => f.name !== file.name);
    this.OnSubmitButton();
  }

/**
 * Delete Confirmation model
 * @param id
 */
  DeleteUsersReviewForm(id): void {
    this.modelService.confirm({
      nzTitle: 'Delete this User Review ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteUsersReview(id),
      nzCancelText: 'No'
    });
    this.fetchAllUsersReview();
  }

  /**
   * Delete Users Review
   * @param id
   */
  deleteUsersReview(id) {
    var deleteMsgId = this.messageService.loading("Deleting...").messageId;
    this.usersReviewService.deleteUsersReview(id).subscribe((res) => {
      this.messageService.remove(deleteMsgId);
      this.removeUsersReviewFromList(id);
    });
  }

  /**
   * Remove Users Review from list
   * @param id 
   */
  removeUsersReviewFromList(id) {
    this.usersReviewList.forEach((usersreview, index) => {
      if (usersreview.id === id) {
        this.usersReviewList.splice(index, 1);
        return false;
      }
    });
    this.displayData = this.usersReviewList;
  }

  /**
   * Submit button enable - disable on model
   */
  OnSubmitButton() {
    if (this.usersReviewModel.userName != null && this.usersReviewModel.review != null && this.imageFileList.length != 0 &&
       this.usersReviewModel.userName != "" && this.usersReviewModel.review != "") {
          this.submitButton = false;
    } else {
          this.submitButton = true;
    }
  }

  handleCancel(form?: NgForm): void {
    this.isVisible = false;
    if (form) {
      this.resetForm(form); 
    }
  }
  
  resetForm(form: NgForm): void {
    form.resetForm();    
    this.usersReviewModel = {
      userName: '',
      rating:0,
      review: '',
      imageUrl: '',
      removedImages: null,
      id: ''
    };
    this.imageFileList = []; 
    this.submitButton = true;
  }

  goToAddEditUsersReview(usersReview) {
    let navigationExtras: NavigationExtras = {
      state: {
        usersReview: usersReview
      }
    };
    this.router.navigate(['/users-review', usersReview.id], navigationExtras);
  }
}
