import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { InputSourceModel } from '../models/input-source-model';

@Injectable({
  providedIn: 'root'
})
export class InputSourceService {

  private inputSourceUrl = 'api/v1/admin/input-source';
  constructor(
    private httpRequest: HttpRequestService
  ) { }
  
  addInputSource(inputSourceModel:InputSourceModel): Observable<Response> {
    return this.httpRequest.post(this.inputSourceUrl, inputSourceModel);
  }

  inputSourceList(): Observable<Response> {
    return this.httpRequest.get(this.inputSourceUrl);
  }

  updateInputSource(formData: FormData): Observable<Response[]> {
    return this.httpRequest.filePut(this.inputSourceUrl, formData);
  }

  deleteByCountryAndState(countryId, stateId): Observable<Response> {
    return this.httpRequest.delete(this.inputSourceUrl + '/' + countryId +'/'+stateId)
  }

  indexing(): Observable<Response> {
    return this.httpRequest.postIndexing(this.inputSourceUrl+'/indexing');
  }

}
