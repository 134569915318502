import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd';
import { adminUserFormBuilder, AdminUserModel } from 'src/app/shared/models/admin-user-model';
import { FormGroup } from '@angular/forms';
import { UsersPromoService } from 'src/app/shared/services/user-promo.service';

@Component({
  selector: 'app-users-promo',
  templateUrl: './users-promo.component.html',
  styleUrls: ['./users-promo.component.css']
})
export class UsersPromoComponent implements OnInit {

  constructor(private userPromoService: UsersPromoService,
    private datePipe: DatePipe,
    private messageService : NzMessageService  
    ) { }

  userList:any=[];
  search:any
  loader:boolean;
  pageIndex = 1;
  pageSize = 10;
  sortBy = "userName";
  sortDirection = "ASC";
  total = 100;
  updateUserPopup: boolean = false;
  createUserPopup : boolean = false;
  disableSubmitButton: boolean;
  disableCancelButton : boolean; 
  searchObj = {
    "promoId": "",
    "promoCode": "",
    "status": "", 			
    "validityDays": null, 	
    "promoStartDate": "", 	
    "promoEndDate": "",   	
    "userId": "",
    "userName": "",
    "userEmail": "",
    "startDate": "",
    "endDate": ""  
};
  
  ngOnInit() {
     this.usersPromo(true);
  }

  /**
   * This method is responsible for fetching users
   */
  usersPromo(reset: boolean = false){

    if (reset) {
      this.pageIndex = 1;
    }
    let searchRequest = {...this.searchObj};
  
    if(searchRequest['startDate'][0]){
      searchRequest["userPromoStartDateMin"] = this.datePipe.transform(searchRequest['startDate'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["userPromoStartDateMax"] =  this.datePipe.transform(searchRequest['startDate'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['startDate'] ="";
    }
    if(searchRequest['endDate'][0]){
      searchRequest["userPromoEndDateMin"] = this.datePipe.transform(searchRequest['endDate'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["userPromoEndDateMax"] =  this.datePipe.transform(searchRequest['endDate'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['endDate'] ="";
    }
  

    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search,
      "parameters": searchRequest
    }

    this.loader = true;
    this.userPromoService.getAllUsers(request).subscribe((res) =>{
      this.loader = false;
      this.userList = res.body.usersPromo;
      this.total = res.body.totalUsers;
     },
     (res) =>{
      this.loader = false;
     });
  }

  collapseExpand(data){
    if (!data.isProhibited) {
      data.expand = data.expand ? false: true;   
    }
  }
  
  /**
   * Responsible for searching on table
   */
  searchInData(column:any){
    this.usersPromo(true)
  }

  /**
   * Responsible for sorting support.
   */
  sort(sort: { key: string; value: string }){
    this.sortBy = sort.key;
    this.sortDirection = sort.value == 'ascend' ? 'ASC' : 'DESC';
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search
    }
    this.usersPromo(false);
  }

  reset(column){
    this.searchObj[column] = "";
    this.usersPromo(true);
  }

}
