import { Component, OnInit, TemplateRef} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { QuestionService } from 'src/app/shared/services/question.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { QuestionFormBuilder, QuestionModel } from 'src/app/shared/models/question-model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

    questionList: any;
    maintainSortOrder:any
    displayData:any
    question: QuestionFormBuilder = new QuestionFormBuilder();
    questionModel: QuestionModel = this.question.questionModel;
    questionForm: FormGroup = this.question.questionForm;
    listOfOption = [];
    options = [];
    disableSubmitButton:boolean; 
    sortedList:any;
    currentSelectedTab:any = 'PRE';
    nextSortOrderÍndex:Number = 1;
    sortOrderIndexArray:any = []
    isVisible:boolean = false;
    formHeaderText = 'Create New Question';
    questionLoading = false;
   

    ngOnInit(): void {
        const children = []
        this.options = children;
        this.questions();     
    }
    
    constructor(private modalService: NzModalService, private questionService: QuestionService, private messageService: NzMessageService,private router:Router) {
    }

    /**
     * Get the list of questions
     */
    questions() {
       // var loadingData = this.messageService.loading("Loading questions...").messageId;
       this.questionLoading = true;
        this.questionService.questions().subscribe((res) => {
            this.questionLoading = false;
           // this.messageService.remove(loadingData);
            this.questionList = res.body;
            this.displayData = this.questionList;
        }
        );
    }

    /**
     * For Drop feature for pre question list
     * @param event 
     */
    dropForQuestion(event: CdkDragDrop<string[]>): void {
        
        moveItemInArray(this.questionList, event.previousIndex, event.currentIndex);
        let i = 0;
        this.questionList.forEach(question => {
            let sortOrderIndex = {
                questionId: null,
                sortOrder: 0
            }
            sortOrderIndex.sortOrder = ++i;
            sortOrderIndex.questionId = question.id;;
            this.sortOrderIndexArray.push(sortOrderIndex);
        });
        this.messageService.remove();
        var sortMsgId = this.messageService.loading("Updating...").messageId;
        this.questionService.updateSortIndexArray(this.sortOrderIndexArray).subscribe( res => {
            this.messageService.remove(sortMsgId);
            this.sortOrderIndexArray = [];
        });
       
    }

    /**
     *  This method delete the delete the question based on id
     * @param id 
     */
    deleteQuestion(id){
        this.questionService.deleteById(id);
        var msgId = this.messageService.loading("Deleting...",{ nzDuration: 0 }).messageId;
        this.questionService.deleteById(id).subscribe((res) => {      
          this.messageService.remove(msgId);        
          this.removeQuestion(id);
        });
    }

    /**
     * This method remove the data from list
     * @param id 
     */
    removeQuestion(id){
        this.questionList.forEach( (question, index) => {
          if(question.id === id){
            this.questionList.splice(index,1);
            return false;
          } 
        });
        this.questionList = [...this.questionList];
        this.displayData = this.questionList;
     }
  
     /**
      * Create delete confirmation box 
      * @param id 
      * @param type 
      */
     showDeleteConfirmModal(id): void {
        this.modalService.confirm({
          nzTitle: 'Delete this Question ?',
          nzOkText: 'Yes',
          nzOkType: 'danger',
          nzOnOk: () => this.deleteQuestion(id),
          nzCancelText: 'No'
        });
      }


    /**
     * Detect tab change
     * @param tab 
     */
    tabChange(tab:any){
        this.currentSelectedTab = tab;
    }

    /**
     * Decide the next sort index
     */
    getNextSortIndex(group:any){
        this.nextSortOrderÍndex = 1;
        if(!this.questionList){
            var groupQuestions = this.questionList.filter(question => question.group === group);
            var max = groupQuestions.reduce(function (prev, current) {
                return (prev.sortOrder > current.sortOrder) ? prev : current
             });
             this.nextSortOrderÍndex = max ? max.sortOrder + 1: 1;
        }
      return this.nextSortOrderÍndex;      
    }

    /**
     * Open the modal question form
     * @param questionData 
     */
    OpenQuestionForm(questionData:any){
        this.isVisible = true;
        if (questionData != null) {
            this.formHeaderText = "Update Question"
            this.question.setModelVals(questionData);      
        }
        else{
            this.formHeaderText = "Create New Question"
            this.questionForm.reset();
        }
    }

    /**
     * Model submit button event
     */
    handleOk(){
      // submit question form
      this.disableSubmitButton = true;      
      for (const i in this.questionForm.controls) {
            this.questionForm.controls[i].markAsDirty();
            this.questionForm.controls[i].updateValueAndValidity();
        }
        this.questionForm.controls.group.setValue(this.currentSelectedTab);
        this.questionForm.controls.sortOrder.setValue(this.getNextSortIndex(this.currentSelectedTab));        
        if (this.questionForm.valid) {
            if (this.questionModel.id) {
                var updateMsgId = this.messageService.loading("Updating...", {nzDuration:0}).messageId;
                this.questionService.updateQuestion(this.questionModel).subscribe( res => {
                    this.messageService.remove(updateMsgId);
                    this.modalService.closeAll();
                    this.questions();
                    this.disableSubmitButton = false;
                },
              err => {
                  this.disableSubmitButton = false;
              }
              )
            
        } else {
            var addMsgId = this.messageService.loading("Saving...", {nzDuration:0}).messageId;
            this.questionService.addQuestion(this.questionModel).subscribe( res => {
                    this.messageService.remove(addMsgId);
                    this.modalService.closeAll();
                    this.questions();
                    this.disableSubmitButton = false;
                },
                err => {
                    this.disableSubmitButton = false;
                }
                ) 
        }
    }
}

    /**
     * Hide the modal form
     */
    handleCancel(){
        this.isVisible = false;
    }

}
