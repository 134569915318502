import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PushMessageModel } from '../models/pushMessage-model';
import { HttpRequestService } from './http-request.service';

@Injectable({
  providedIn: 'root'
})
export class PushMessageService {

  private stateUrl = 'api/v1/admin/push-message/';

  constructor(private httpRequest: HttpRequestService) { }

  getUserCount(pushMessageModel : PushMessageModel) : Observable<Response> {
    return this.httpRequest.post(this.stateUrl, pushMessageModel);
  }

  sendNotification(formData : FormData) : Observable<Response> {
    return this.httpRequest.filePost(this.stateUrl + 'notification', formData);
  }

  getNotification(body : any) : Observable<any> {
    return this.httpRequest.post(this.stateUrl + 'all', body);
  }
}
