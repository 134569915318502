import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user-model';
import { AdminUserModel } from '../models/admin-user-model';

@Injectable({
  providedIn: 'root'
})
export class UsersPromoService {

  private usersPromoUrl = 'api/v1/admin/promo';

  constructor(private httpRequest: HttpRequestService) { }

  /**
   * This service is responsible for getting all users. 
   * @param body 
   */
  getAllUsers(body:any): Observable<any>{
    return this.httpRequest.post(this.usersPromoUrl+'/users-list',body);
  }

}
