import { Component, OnInit } from '@angular/core';
import { DataExportService } from '../shared/services/data-export.service';
import { DataExportModel, DataExportFormBuilder } from '../shared/models/data-export.model';
import { FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-data-export-utility',
  templateUrl: './data-export-utility.component.html',
  styleUrls: ['./data-export-utility.component.css']
})
export class DataExportUtilityComponent implements OnInit {

  constructor(private dataExportService:DataExportService,
    private msgService: NzMessageService
    ) { }
  allParticipants:any = [];
  dataExport: DataExportFormBuilder = new DataExportFormBuilder();
  dataExportModel: DataExportModel =  this.dataExport.dataExportModel;
  dataExportForm: FormGroup = this.dataExport.dataExportForm;
  processSubmitForm: Boolean = false;

  
  ngOnInit() {
    this.fetchParticipants();
    this.processSubmitForm = true;
  }

  /**
   *Fetches all the participants
   */
  fetchParticipants(){
    this.dataExportService.participantsList().subscribe((res) => {
      this.allParticipants = res.body;
    });
  }

  /**
   * Download exported data
   */
  downloadData(){
    this.processSubmitForm=true;

    // this.dataExportService.exportData(this.dataExportModel).subscribe((res) => {
      
    // },
    //   err => {
    //       this.processSubmitForm = false;
    //     }
    // );
    var saveMsgId = this.msgService.loading("Downloading...", {nzDuration:0}).messageId;            
    
    this.dataExportService.exportData(this.dataExportModel).subscribe((res: any) => {
      this.downloadFile(res);
      this.msgService.remove(saveMsgId); 
      this.processSubmitForm = false;
    },(err)=>{
      this.processSubmitForm = false;
    });
  }


  downloadFile(data: any) {
    const blob = new Blob( [data] , { type: 'application/zip' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  disableSubmitButton() {
    if(this.dataExportModel.dateRange != null && this.dataExportModel.dateRange.length != 0) {
      this.processSubmitForm = false;
    } else {
      this.processSubmitForm = true;
    }
  }
   
}
