import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { AccountModel } from '../models/account-model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private accountUrl = 'api/v1/admin/accounts';

  constructor(private httpRequest: HttpRequestService) { }

  /**
   * This service is responsible for getting all accounts. 
   * @param body 
   */
  getAllAccounts(body:any): Observable<any>{
    return this.httpRequest.post(this.accountUrl+'/list',body);
  }

  /**
   * This service is responsible for creating account
   * @param accountModel 
   */
  createAccount(accountModel: AccountModel): Observable<any>{
    return this.httpRequest.post(this.accountUrl,accountModel);
  }

  /**
   * This service is responsible for updating account
   * @param accountModel 
   */
  updateAccount(accountModel: AccountModel): Observable<any>{
    return this.httpRequest.put(this.accountUrl,accountModel);
  }
}
