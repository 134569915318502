import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { DatePipe } from '@angular/common';
import { accountFormBuilder, AccountModel } from 'src/app/shared/models/account-model';
import { FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  
  account: accountFormBuilder = new accountFormBuilder();
  accountModel: AccountModel = this.account.accountModel;
  accountCreateForm: FormGroup = this.account.accountCreateForm;
  accountUpdateForm: FormGroup = this.account.accountUpdateForm;
  accountList: any = [];
  search: any
  loader: boolean;
  pageIndex = 1;
  pageSize = 10;
  sortBy: string = "name";
  sortDirection = "ASC";
  updateAccountPopup: boolean = false;
  createAccountPopup : boolean = false;
  disableSubmitButton: boolean;
  modalTitleAccount: string;
  total = 100;
  disableCancelButton : boolean;
  searchObj = {
    "name": "",
    "email": "",
    "isEmailVerified": "",
    "enabled": "",
    "role": "",
    "signup": "",
    "lastSignin": ""
  };

  ngOnInit() {
    this.accounts(true);
  }

  constructor(private accountService: AccountService, private datePipe: DatePipe, private messageService: NzMessageService) {

 }

  /**
   * This method is responsible for fetching accounts
   */
  accounts(reset: boolean = false) {

    if (reset) {
      this.pageIndex = 1;
    }
    let searchRequest = { ...this.searchObj };

    if (searchRequest['signup'][0]) {
      searchRequest["signupMinDate"] = this.datePipe.transform(searchRequest['signup'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["signupMaxDate"] = this.datePipe.transform(searchRequest['signup'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['signup'] = "";
    }
    if (searchRequest['lastSignin'][0]) {
      searchRequest["lastSigninMinDate"] = this.datePipe.transform(searchRequest['lastSignin'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["lastSigninMaxDate"] = this.datePipe.transform(searchRequest['lastSignin'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['lastSignin'] = "";
    }



    let request = {
      "pageNumber": this.pageIndex,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "search": this.search,
      "parameters": searchRequest
    }

    this.loader = true;
    this.accountService.getAllAccounts(request).subscribe((res) => {
      this.loader = false;
      this.accountList = res.body.data;
      this.total = res.body.totalCount;
    },
      (res) => {
        this.loader = false;
      });
  }

  /**
   * Responsible for searching on table
   */
  searchInData(column: any) {
    this.accounts(true)
  }

  /**
   * Responsible for sorting support.
   */
  sort(sort: { key: string; value: string }) {
    this.sortBy = sort.key;
    this.sortDirection = sort.value == 'ascend' ? 'ASC' : 'DESC';
    let request = {
      "pageNumber": this.pageIndex,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "search": this.search
    }
    this.accounts(false);
  }

  reset(column) {
    //this.search = null;
    this.searchObj[column] = "";
    this.accounts(true);
  }

  
  /**
   * Close the model popup form.
   */
  handleCancel() {
    this.createAccountPopup = false;
    this.updateAccountPopup = false;
  }


  /**
   * Create the account.
   */
  submitAccountCreateForm(){
    var createMsgId = this.messageService.loading("Creating...").messageId;
    this.disableSubmitButton = true;
    this.disableCancelButton = true;
    this.accountService.createAccount(this.accountModel).subscribe(res => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
      this.messageService.remove(createMsgId);
      this.createAccountPopup = false;
      this.accounts(true);
    },
      err => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
    }
    )       
  }
  

  /**
   * Update the existing account.
   */
  submitAccountUpdateForm(){
    this.disableSubmitButton = true;
    this.disableCancelButton = true;
    var updateMsgId = this.messageService.loading("Updating...").messageId;
    this.accountService.updateAccount(this.accountModel).subscribe(res => {
      this.messageService.remove(updateMsgId);
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
      this.updateAccountPopup = false;
        this.accounts(true);
    },
    err => {
      this.disableSubmitButton = false;
      this.disableCancelButton = false;
    }
  )
  }

    /**
     * Popup the modal
     * @param data 
     */
   makeModelVisible(data,createAccountForm:boolean){
    
    if (createAccountForm) {
      this.accountCreateForm.reset();
      this.createAccountPopup = true  
      this.account.setAccountCreateFormModelVals(data);
    } else {
      this.updateAccountPopup = true;
      this.account.setAccountUpdateFormModelVals(data);
    }
}
}