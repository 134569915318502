import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { DefaultRegulationModel } from '../models/defaultRegulation-model';

@Injectable({
  providedIn: 'root'
})
export class DefaultRegulationService {

  private stateUrl = 'api/v1/admin/default-regulations';

  constructor(private httpRequest: HttpRequestService) { }

  saveRegulation(defaultRegulationModel:DefaultRegulationModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl, defaultRegulationModel);
  }

  getAllRegulations(body: any): Observable<any> {
    return this.httpRequest.post(this.stateUrl+"/list", body);
  }

  updateRegulation(defaultRegulationModel:DefaultRegulationModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl +'/' +defaultRegulationModel.id,defaultRegulationModel);
  }

  deleteRegulation(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl +'/'+id)
  }

  duplicateRegulation(defaultRegulationModel:DefaultRegulationModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl + '/duplicate/'+defaultRegulationModel.id, defaultRegulationModel);
  }
}