import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  private tripsUrl = 'api/v1/admin/trips-data';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  getAllTrips(body : any): Observable<any> {
    return this.httpRequest.post(this.tripsUrl, body);
  }

  tripDetailsById(id: String): Observable<Response> {
    return this.httpRequest.get(this.tripsUrl + '/' + id)
  }
}
