import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PushMessageConditionModel } from "./pushMessage-condition-model";

export interface PushMessageModel {
    id : string;
    title : string;
    text : string;
    imageUrl : string;
    userCount : string;
    conditions : PushMessageConditionModel[];
  }

export class PushMessageFormBuilder {

    pushMessageModel : PushMessageModel = {
        id : null,
        title : null,
        text : null,
        imageUrl : null,
        userCount : null,
        conditions : []
    }

    pushMessageForm: FormGroup = new FormGroup({
        id : new FormControl(this.pushMessageModel.id),
        title: new FormControl(this.pushMessageModel.title, [Validators.required]),
        text: new FormControl(this.pushMessageModel.text, [Validators.required]),
        imageUrl: new FormControl(this.pushMessageModel.imageUrl),
        userCount: new FormControl(this.pushMessageModel.userCount),
        conditions: new FormControl(this.pushMessageModel.conditions)
      });

      constructor() {
        this.pushMessageForm.valueChanges.subscribe(val => {
          this.pushMessageModel.id = val.id;
          this.pushMessageModel.title = val.title;
          this.pushMessageModel.text = val.text;
          this.pushMessageModel.imageUrl = val.imageUrl;
          this.pushMessageModel.userCount = val.userCount;
          this.pushMessageModel.conditions = val.conditions;
        });
      }

      setModelVals(res: any) {
        this.pushMessageForm.controls.id.setValue(res.id);
        this.pushMessageForm.controls.title.setValue(res.title);
        this.pushMessageForm.controls.text.setValue(res.text);
        this.pushMessageForm.controls.imageUrl.setValue(res.imageUrl);
        this.pushMessageForm.controls.userCount.setValue(res.userCount);
        this.pushMessageForm.controls.conditions.setValue(res.conditions);
      }
}