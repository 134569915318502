import { Component, OnInit } from '@angular/core';
import { MeasureService } from 'src/app/shared/services/measure.service';
import { MeasureModelFormBuilder, MeasureModel } from '../../../shared/models/measure-model';
import { FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd';
import { TableService } from 'src/app/shared/services/table.service';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.css']
})

export class MeasuresComponent implements OnInit {
  displayData: any;
  MeasureList: any;
  isVisible: boolean;
  formHeaderText: String;
  submitButton: boolean;
  measureModel: MeasureModel;
  measure: MeasureModelFormBuilder = new MeasureModelFormBuilder();
  sortAttribute = {key: 'name', value: 'ascend'};
  measureLoading = false;
  //measureModel: MeasureModel = this.measure.measureModel;
  //measureForm: FormGroup = this.measure.measureForm;
  
  constructor(private measureService: MeasureService,
    private messageService: NzMessageService,
    private modalService: NzModalService,
    private tableService: TableService,) {
    this.displayData = this.MeasureList;
    this.measureModel = new MeasureModel();
  }

  ngOnInit() {
    this.fetchMeasureList();
  }

  /**
   * Get list of Measures 
   */ 
  fetchMeasureList() {
    //const loadingData = this.messageService.loading('Loading ...').messageId;
    this.measureLoading = true;
    this.measureService.measureList().subscribe((res) => {
      //this.messageService.remove(loadingData);
      this.measureLoading = false;
      this.MeasureList = res;
      this.displayData =  this.MeasureList;
    });
  }

  /**
  * Open model window for add and update
  * @param measureData 
  */
  openMeasureForm(measureData: any) {
    this.submitButton = true;
    this.isVisible = true;
    if (measureData != null) {
      this.formHeaderText = 'Update Measure';
      const measureDataClone = Object.assign({}, measureData);
      this.measureModel = measureDataClone;
      this.submitButton = false;
    } else {
      this.formHeaderText = 'Create New Measure';
      this.measureModel = new MeasureModel(); 
      this.submitButton = true;
    }
  }

 /**
  * Disable submit button
  */
  OnSubmitButton() { 
    if(this.measureModel.name != null && this.measureModel.abv != null && this.measureModel.name != "" && this.measureModel.abv != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
   * Model submit button event
   */
  onSubmit() {
    if (this.measureModel.id != null) {
      var updateMsgId = this.messageService.loading("Updating...").messageId;
      this.measureService.updateMeasure(this.measureModel).subscribe((res) => {
        this.messageService.remove(updateMsgId);
        this.modalService.closeAll();
        this.fetchMeasureList();
        //this.updateMeasureInList(this.measureModel);
      });

    } else {
      this.submitButton = true;
      const loadingData = this.messageService.loading('Saving ...').messageId;
      this.measureService.saveMeasure(this.measureModel).subscribe((res) => {
        this.messageService.remove(loadingData);
        this.modalService.closeAll();
        this.fetchMeasureList();
      });

    }
  }

  /**
   * Delete measure 
   * @param id 
   */
  deleteMeasure(id) {
    var deleteMsgId = this.messageService.loading("Deleting...").messageId;
    this.measureService.deleteMeasure(id).subscribe((res) => {
      this.messageService.remove(deleteMsgId); 
      this.removeMeasureFromList(id);
    });
  }

  /**
   * Open model to confirm delete
   * @param id 
   */
  deleteMeasureForm(id): void {
    this.modalService.confirm({
      nzTitle: 'Delete this Measure ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteMeasure(id),
      nzCancelText: 'No'
    });
  }

  /**
   * Remove measure from list
   * @param id
   */
  removeMeasureFromList(id) {
    this.MeasureList.forEach((measure, index) => {
      if(measure.id === id) {
          this.MeasureList.splice(index,1);
          return false;
      }
    });
    this.displayData = this.MeasureList;
  }


   /**
   * Update in measure from list
   * @param id
   */
  updateMeasureInList(measureModel) {
    this.MeasureList.forEach((measure, index) => {
      if(measure.id === measureModel.id) {
        measure = measureModel
          return false;
      }
    });
    this.displayData = this.tableService.sort(this.sortAttribute, this.MeasureList);
  }

  /**
   * Cancel button event
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Sort List
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableService.sort(sortAttribute, this.MeasureList);
  }
 
}
