import { FormGroup, FormControl, Validators } from "@angular/forms";

export class FishingMethodModel {

    id: string;
    name: string;
}

export class FishingMethodModelFormBuilder {
    fishingMethodModel: FishingMethodModel = {
        id: null,
        name: null
    }

    fishingMethodForm: FormGroup = new FormGroup({
        id: new FormControl(this.fishingMethodModel.id),
        name: new FormControl(this.fishingMethodModel.name, [Validators.required])
    });

    constructor() {
        this.fishingMethodForm.valueChanges.subscribe(val => {
            this.fishingMethodModel.id = val.id;
            this.fishingMethodModel.name = val.name;
        });
    }

    setModelVals(res: any) {
            this.fishingMethodForm.controls.id.setValue(res.id),
            this.fishingMethodForm.controls.name.setValue(res.name);
    }
}


