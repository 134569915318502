import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface AdminUserModel {
    id:string,
    name: string,
    email: string,
    role: string,
    enabled: boolean,
    password:string,
    isTestUser: boolean,
    promoCode: string
}

export class adminUserFormBuilder {

    adminUserModel: AdminUserModel = {
        id:null,
        name: null,
        email: null,
        role: null,
        enabled: false,
        password:null,
        isTestUser: false,
        promoCode: null
    };

    adminUserCreateForm: FormGroup = new FormGroup({
        id: new FormControl(this.adminUserModel.id),
        name: new FormControl(this.adminUserModel.name,[Validators.required]),
        email: new FormControl(this.adminUserModel.email,[Validators.required]),
        role: new FormControl(this.adminUserModel.role,[Validators.required]),
        password: new FormControl(this.adminUserModel.password,[Validators.required])
    });

    adminUserUpdateForm: FormGroup = new FormGroup({
        id: new FormControl(this.adminUserModel.id),
        name: new FormControl(this.adminUserModel.name,[Validators.required]),
        email: new FormControl(this.adminUserModel.email,[Validators.required]),
        role: new FormControl(this.adminUserModel.role,[Validators.required]),
        enabled:  new FormControl(this.adminUserModel.enabled,[Validators.required]),
        isTestUser: new FormControl(this.adminUserModel.isTestUser,[Validators.required]),
        promoCode: new FormControl(this.adminUserModel.promoCode)
    });

    constructor() {
        this.adminUserCreateForm.valueChanges.subscribe(val => {
            this.adminUserModel.id = val.id;
            this.adminUserModel.name = val.name;
            this.adminUserModel.email = val.email;
            this.adminUserModel.role = val.role;
            this.adminUserModel.password = val.password
        })
        this.adminUserUpdateForm.valueChanges.subscribe(val => {
            this.adminUserModel.id = val.id;
            this.adminUserModel.name = val.name;
            this.adminUserModel.email = val.email;
            this.adminUserModel.role = val.role;
            this.adminUserModel.enabled = val.enabled;
            this.adminUserModel.isTestUser = val.isTestUser;
            this.adminUserModel.promoCode = val.promoCode;
        })
    }

    setadminUserCreateFormModelVals(res: any) {
        this.adminUserCreateForm.controls.name.setValue(res != null ? res.name : null);
        this.adminUserCreateForm.controls.email.setValue(res != null ? res.name : null);
        this.adminUserCreateForm.controls.role.setValue(res != null ? res.role : null);
        this.adminUserCreateForm.controls.password.setValue(res != null ? res.password : null);
    }

    setadminUserUpdateFormModelVals(res: any) {
        this.adminUserUpdateForm.controls.id.setValue(res != null ? res.id : null);
        this.adminUserUpdateForm.controls.name.setValue(res != null ? res.name : null);
        this.adminUserUpdateForm.controls.role.setValue(res != null ? res.role : null);
        this.adminUserUpdateForm.controls.email.setValue(res != null ? res.email : null);
        this.adminUserUpdateForm.controls.enabled.setValue(res != null ? res.enabled : null);
        this.adminUserUpdateForm.controls.isTestUser.setValue(res != null ? res.isTestUser : null);
        this.adminUserUpdateForm.controls.promoCode.setValue(res != null ? res.promoCode : null);
    }
}