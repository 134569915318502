import { Directive, ElementRef, Input, OnInit, HostListener, EventEmitter, OnChanges } from "@angular/core";

@Directive({
    selector: '[appDisableAfterClick]'
})

export class DisableButtonOnRequest {

  // requestState:boolean = false;

  // ngOnChanges() {
  //   console.log("In directive"+this.requestState);
  //   if(this.requestState){
  //     this.el.nativeElement.disabled = true; 
  //   } 
  // }

  // constructor(
  //   private el: ElementRef,private dataService:DataService
  // ) {
  //   this.el.nativeElement.disabled = true; 
  //   this.dataService.currentMessage.subscribe(message => this.requestState = message)
  //   if(this.requestState){
  //     this.el.nativeElement.disabled = true; 
  //   }
  // }


  // @HostListener('click')
  // onClick() {
  //   this.el.nativeElement.disabled = true;    
  // }

  // ngOnInit() {
  //   this.el.nativeElement.disabled = true; 
  //     this.dataService.currentMessage.subscribe(message => this.requestState = message)
  //     if(this.requestState){
  //       this.el.nativeElement.disabled = true; 
  //     }
      
  // }

  

  // ngOnDestroy() {
  //   this.subscription && this.subscription.unsubscribe();
  // }

}