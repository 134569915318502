import { OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { RegulationModel, RegulationModelFormBuilder } from 'src/app/shared/models/regulation-model';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { RegulationService } from 'src/app/shared/services/regulation.service';
import { Component} from '@angular/core';
import { TableService } from 'src/app/shared/services/table.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { SpeciesModel, SpeciesFormBuilder } from 'src/app/shared/models/species.model';
import { StateService } from 'src/app/shared/services/state.service';
import { StateFormBuilder, StateModel } from 'src/app/shared/models/state-model';
import { format } from "date-fns";
import { FormGroup } from '@angular/forms';
import { RegionFormBuilder, RegionModel } from 'src/app/shared/models/region-model';
import { DatePipe, formatDate } from '@angular/common';
import { AIRegulationService } from 'src/app/shared/services/ai-regulation.service';

@Component({
  selector: 'app-ai-regulation',
  templateUrl: './ai-regulation.component.html',
  styleUrls: ['./ai-regulation.component.css']
})
export class AIRegulationComponent implements OnInit {

  regulationList:RegulationModel[];
  regionFilterList: any = [];
  //speciesFilterList: any = [];
  speciesFilterList: [];
  lessonsCounter: any
  currentSelectedRegulation: any = null;
  searchAddress: any
  disableAllFormControls = true;
  filteredRegionListForSearch:any = this.regionFilterList;
  filterDataOfRegion = () => true;
  isVisible : boolean;
  isInputSourceVisible : boolean;
  formHeaderText : string;
  species : any = [];
  regions : any = [];
  regulationId : any;
  submitButton : boolean;
  seasons: any = [];
  quickEditSubmitButton = true;
  pageIndex = 1;
  pageSize = 10;
  total = 1;;
  loading = false;
  sortBy = "name";
  sortDirection = "ASC";
  search : any;
  selectedRegions: any = [];
  selectedSpecies: any = [];
  regulationsLoading = false;
  regulation: RegulationModelFormBuilder = new RegulationModelFormBuilder();
  regulationModel: RegulationModel = this.regulation.regulationModel;
  regulationForm: FormGroup = this.regulation.regulationForm;

  dateFilterList: any =  [];
  selectedDates:any  = [];
  region: RegionFormBuilder = new RegionFormBuilder();
  regionObject : RegionModel = this.region.regionModel;

  specie : SpeciesFormBuilder = new SpeciesFormBuilder();
  speciesObject : SpeciesModel = this.specie.speciesModel;

  state : StateFormBuilder = new StateFormBuilder();
  stateObject : StateModel = this.state.stateModel;

  updatedAt : StateFormBuilder = new StateFormBuilder();
  updatedAtObject : StateModel = this.state.stateModel;

  selectedStates: any = [];
  stateFilterList: any = [];
  selectedWatchlist: any;
  selectedUpdatedStartDate: any;
  selectedUpdatedEndDate: any;
  changeSummaryRes: any = [];
  isMouseHoverLoaded: boolean = false;
  filterObj: any;

  selectedRegionsTemp: any = [];
  selectedSpeciesTemp: any = [];
  selectedStatesTemp: any = [];
  selectedDatesTemp: any = [];
  inputSourceList: any = [];
  inputLinkValue: any = '';

  searchObj = {
    "lastUpdatedTime" : ""
  }

  // season: SeasonFormBuilder = new SeasonFormBuilder();
 // seasonModel: SeasonModel = this.season.seasonModel;
 // seasonForm: FormGroup = this.season.seasonForm;

 constructor(
  private msgService: NzMessageService,
  private aiRegulationService: AIRegulationService,
  private modalService: NzModalService,
  private router: Router,
  private route: ActivatedRoute,
  private tableSvc: TableService,
  private speciesService: SpeciesService,
  private regionService: RegionService,
  private utilityService : UtilityService,
  private datePipe: DatePipe,
  private stateService : StateService,
  private messageService: NzMessageService,
  ) { 
    this.route.queryParams.subscribe(params => {

      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
        if (Object.keys(this.router.getCurrentNavigation().extras.state.species).length > 0) {
          this.selectedSpeciesTemp = this.router.getCurrentNavigation().extras.state.species.id;
          this.selectedSpecies = this.selectedSpeciesTemp;
        }
        if (Object.keys(this.router.getCurrentNavigation().extras.state.region).length > 0) {
          this.selectedRegionsTemp = this.router.getCurrentNavigation().extras.state.region.id;
          this.selectedRegions = this.selectedRegionsTemp;
        }
        if (Object.keys(this.router.getCurrentNavigation().extras.state.state).length > 0) {
          this.selectedStatesTemp = this.router.getCurrentNavigation().extras.state.state.id;
          this.selectedStates = this.selectedStatesTemp;
        }
        if (this.router.getCurrentNavigation().extras.state.pageIndex) {
          this.pageIndex = this.router.getCurrentNavigation().extras.state.pageIndex;
        }
        if(this.router.getCurrentNavigation().extras.state.selectedDates){
          this.selectedDatesTemp = this.router.getCurrentNavigation().extras.state.selectedDates;
          this.selectedDates = this.selectedDatesTemp;
        }
      }
    });
  }

  ngOnInit() {
    this.getRegions();
    this.getFilterSpeciesList();
    this.fetchAllRegulations();
    this.getFilterStateList();
  }

  searchInData11(column: any) {
    this.fetchAllRegulations();
  }

  reset(column) {
    this.searchObj[column] = "";
    this.fetchAllRegulations();
  }

  resetWatchlistSearch(column) {
    this.selectedWatchlist = null;
    this.fetchAllRegulations();
  }

  collapseExpand(data) {
    if (data.type != 'PROHIBITED' && data.type != 'CUSTOM' && data.type != 'DEFAULT') {
      data.expand = data.expand ? false : true;
      //this.QuickEditUpdateButton(data.totalSeasons);
    }
  }

  fetchAllRegulations(reset: boolean = false) {

    this.msgService.remove();
    this.pageIndex = reset ? 1 : this.pageIndex;


    let searchRequest = { ...this.searchObj };
    if (searchRequest['lastUpdatedTime'][0]) {
      this.selectedUpdatedStartDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][0], 'yyyy-MM-dd');
      this.selectedUpdatedEndDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][1], 'yyyy-MM-dd');
    }

    this.selectedUpdatedStartDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][0], 'yyyy-MM-dd');
    this.selectedUpdatedEndDate = this.datePipe.transform(searchRequest['lastUpdatedTime'][1], 'yyyy-MM-dd');

    let filteredRegions = this.selectedRegions ? this.selectedRegions.join() : null;
    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null;
    let filteredStates = this.selectedStates ? this.selectedStates.join() : null;
    let filteredWatchList = this.selectedWatchlist ? this.selectedWatchlist : null;
    let filteredUpdatedStartDate = null;
    let filteredUpdatedEndDate = null;
    let filteredDateOperation = "in";

    if(this.selectedUpdatedStartDate != null && this.selectedUpdatedEndDate != null ){
      filteredUpdatedStartDate =  this.selectedUpdatedStartDate; 
      filteredUpdatedEndDate = this.selectedUpdatedEndDate;
    } 
    else if(this.selectedDates && this.selectedDates.length > 0){     
      let filteredDate = this.dateFilterList.filter(f => f.selectedDate === this.selectedDates[0])[0];

      filteredUpdatedStartDate = formatDate(new Date(new Date().setMonth(new Date().getMonth() - filteredDate.selectedDateValue)), 'yyyy-MM-dd', 'en');
      filteredUpdatedEndDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      if(filteredDate.selectedDate === "Last 3 months" || filteredDate.selectedDate === "Last 6 months"
        || filteredDate.selectedDate === "Last 12 months" || filteredDate.selectedDate === "Last 24 months"
        || filteredDate.selectedDate === "Last 36 months"){
            filteredDateOperation = "in";
      }
      else if(filteredDate.selectedDate === "Before 3 months" || filteredDate.selectedDate === "Before 6 months"
        || filteredDate.selectedDate === "Before 12 months" || filteredDate.selectedDate === "Before 24 months"
        || filteredDate.selectedDate === "Before 36 months"){        
            filteredDateOperation = "not in";
      }
    }

    let request = {
      "pageNumber": this.pageIndex,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      "boundaries": filteredRegions,
      "species": filteredSpecies,
      "states": filteredStates,
      "lastUpdatedStartDate": filteredUpdatedStartDate,
      "lastUpdatedEndDate": filteredUpdatedEndDate,
      "watchList": filteredWatchList,
      "dateOperation": filteredDateOperation
    }
    this.regulationsLoading = true;
    this.aiRegulationService.getAllRegulations(request).subscribe((res) => {
      this.regulationsLoading = false;
      if (res.body) {
        this.total = res.body.totalCount;
        this.setSeasonDateRange(res.body.data);
        this.regulationList = res.body.data;
      } else {
        this.total = 0
        this.regulationList = [];
      }
    },
      (err) => {
        this.regulationsLoading = false;
      }
    );
  }


  sort(sortAttribute: any) {
    this.sortBy = null;
    this.sortDirection = null;
    if (sortAttribute.value) {
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC" : "");
    }
    this.fetchAllRegulations(false);
  }

  searchInData() {
    this.regulationList = this.tableSvc.search(this.search, this.regulationList);
  }

  action(action: string) {

    switch (action) {
      case 'edit':
        this.goToAddEditAIRegulationPage(this.currentSelectedRegulation);
        break;
      case 'delete':
        this.showDeleteConfirm(this.currentSelectedRegulation.id);
        console.log("delete => " + this.currentSelectedRegulation.id);
        break;
      default:
        break;
    }

  }

  goToAddEditAIRegulationPage(regulation) {
    this.regionObject.id = this.selectedRegions,
    this.speciesObject.id = this.selectedSpecies;
    this.stateObject.id = this.selectedStates;

    let navigationExtras: NavigationExtras = {
      state: {
        regulation: regulation,
        selectedRegions: this.regionObject,
        selectedSpecies: this.speciesObject,
        selectedStates: this.stateObject,
        pageIndex: this.pageIndex,
        selectedDates: this.selectedDates
      }
    };
    this.router.navigate(['/ai-regulations', regulation.id], navigationExtras);
  }


  /**
   * Show delete confirm box 
   * @param id 
   */
  showDeleteConfirm(id): void {
    this.modalService.confirm({
      nzTitle: 'Delete this regulation ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteRegulationById(id),
      nzCancelText: 'No'
    });
  }


  /**
   * Delete the regulation by id
   * @param id 
   */
  deleteRegulationById(id) {
    var msgId = this.msgService.loading("Deleting...", { nzDuration: 0 }).messageId;
    this.aiRegulationService.deleteById(id).subscribe((res) => {
      this.msgService.remove(msgId);
      this.fetchAllRegulations();
    });
  }

  currentSelectedData(data: any) {
    console.log("Regulation => " + data.id);
    this.currentSelectedRegulation = data;
  }

  async getFilterSpeciesList() {
    this.speciesFilterList = await this.speciesService.getAllLiteSpecies();
  }

  /**
   * To get list of regions for combobox dropdown
   */
  getRegions() {
    //this.loading = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionFilterList = res.body;
      this.filteredRegionListForSearch = this.regionFilterList;
      //this.loading = false;
    });
  }

  /**
   * This method is responsible for searching on region list.
   * @param search 
   */
  searchInRegionList(search: string) {
    this.filteredRegionListForSearch = this.utilityService.searchInRegionsWithCountryAndState(search, this.regionFilterList);
  }

  /**
   * This method is Responsible for Open Model window for duplicate Regulation
   * @param id 
   */
  openDuplicateForm(id) {
    this.isVisible = true;
    this.submitButton = true;
    this.formHeaderText = "Duplicate Regulation"
    this.regulationId = id;
  }

  /**
   * This method is responsible for close model window for Duplicate Regulation
   */
  onCancel() {
    this.isVisible = false;
    this.isInputSourceVisible = false;
  }

  /**
   * Disable - Enable Model Submit Button
   */
  OnSubmitButton() {
    if (this.species != null && this.regions != null && this.species != "" && this.regions != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
 * Set Date Range of Seasons
 * @param data 
 */
  setSeasonDateRange(data) {

    data.forEach(regu => {
      if (regu.totalSeasons) {

        this.updateSeasonIndexes(regu);
        regu.totalSeasons.forEach(s => {
          s.startDate = new Date(s.startDateStr);
          s.endDate = new Date(s.endDateStr);

          const browser = this.getBrowserName();
          console.log("Console => " + browser);
          if (browser === 'safari') {

            // For safari
            s.startDate = this.datePipe.transform(s.startDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
            s.endDate = this.datePipe.transform(s.endDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
          }
          else {
            // For other browser
            s.startDate = this.datePipe.transform(s.startDateStr, 'yyyy-MM-ddTHH:mm:ss');
            s.endDate = this.datePipe.transform(s.endDateStr, 'yyyy-MM-ddTHH:mm:ss');
          }

          s.dateRange = [s.startDate, s.endDate];

          s.restrictions.minSizeUnit = s.restrictions.minSize ? this.getUnit(s.restrictions.minSize) : '\u0022';
          s.restrictions.maxSizeUnit = s.restrictions.maxSize ? this.getUnit(s.restrictions.maxSize) : '\u0022';
          s.restrictions.trophyUnit = s.restrictions.trophySize ? this.getUnit(s.restrictions.trophySize) : '\u0022';
          s.restrictions.aggregateLimit = this.replaceNoneWithZero(s.restrictions.aggregateLimit);
          s.restrictions.bagLimit = this.replaceNoneWithZero(s.restrictions.bagLimit);
          s.restrictions.vesselLimit = this.replaceNoneWithZero(s.restrictions.vesselLimit);
          s.restrictions.trophyLimit = this.replaceNoneWithZero(s.restrictions.trophyLimit);
          s.restrictions.trophySize = s.restrictions.trophySize ? this.replaceNoneWithZero(s.restrictions.trophySize) : s.restrictions.trophySize;
          s.restrictions.minSize = s.restrictions.minSize ? this.replaceNoneWithZero(s.restrictions.minSize) : s.restrictions.minSize;
          s.restrictions.maxSize = s.restrictions.maxSize ? this.replaceNoneWithZero(s.restrictions.maxSize) : s.restrictions.maxSize;
        });
      }

      if (regu.defaultSeason) {
        var s = regu.defaultSeason;
        s.restrictions.minSizeUnit = s.restrictions.minSize ? this.getUnit(s.restrictions.minSize) : '\u0022';
        s.restrictions.maxSizeUnit = s.restrictions.maxSize ? this.getUnit(s.restrictions.maxSize) : '\u0022';
        s.restrictions.trophyUnit = s.restrictions.trophySize ? this.getUnit(s.restrictions.trophySize) : '\u0022';
        s.restrictions.aggregateLimit = this.replaceNoneWithZero(s.restrictions.aggregateLimit);
        s.restrictions.bagLimit = this.replaceNoneWithZero(s.restrictions.bagLimit);
        s.restrictions.trophyLimit = this.replaceNoneWithZero(s.restrictions.trophyLimit);
        s.restrictions.trophySize = s.restrictions.trophySize ? this.replaceNoneWithZero(s.restrictions.trophySize) : s.restrictions.trophySize;
        s.restrictions.minSize = s.restrictions.minSize ? this.replaceNoneWithZero(s.restrictions.minSize) : s.restrictions.minSize;
        s.restrictions.maxSize = s.restrictions.maxSize ? this.replaceNoneWithZero(s.restrictions.maxSize) : s.restrictions.maxSize;
      }
    });
  }
  /**
   * Replace value Zero to NULL and get value without Unit
   * @param value 
   */
  replaceNoneWithZero(value) {
    if (value) {
      if (value == "None")
        value = null;
      else if (value == "None'")
        value = null;
      else if (value.includes("\'"))
        value = value.replace("\'", " ");
      else if (value.includes("lbs"))
        value = value.replace(" lbs", " ");
      else if (value.includes("\""))
        value = value.replace("\"", " ");
      else if (value.includes("mm"))
        value = value.replace(" mm", " ");
      else if (value.includes("cm"))
        value = value.replace(" cm", " ");
      else if (value.includes("m"))
        value = value.replace(" m", " ");
      else if (value.includes("gal"))
        value = value.replace(" gal", " ");
      else if (value.includes("ltr"))
        value = value.replace(" ltr", " ");
    }
    return value;
  }

  /**
   * This method is used to get Unit from Size Value
   * 
   * @param size 
   */
  getUnit(size) {
    var unit;
    if (size.includes("'")) {
      unit = '\u0027';
    } else if (size.includes("lbs")) {
      unit = 'lbs';
    } else if (size.includes("\"")) {
      unit = '\u0022';
    } else if (size.includes("mm")) {
      unit = 'mm';
    } else if (size.includes("cm")) {
      unit = 'cm';
    } else if (size.includes("m")) {
      unit = 'm';
    } else if (size.includes("gal")) {
      unit = 'gal';
    } else if (size.includes("ltr")) {
      unit = 'ltr';
    } else
      unit = '\u0022';
    return unit;
  }

  /**
   * This method is used to set index in seasons
   * 
   * @param regulation 
   */
  updateSeasonIndexes(regulation) {
    var seasons = regulation.totalSeasons;
    seasons.forEach(function (ss, index) {
      ss.index = index;
    });
  };

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  /**
   * This method is used to get All states 
   */
  async getFilterStateList() {
    this.stateFilterList = await this.stateService.getAllLiteStates();
  }

  onChange1(result: Date): void {
    this.selectedUpdatedStartDate = formatDate(new Date(result[0]), 'yyyy-MM-dd', 'en_US');
    this.selectedUpdatedEndDate = formatDate(new Date(result[1]), 'yyyy-MM-dd', 'en_US');
  }

    createDraftRegulation() {
     // this.isInputSourceVisible = true;

      var createMsgId = this.messageService.loading("Creating draft regulations, please wait...").messageId;
      this.aiRegulationService.createDraftRegulation().subscribe(res => {
        this.messageService.remove(createMsgId);
        this.fetchAllRegulations(true);
        },
          err => {
          
        }
      )   
    }

    addInputSourceToList(){
      if(this.inputLinkValue.length > 0 || this.inputLinkValue !==''){
        this.inputSourceList.push(this.inputLinkValue);
        this.inputLinkValue = '';
      }
    }

    removeLink(link1){
      this.inputSourceList = this.inputSourceList.filter(link => link != link1);
    }

  
}

