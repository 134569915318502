import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/shared/services/table.service';
import { TripsService } from 'src/app/shared/services/trips.service';
import { NzMessageService } from 'ng-zorro-antd';
import { NavigationExtras, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit {

  tripsList: any;
  displayData;
  dateFormat: any = 'MM-dd-yy HH:mm:ss';
  tripLoading = false;
  sortAttribute = {key: 'lastUpdated', value: 'desc'};
  pageIndex = 1;
  pageSize = 10;
  sortBy = "lastUpdated";
  sortDirection = "DESC";
  total = 1;
  searchObj = {
    "userName" : "",
    "tripTitle" : "",
    "location" : "",
    "lastUpdatedTime" : ""
  }
 
  constructor(private tableSvc: TableService,
    private tripService: TripsService,
    private msgService: NzMessageService,
    private router: Router,
    private datePipe : DatePipe
    ) { }

  ngOnInit() {
    this.fetchAllTrips(false);
  }

  fetchAllTrips(value : boolean = false) { 
    this.pageIndex = value ? 1 : this.pageIndex;
    this.msgService.remove();
    //var msgId = this.msgService.loading("Loading...",{ nzDuration: 0 }).messageId;
      this.tripLoading = true;
      let searchRequest = {...this.searchObj};
      if(searchRequest['lastUpdatedTime'][0]){
        searchRequest["lastUpdatedMinTime"] = this.datePipe.transform(searchRequest['lastUpdatedTime'][0], 'yyyy-MM-dd').concat(' 00:00:00');
        searchRequest["lastUpdatedMaxTime"] =  this.datePipe.transform(searchRequest['lastUpdatedTime'][1], 'yyyy-MM-dd').concat(' 23:59:59');
        searchRequest['lastUpdatedTime'] ="";
      }

      let request = {
        "pageNumber" : this.pageIndex,
        "pageSize" : this.pageSize,
        "sortBy" : this.sortBy,
        "sortDirection" : this.sortDirection,
        "parameters" : searchRequest
      }

      this.tripService.getAllTrips(request).subscribe((res) => {
      //  this.msgService.remove(msgId);
         this.tripLoading = false;
         if(res.body) {
         this.tripsList = res.body.data;
         this.total = res.body.totalCount;
         this.displayData = this.tripsList;
         } else {
           this.tripsList = [];
         }
      },
      (err)=>{
        this.tripLoading =false;
      });   
  }

  getTripDetails(trip){
    let navigationExtras: NavigationExtras = {
      state: {
        trip: trip
      }
    };
    this.router.navigate(['/trips', trip.id], navigationExtras);
  }

  
   /**
   * Responsible for searching on table
   */
  searchInData(column:any){
    this.fetchAllTrips(true)
  }

   /**
   * To sort columns
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortBy=null;
    this.sortDirection=null;
    if(sortAttribute.value){
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
    }
    this.fetchAllTrips(false);
  }

  /**
   * Event on Reset Button in individual search
   * @param column 
   */
  reset(column){
    this.searchObj[column] = "";
    this.fetchAllTrips(true);
  }
}
