import { ParticipantModel } from './participant-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export class MeasureModel {
  name: string;
  abv: string;
  id: string;
}

export class MeasureModelFormBuilder {

  measureModel: MeasureModel = {
    name: null,
    abv: null,
    id: null,
  };

  measureForm: FormGroup = new FormGroup({
    name: new FormControl(this.measureModel.name, [Validators.required]),
    abv: new FormControl(this.measureModel.abv, [Validators.required]),
    id: new FormControl(this.measureModel.id)
  });

  constructor() {
    this.measureForm.valueChanges.subscribe(val => {
      this.measureModel.name = val.name;
      this.measureModel.abv = val.abv;
      this.measureModel.id = val.id;
    });
  }

  setModelVals(res: any) {
    this.measureForm.controls.name.setValue(res.name);
    this.measureForm.controls.abv.setValue(res.abv);
    this.measureForm.controls.id.setValue(res.id);
  }
  
}
