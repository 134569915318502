import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpeciesService {
  
  private speciesUrl = 'api/v1/admin/species';

  private updateSpeciesURL = 'api/v1/admin/translate/module-field';
  
  constructor(private httpRequest: HttpRequestService) { }

  species: [];
  measurements :  Observable<Response[]>;

  addSpecies(formData: FormData): Observable<Response>{
    return this.httpRequest.filePost(this.speciesUrl,formData);
  }

  async getAllLiteSpecies() {

    //if (this.species == null || this.species.length == 0) {     
      let response =  await this.httpRequest.get(this.speciesUrl + '/').toPromise();
        if(response) {
          this.species = response.body;  
        }   

    //}
    return this.species;
  }

  clearAllLiteSpecies(){
    this.species=null;
  }

  getAllMeasurements():Observable<any>{
    if (!this.measurements) {
      this.measurements = this.httpRequest.get(this.speciesUrl+'/measurements').pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this.measurements;
  }
    getAllSpecies(body:any):Observable<any> {
    return this.httpRequest.post(this.speciesUrl+"/list",body);
  }

  quickUpdateSpecies(speciesData: any,id: string): Observable<Response[]> {
    return this.httpRequest.post(this.speciesUrl+'/quick-update/'+id, speciesData);
  }

  updateSpecies(speciesData: any,id: string): Observable<any> {
    return this.httpRequest.filePut(this.speciesUrl+'/'+id, speciesData);
  }

  //edit translate data
  editTranslateSpanis(id: string, data: any): Observable<any>{
    const editURL =  this.httpRequest.post(this.updateSpeciesURL+'/'+id, data);
    return editURL;
  }
  //Update specific Species on selected ID
  toUpdateSpecies(id: string,data: any): Observable<any> {
    const speciesURLS = this.httpRequest.put(this.updateSpeciesURL+'/'+id, data);
    //console.log("url==",speciesURLS)
    return speciesURLS;
  }

  /**
   * THis method is used to fetch all the details for species to edit.
   * @param speciesId 
   */
  findById(speciesId: any): Observable<any>  {
    return this.httpRequest.get(this.speciesUrl+'/'+speciesId);
  }

  deleteSpecies(speciesId: any){
    return this.httpRequest.delete(this.speciesUrl+'/'+speciesId);
  }

  deleteSpeciesImage(imageType: string, imageName: string,speciesId: string){
    return this.httpRequest.put(this.speciesUrl+'/deleteImage/'+ imageType +"/"+speciesId+"?imageName="+imageName,"");
  }
}
