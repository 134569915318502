import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface AccountModel {
    id:string,
    name: string,
    email: string,
    role: string,
    enabled: boolean,
    password:string
}

export class accountFormBuilder {

    accountModel: AccountModel = {
        id:null,
        name: null,
        email: null,
        role: null,
        enabled: false,
        password:null
    };

    accountCreateForm: FormGroup = new FormGroup({
        id: new FormControl(this.accountModel.id),
        name: new FormControl(this.accountModel.name,[Validators.required]),
        email: new FormControl(this.accountModel.email,[Validators.required]),
        role: new FormControl(this.accountModel.role,[Validators.required]),
        password: new FormControl(this.accountModel.password,[Validators.required])
    });

    accountUpdateForm: FormGroup = new FormGroup({
        id: new FormControl(this.accountModel.id),
        name: new FormControl(this.accountModel.name,[Validators.required]),
        email: new FormControl(this.accountModel.email,[Validators.required]),
        role: new FormControl(this.accountModel.role,[Validators.required]),
        enabled:  new FormControl(this.accountModel.enabled,[Validators.required]) 
    });

    constructor() {
        this.accountCreateForm.valueChanges.subscribe(val => {
            this.accountModel.id = val.id;
            this.accountModel.name = val.name;
            this.accountModel.email = val.email;
            this.accountModel.role = val.role;
            this.accountModel.password = val.password
        })
        this.accountUpdateForm.valueChanges.subscribe(val => {
            this.accountModel.id = val.id;
            this.accountModel.name = val.name;
            this.accountModel.email = val.email;
            this.accountModel.role = val.role;
            this.accountModel.enabled = val.enabled
        })
    }

    setAccountCreateFormModelVals(res: any) {
        this.accountCreateForm.controls.id.setValue(res.id);
        this.accountCreateForm.controls.name.setValue(res.name);
        this.accountCreateForm.controls.email.setValue(res.email);
        this.accountCreateForm.controls.role.setValue(res.role);
        this.accountCreateForm.controls.password.setValue(res.password);
    }

    setAccountUpdateFormModelVals(res: any) {
        this.accountUpdateForm.controls.id.setValue(res.id);
        this.accountUpdateForm.controls.name.setValue(res.name);
        this.accountUpdateForm.controls.role.setValue(res.role);
        this.accountUpdateForm.controls.email.setValue(res.email);
        this.accountUpdateForm.controls.enabled.setValue(res.enabled);
    }
}