import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ParticipantModel } from "./participant-model";

export interface DataExportModel{
    dateRange: [];
    startDate: any;
    endDate: any;
    participants: [];
}

export class DataExportFormBuilder {

    dataExportModel: DataExportModel = {
        dateRange: null,
        participants: null,
        startDate: null,
        endDate: null,
    };

    dataExportForm: FormGroup = new FormGroup({
        dateRange: new FormControl(this.dataExportModel.dateRange),
        participants: new FormControl(this.dataExportModel.participants),
    });

    constructor() {
            this.dataExportForm.valueChanges.subscribe(val => {
                this.dataExportModel.dateRange = val.dateRange;
                if(val.dateRange && val.dateRange.length > 1){
                    this.dataExportModel.startDate = val.dateRange[0].getTime();
                    this.dataExportModel.endDate = val.dateRange[1].getTime();
                }
                this.dataExportModel.participants = val.participants;
        })
    }   

}