import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalService, NzMessageService, UploadFile } from 'ng-zorro-antd';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserFormBuilder, UserModel } from 'src/app/shared/models/user-model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  changePWForm: any;
  userForm: any;
  avatarUrl: string;
  responseData: string;
  oldPassword: String;
  newPassword: String;
  confirmPassword: String;
  user: UserFormBuilder = new UserFormBuilder();
  userModel : UserModel = this.user.userModel;
  loader:boolean;
  userDisplayName:string;
  fileList: UploadFile[] = [];

  constructor(private fb: FormBuilder, private message: NzMessageService,private authenticationService: AuthenticationService,private userService:UserService) { }

  ngOnInit(): void {
    this.changePWForm = this.fb.group({
        oldPassword: [ null, [ Validators.required ] ],
        newPassword: [ null, [ Validators.required ] ],
        confirmPassword: [ null, [ Validators.required ]]
    });
    this.fillLoginUserDetails();
}

  changePassword(): void {
    for (const i in this.changePWForm.controls) {
        this.changePWForm.controls[ i ].markAsDirty();
        this.changePWForm.controls[ i ].updateValueAndValidity();
    }


    if (this.newPassword == this.confirmPassword) {        
        this.authenticationService.userAuthenticationForChangePassword(sessionStorage.getItem('email'),this.oldPassword,this.confirmPassword).then((res) => {
            this.changePWForm.reset();
        });       
    }else{  
        this.message.error("New password and confirm password does not match.",{nzDuration:0});
        setTimeout(() => {
            this.message.remove();
        }, 2000);
       
    }
   
}

private getBase64(img: File, callback: (img: {}) => void): void {
    const reader = new FileReader();

    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

handleChange(info: { file: UploadFile }): void {
    this.getBase64(info.file.originFileObj, (img: string) => {
        this.avatarUrl = img;
        this.fileList.concat(info.file);
    });
}

    fillLoginUserDetails(){
        this.userModel.name = sessionStorage.getItem('userName');
        this.userModel.email = sessionStorage.getItem('email');
        this.userModel.phoneNumber = sessionStorage.getItem('phoneNumber');
      //  this.userModel.photoURL = sessionStorage.getItem('photoURL');
        this.userModel.uId = sessionStorage.getItem('uid');
        this.avatarUrl = this.userModel.photoURL;
        this.userDisplayName = this.userModel.name;
        //console.log("User model "+JSON.stringify(this.userModel));
    }

    updateUserDetails() {
        this.loader = true;
     //   this.message.loading("Updating...");
        var userDTOBlob = new Blob([JSON.stringify(this.userModel)], {
            type: 'application/json'
          });
          let formData: FormData = new FormData();
          formData.append('adminUserLiteDTO', userDTOBlob);
          this.fileList.forEach((file: any) => {
              formData.append('userImage', file.originFileObj);
          });  

        var msgId = this.message.loading("Updating...").messageId;
        this.userService.updateAdminUserProfile(this.userModel).subscribe(res => {
            sessionStorage.setItem('userName', this.userModel.name);
            //this.userDisplayName = this.userModel.name;            
            this.message.remove(msgId);
            this.message.success("User porfile updated successfully.", { nzDuration: 0 });
            setTimeout(() => {
                this.message.remove();
            }, 2000);
        },res => {
            this.loader = false;
            this.message.success(res, { nzDuration: 0 });
            setTimeout(() => {
                this.message.remove();
            }, 2000);
        }); 
    }
}
