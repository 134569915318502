import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { publishReplay, refCount } from 'rxjs/operators';
import { BaitsModel } from '../models/baits-model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private translationURL = 'api/v1/admin/translate/module-field';

  constructor(private httpRequest: HttpRequestService) {
  }

  fetchTranslation(id: String, body : any): Observable<Response> {
    return this.httpRequest.post(this.translationURL + "/" + id, body);
  }

  updateTranslation(id: string, data: any): Observable<any> {
    const rgbURL = this.httpRequest.put(this.translationURL + '/'+id, data);
    return rgbURL;
  }
}

