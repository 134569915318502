import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { interval, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    subscription: Subscription;
    constructor(private router: Router,private authenticateService :AuthenticationService) { 
     
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        
        if (sessionStorage.getItem('isLoggedIn')) {

          // const source = interval(3300000);
         //  this.subscription = source.subscribe(val =>  { 
             //  this.authenticateService.refreshAuthToken();
            //  }              
         //    ); 
            return true;
        }
        else {
            this.router.navigate(['/signIn']);
            return false;
        }

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
      }
}
