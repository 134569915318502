import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { RegionModel } from '../models/region-model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  private stateUrl = 'api/v1/admin/regions';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addRegion(formData:FormData): Observable<Response> {
    return this.httpRequest.filePost(this.stateUrl,formData);
  }

  regionList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl);
  }

  updateRegion(formData: FormData): Observable<Response[]> {
    return this.httpRequest.filePut(this.stateUrl, formData);
  }

  deleteById(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + '/' + id)
  }

  findById(regionId:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/'+regionId);
  }

  regionLiteList(): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/regionList');
  }

  getAllSystemLocations(startParam: String): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/all-locations?parameter='+startParam+"&limit="+5);
  }

  getAllOverlapRegions(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/overlap');
  }
}
