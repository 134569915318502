import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user-model';
import { AdminUserModel } from '../models/admin-user-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usersUrl = 'api/v1/admin/users';

  private profileUrl = 'api/v1/admin/profile';

  constructor(private httpRequest: HttpRequestService) { }

  /**
   * This service is responsible for getting all users. 
   * @param body 
   */
  getAllUsers(body:any): Observable<any>{
    return this.httpRequest.post(this.usersUrl+'/list',body);
  }

  updateAdminUserProfile(userModel:UserModel): Observable<any>{
    return this.httpRequest.put(this.profileUrl,userModel);
  } 

   /**
    * This service is responsible for creating user.
    * @param adminUserModel 
    */
  createUser(adminUserModel: AdminUserModel): Observable<any>{
    return this.httpRequest.post(this.usersUrl,adminUserModel);
  }

  /**
   * This service is responsible for updating user.
   * @param adminUserModel 
   */
  updateUser(adminUserModel: AdminUserModel): Observable<any>{
    return this.httpRequest.put(this.usersUrl,adminUserModel);
  }

}
