import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import { AuthenticationService } from '../services/authentication.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    ongoingRequests: Array<string> = [];
    private requests: HttpRequest<any>[] = [];
    constructor(private authenticationService: AuthenticationService,private messageService:NzMessageService,
                private router: Router
               // ,private dataService:DataService        
        ) { }   

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return this.authenticationService.getIdToken().mergeMap(userToken=>{

         //   this.requests.push(request);
        //        let userToken = sessionStorage.getItem("accessToken");
                //let user = sessionStorage.getItem("userName");
        
                if (userToken) {
                    request = request.clone({
                                       setHeaders: {
                                           'X-Authorization-Firebase': `${userToken}`
                                       }
                                   });       
                }else{
                    this.router.navigate[('/signIn')];
                }
                this.requests.push(request);
            //    this.dataService.changeMessage(true);
        
                return Observable.create(observer => {
                    const subscription = next.handle(request)
                        .subscribe(
                            event => {
                                if (event instanceof HttpResponse) {
                                    this.removeRequest(request);
                                    observer.next(event);
                                }
                            },
                            err => {
                            //    this.dataService.changeMessage(false);
                                this.messageService.remove();
                                if (err.status == 401) {
                                    this.router.navigate(['/signIn']);
                                }
                                else if(err.status == 400){
                                    err.error.errors.forEach(error => {
                                        this.messageService.error(error.message, { nzDuration: 5000 });
                                    });
                                }
                                else{
                                    this.messageService.error(JSON.stringify(err.message), { nzDuration: 3000 });
                                }
                                this.removeRequest(request);
                                observer.error(err);
                            },
                            () => {
                                this.removeRequest(request);
                                observer.complete();
                            });
                    return () => {
                        this.removeRequest(request);
                        subscription.unsubscribe();
                    };
                });
        });     
    }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
     //   this.dataService.changeMessage(false);
    }   
}