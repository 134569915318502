import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface InputSourceModel {
    id: string;
    country: {
        id:string,
        name:string,
        code:string
    };
    state: {
        id:string,
        name:string,
        country:{},
        code:string
    };    
    comments: string;
    source: [];
}

export class InputSourceFormBuilder {

    inputSourceModel: InputSourceModel = {
        id: null,
        country: null,
        state: null,
        comments: '',
        source : []
    };

    inputSourceForm: FormGroup = new FormGroup({
        id:  new FormControl(this.inputSourceModel.id),
        country: new FormControl(this.inputSourceModel.country, [Validators.required]),
        state: new FormControl(this.inputSourceModel.state, [Validators.required]),
        comments: new FormControl(this.inputSourceModel.comments),
        source : new FormControl(this.inputSourceModel.source)
    });

    constructor() {
            this.inputSourceForm.valueChanges.subscribe(val => {
                this.inputSourceModel.id = val.id;
                this.inputSourceModel.country = val.country;
                this.inputSourceModel.state = val.state;
                this.inputSourceModel.comments = val.comments;
                this.inputSourceModel.source = val.source;
        })
    }
   setModelVals(res:any) {
        this.inputSourceForm.controls.id.setValue(res.id),
        this.inputSourceForm.controls.country.setValue(res.country),
        this.inputSourceForm.controls.state.setValue(res.state),
        this.inputSourceForm.controls.comments.setValue(res.comments),
        this.inputSourceForm.controls.source.setValue(res.source);
    }

}