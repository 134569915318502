import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { publishReplay, refCount } from 'rxjs/operators';
import { BaitsModel } from '../models/baits-model';

@Injectable({
  providedIn: 'root'
})
export class UsersReviewService {

  private usersReviewURL = 'api/v1/admin/reviews';
  private translationURL = 'api/v1/admin/translate/module-field';

  constructor(private httpRequest: HttpRequestService) {
  }

  fetchAllUsersReview(): Observable<Response> {
    return this.httpRequest.get(this.usersReviewURL);
  }

  saveUsersReview(formData: FormData): Observable<Response> {
    return this.httpRequest.filePost(this.usersReviewURL, formData);
  }

  updateUsersReview(formData: FormData): Observable<Response> {
    return this.httpRequest.filePut(this.usersReviewURL, formData);
  }

  deleteUsersReview(id: String): Observable<Response> {
    return this.httpRequest.delete(this.usersReviewURL + "/" + id);
  }

  findById(id:any): Observable<any> {
    return this.httpRequest.get(this.usersReviewURL+'/'+id);
  }

  fetchTranslation(id: String, body : any): Observable<Response> {
    return this.httpRequest.post(this.translationURL + "/" + id, body);
  }

  updateTranslation(id: String, body : any): Observable<any> {
    return this.httpRequest.put(this.translationURL + "/" + id, body);
  }
}

