import { SplitInterpolation } from "@angular/compiler";

export interface UserModel {
    uId: string;
    name: string;
    email: string;
    phoneNumber: string;
    photoURL : string;
}


export class UserFormBuilder {

    userModel: UserModel = {
        uId: null,
        name: null,
        email: null,
        phoneNumber: null,
        photoURL : null
    };


}
