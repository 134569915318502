import { FormGroup, FormControl } from "@angular/forms";

export class StateModel {

    id: string;
    name: string;
}

export class StateFormBuilder {
    stateModel: StateModel = {
        id: null,
        name: null,
    }

    stateForm: FormGroup = new FormGroup({
        id: new FormControl(this.stateModel.id),
        name: new FormControl(this.stateModel.name),
    });

    constructor() {
            this.stateForm.valueChanges.subscribe(val => {
            this.stateModel.id = val.id;
            this.stateModel.name = val.name;
        });
    }

    setModelVals(res: any) {
            this.stateForm.controls.id.setValue(res.id),
            this.stateForm.controls.name.setValue(res.name)
    }
}