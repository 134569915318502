import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router'
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
participantList : any;
displayParticipantData : any;
tripList : any;
displayTripData : any;
catcheList : any;
displayCatcheData : any;
statistics={};
displayData:any;
overviewLoading = false;
catchesLoading = false;
participantLoading = false;
tripLoading = false;

  constructor(private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private router: Router
    ) { }

  ngOnInit() {
    //regulation manager user won't have access to dashboard so
    if(this.authenticationService.isRegulationManager()){
      this.router.navigate(['/regulations']);
    }else{
      this.displayData = ['dummy'];
      this.fetchOverview();
      this.fetchParticipantList();
      this.fetchTripList();
      this.fetchCatcheList();
    }    
  }

fetchOverview() {
  this.overviewLoading = true;
  this.dashboardService.overView().subscribe(res => {
    this.overviewLoading = false;
    this.statistics = res;
  });

//  this.statistics = {
//   totalParitipants:555,
//   activeParticipants:564,
//   inactiveParticipants:191,
//   past7daysParticipants:50,
//   totalTrips:439,
//   preTripSuvey: 191,
//   postTripSurvey: 50,
//   postCatchSurvey:183
//   };
}


  fetchParticipantList() {
    this.participantLoading = true;
    this.dashboardService.participantList().subscribe(res => {
    this.participantLoading = false;
    this.participantList = res; 
    this.displayParticipantData = this.participantList;
  });
}

fetchTripList() {
  this.tripLoading = true;
  this.dashboardService.tripList().subscribe(res => {
    this.tripLoading = false;
    this.tripList = res; 
    this.displayTripData = this.tripList;
});
}

fetchCatcheList() {
  this.catchesLoading = true;
  this.dashboardService.catcheList().subscribe(res => {
    this.catchesLoading = false;
    this.catcheList = res; 
    this.displayCatcheData = this.catcheList;
});
}


}
