import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  /**
   * This service provide searching support based on country , state and region name itself.
   * @param search 
   * @param regionList 
   */
  searchInRegionsWithCountryAndState(search:string,regionList:any){
    let filteredRegionList:any = []
    const dataOfRegion = regionList.filter(function (region) {
      if ((region.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (region.country.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (region.state.toLowerCase().indexOf(search.toLowerCase()) !== -1)) {
        return true;
      }
    });
    if (dataOfRegion.length !== 0) {
      filteredRegionList = dataOfRegion;
    }
    else {
      filteredRegionList = regionList;
    }
    return filteredRegionList;
  }
}
