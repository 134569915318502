import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalService, UploadFile, UploadFilter } from 'ng-zorro-antd';
import { PushMessageConditionModel } from 'src/app/shared/models/pushMessage-condition-model';
import { PushMessageFormBuilder, PushMessageModel } from 'src/app/shared/models/pushMessage-model';

import { PushMessageService } from 'src/app/shared/services/push-message.service';

@Component({
  selector: 'app-push-message',
  templateUrl: './push-message.component.html',
  styleUrls: ['./push-message.component.css']
})
export class PushMessageComponent implements OnInit {

isFormVisible = false;
pushMessage: PushMessageFormBuilder = new PushMessageFormBuilder();
pushMessageModel : PushMessageModel = this.pushMessage.pushMessageModel;
formHeaderText : any;
userCount : any;
isSpanVisible = false;
messageLoading : any;
notificationList : any;
sortAttribute = {key: 'name', value: 'ascend'};
pageIndex = 1;
pageSize = 10;
total = 1;
sortBy = "createdDate";
sortDirection = "DESC";
displayData: any;
submitValidateButton: boolean;
fileList: UploadFile[] = [];

  constructor(private pushMessageService : PushMessageService,
    private messageService : NzMessageService,
    private modalService : NzModalService) { 
  }

  ngOnInit() {
    this.getAllNotification();
  };

  /**
   * Get All notifications
   */
  getAllNotification() {
    this.messageLoading = true;

    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection
    }

    this.pushMessageService.getNotification(request).subscribe((resp) => {
      this.messageLoading = false;
      if(resp.body) {
        this.total = resp.body.totalCount;
        this.notificationList = resp.body.data;
      } else {
        this.total = 0
        this.notificationList = [];
      }
      this.displayData =  this.notificationList;
    },
    (err) => {
      this.notificationList = false;
    });
  }

  /**
   * Handle model
   */
  openMessageForm() {
    this.isFormVisible = true;
    this.submitValidateButton = true;
    this.isSpanVisible = false;
    this.pushMessageModel.conditions = [];
    if(this.pushMessageModel.conditions.length == 0) {
      this.addCondition();
    } 
    this.formHeaderText = "Create Notification"
    this.fileList = [];
  }

   /**
   * Cancel button event
   */
    onCancel() {
      this.isFormVisible = false;
      this.fileList = [];
      this.pushMessageModel.conditions = [];
    }

    /**
     * Handle validate button event
     * 
     * @param pushMessageModel 
     */
    checkUserCount(pushMessageModel : any) {
      this.pushMessageService.getUserCount(pushMessageModel).subscribe(res => {
        this.userCount = res.body;
        this.isSpanVisible = true;
        this.pushMessageModel.userCount = this.userCount;
      })
    }

    /**
     * Handle send button event
     * @param pushMessageModel 
     */
    sendNotification(pushMessageModel : any) {

      var PushMessageBlob = new Blob([JSON.stringify(pushMessageModel)], {
        type: 'application/json'
      });
      let formData: FormData = new FormData();
      formData.append('pushMessageDTO', PushMessageBlob);
      this.fileList.forEach((file: any) => {
        formData.append('imageFile', file);
      });

      const sendingData = this.messageService.loading('Sending ...').messageId;
      this.pushMessageService.sendNotification(formData).subscribe(res => {
        this.messageService.remove(sendingData);
        this.modalService.closeAll();
        this.getAllNotification();
      })
    }

  /**
   * To sort columns
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortBy=null;
    this.sortDirection=null;
    if(sortAttribute.value){
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
    }
    this.getAllNotification();
  }

  // /**
  // * Disable submit button
  // */
  //  OnSubmitSendButton() { 
  //   if(this.pushMessageModel.title != null && this.pushMessageModel.text != null && this.pushMessageModel.title != "" && this.pushMessageModel.text != "") {
  //     this.submitSendButton = false;
  //   } else {
  //     this.submitSendButton = true;
  //   }
  // }

  /**
  * Disable submit button
  */
    OnSubmitValidateButton() { 
        this.pushMessageModel.conditions.forEach((condition : any) => {
          if(condition.property != null && condition.condition != null && condition.propertyValue != null
                && condition.property != "" && condition.condition != "" && condition.propertyValue != "") {
            this.submitValidateButton = false;
          } else {
            this.submitValidateButton = true;
          }
        })
    }

    beforeUpload = (file: UploadFile): boolean => {
      this.fileList = this.fileList.concat(file);
      return false;
    };

    /**
     * Handles Add condition
     */
    addCondition() {
      var newIndex = 0;
      if(this.pushMessageModel.conditions != null) {
        newIndex = this.pushMessageModel.conditions.length;
      }

      var newCondition = { id : null, property : null, condition : null, propertyValue : null, index : newIndex} as PushMessageConditionModel;
      this.pushMessageModel.conditions.push(newCondition);
      this.updateConditionIndexes();
      this.submitValidateButton = true;
    }

    /**
     *  Handles Delete condition
     * @param condition 
     */
    removeCondition(condition: any) {
      this.pushMessageModel.conditions.splice(condition.index,1);
      this.updateConditionIndexes();
      this.OnSubmitValidateButton();
    }

    /**
     * To update array index
     */
    updateConditionIndexes(){
      if(this.pushMessageModel.conditions){
        var conditions = this.pushMessageModel.conditions
        conditions.forEach(function(condition,index){
          condition.index = index;
        });
      }
    };
}
