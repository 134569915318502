import { Component, OnInit } from '@angular/core';
import { ScansService } from 'src/app/shared/services/scans.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService, NzModalService, isEmpty } from 'ng-zorro-antd';

@Component({
  selector: 'app-scans',
  templateUrl: './scans.component.html',
  styleUrls: ['./scans.component.css']
})
export class ScansComponent implements OnInit {

  scansList: any = [];
  speciesFilterList: any = [];
  selectedSpecies: any = [];
  selectedUser: any = [];
  pageIndex = 1;
  pageSize = 10;
  sortBy = "date";
  sortDirection = "DESC";
  total = 1;
  loading = false;
  loadingScansList = false;
  emailSearch: any
  scanId: any;
  addImgToTraining: any
  downloadScansListReq: any = [];
  allCheckedMatchesIdSet: Set<String> = new Set();

  previewImage = null;
  previewVisible = false;

  formHeaderText: any;
  migrateObj = {
    "fromEmail": "",
    "toEmail": ""
  }
  submitButton: boolean;
  isVisible: boolean;

  constructor(
    private scansService: ScansService,
    private speciesService: SpeciesService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private msgService: NzMessageService,
  ) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
        if (Object.keys(this.router.getCurrentNavigation().extras.state.species).length > 0) {
          this.selectedSpecies.push(this.router.getCurrentNavigation().extras.state.species.id);
        }
        if (this.router.getCurrentNavigation().extras.state.scans.length > 0) {
          this.scanId = this.router.getCurrentNavigation().extras.state.scans;
        }
      }
    });
  }

  ngOnInit() {
    this.fetchAllScans();
    this.getFilterSpeciesList();
  }


  fetchAllScans(reset: boolean = false) {
    this.pageIndex = reset ? 1 : this.pageIndex;
    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null;
    let filteredUser = this.selectedUser.length > 0 ? this.selectedUser.join() : false;
    if (this.selectedUser == 'all') {
      filteredUser = null;
    }

    let speciesFilter = null;
    if (filteredSpecies && filteredSpecies.trim().length > 0) {
      speciesFilter = { "species": filteredSpecies, "email": this.emailSearch, "id": this.scanId, "user": filteredUser };
    }
    else {
      speciesFilter = { "email": this.emailSearch, "id": this.scanId, "user": filteredUser }
    }

    let request = {
      "pageNumber": this.pageIndex,
      "pageSize": this.pageSize,
      "sortBy": this.sortBy,
      "sortDirection": this.sortDirection,
      // "species":filteredSpecies
      "parameters": speciesFilter
    }
    this.loadingScansList = true;
    this.scansService.getAllScans(request).subscribe((res) => {
      this.loadingScansList = false;
      if (res.body) {
        this.total = res.body.totalCount;
        this.scansList = res.body.data;
      } else {
        this.scansList = [];
      }
    },
      (err) => {
        this.loadingScansList = false;
      });

  }

  sort(sortAttribute: any) {
    this.sortBy = null;
    this.sortDirection = null;
    if (sortAttribute.value) {
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC" : "");
    }
    this.fetchAllScans(false);
  }

  async getFilterSpeciesList() {
    this.speciesFilterList = await this.speciesService.getAllLiteSpecies();
  }

  handlePreview = (file) => {
    if (file) {
      this.previewImage = file;
      console.log(this.previewImage, "previewww--");
    } else {
      this.previewImage = null;
    }
    this.previewVisible = true;
  }


  collapseExpand(item) {

    item.expand = item.expand ? false : true;
    if (item.expand) {

    }
    // if (item) {
    //   this.speciesFormBuilder.setModelValues(item);
    // } else {
    //     return;
    // }
  }

  reset() {
    this.downloadScansImgs = null;
    this.emailSearch = null;
    this.fetchAllScans(true);
  }

  /**
   * Open modal window
   */
  openMigrateForm() {
    this.formHeaderText = "Migrate Catches"
    this.isVisible = true;
    this.submitButton = true;
    this.migrateObj.fromEmail = '';
    this.migrateObj.toEmail = '';
  }

  /**
   * Handle validation for modal window
   */
  OnSubmitButton() {
    if (this.migrateObj.fromEmail != null && this.migrateObj.toEmail != null && this.migrateObj.fromEmail != "" && this.migrateObj.toEmail != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
   * Handle Cancel button on Modal window
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
 * Open model to confirm delete
 * @param id 
 */
  confirmMigrationForm(): void {
    this.modalService.confirm({
      nzTitle: 'Are you sure want to migrate ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.MigrateCatches(),
      nzCancelText: 'No'
    });
  }

  /**
   * Handle confirm button event for Migrate catches 
   */
  MigrateCatches() {
    let searchRequest = { ...this.migrateObj };
    let request = {
      "parameters": searchRequest
    }

    if (this.migrateObj.fromEmail == this.migrateObj.toEmail) {
      this.msgService.error("Please enter two different email")
    } else {

      var msgId = this.msgService.loading("Migrating...", { nzDuration: 0 }).messageId;
      this.scansService.migrateScans(request).subscribe(res => {
        this.msgService.remove(msgId);
        this.msgService.success("Catches Migrated Successfully");
        this.modalService.closeAll();
      },
        (err) => {
          this.msgService.remove(msgId);
          this.msgService.error("Failed to migrate catches, please try again");
        });

    }
  }

  /* To copy any Text */
  copyValue(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  downloadScansImgs() {
    if (this.downloadScansListReq.length > 0) {
      this.scansService.downloadScansImgs(this.downloadScansListReq).subscribe((res) => {
        var blob = new Blob([res], { type: 'application/zip' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "scans-images";
        link.click();
        this.fetchAllScans();
      },
        (err) => {
          console.log(err, "errr")
        });
    }
    this.downloadScansListReq = [];
  }

  addToDownloadList(item: any, match: any) {
    let itemMatchesList: any;
    var matchIdPresent: any;

    //This is for select any one match from scans.
    if (this.allCheckedMatchesIdSet.size > 0) {
      itemMatchesList = item.matches.map(match => match.matchesId);
      matchIdPresent = itemMatchesList.find(value => this.allCheckedMatchesIdSet.has(value));

      if (matchIdPresent && match.matchesId != matchIdPresent) {
        this.allCheckedMatchesIdSet.delete(matchIdPresent)
        this.downloadScansListReq = this.downloadScansListReq.filter(obj => obj.catchMatchId !== matchIdPresent);
      }
    }

    //Collect all checked matches for add to training.
    if (!this.allCheckedMatchesIdSet.has(match.matchesId)) {
      let downloadScansObj = {
        "catchId": "",
        "catchMatchId": "",
        "speciesId": ""
      };
      downloadScansObj.catchId = item.id;
      downloadScansObj.catchMatchId = match.matchesId;
      downloadScansObj.speciesId = match.speciesId;
      this.downloadScansListReq.push(downloadScansObj);
      this.allCheckedMatchesIdSet.add(match.matchesId);
    }
    else {
      this.allCheckedMatchesIdSet.delete(match.matchesId);
      this.downloadScansListReq = this.downloadScansListReq.filter(obj => obj.catchMatchId !== matchIdPresent);
    }
  }

  isChecked(matchId: String): boolean {
    if (this.allCheckedMatchesIdSet.has(matchId)) { return true; }
    else { return false; }
  }

}
