import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { SignInFormBuilder, SignInModel } from 'src/app/shared/models/signin.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  nzLoading:boolean = false;
  signIn: SignInFormBuilder = new SignInFormBuilder();
  signInModel: SignInModel = this.signIn.signInModel;
  signInForm:FormGroup = this.signIn.signInForm;
  isVisible = false;
  email;
  currentYear:any = null;

    ngOnInit() {
        this.currentYear = new Date().getUTCFullYear();
    }

    constructor(private authenticationService: AuthenticationService,private messageService:NzMessageService  ) {
    }

    signin() {
      this.nzLoading = true;
      this.authenticationService.signIn(this.signInModel.email,this.signInModel.password);
      this.nzLoading = false;
    }


    handleOk(): void {

      this.isVisible = true;
      this.authenticationService.forgetPassword(this.forgetPasswordForm.controls.email.value).then(res => {
         this.messageService.success("Password reset email has been sent to your email address.", { nzDuration: 2000,nzAnimate:false });
         this.isVisible = false;
        this.forgetPasswordForm.reset();
    })
    .catch(err => {
      var message = err.message;
      this.messageService.remove();
        // we will never tell user that you are not registered
        if(err.code && err.code == 'auth/user-not-found'){
          this.messageService.success("Password reset email has been sent to your email address.", { nzDuration: 2000,nzAnimate:false });
          this.isVisible = false;
         this.forgetPasswordForm.reset();
        }else{
          this.messageService.error(message,{ nzDuration: 2000,nzAnimate:false });
        }


  });
    }

    handleCancel(): void {
      this.isVisible = false;
      this.forgetPasswordForm.controls.email.setValue=null;
//      this.forgetPasswordForm.reset();
    }

    showForgetPasswordModal(): void {
      this.isVisible = true;
    }

    forgetPasswordForm:FormGroup = new FormGroup({
      email : new FormControl('', [ Validators.required ])
    });

  

}
