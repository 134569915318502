import { FormControl, Validators, FormGroup } from '@angular/forms';

export interface RestrictionModel {
    aggregateLimit: string,
        bagLimit: string,
        gearRegulations: [],
        maxSize: string,
        minSize: string,
        trophyLimit: string,
        trophySize: string,
        vesselLimit: string,
        measurementId:string,
        trophyUnit : string,
        maxSizeUnit:string,
        minSizeUnit: string
}

export class RestrictioFormBuilder {

    restrictionModel: RestrictionModel = {
        aggregateLimit: null,
        bagLimit: null,
        gearRegulations: [],
        maxSize: null,
        minSize: null,
        trophyLimit: null,
        trophySize: null,
        vesselLimit: null,
        measurementId : null,
        trophyUnit : '\u0022',
        maxSizeUnit: '\u0022',
        minSizeUnit: '\u0022',
    }

    restrictionForm: FormGroup = new FormGroup({
        aggregateLimit: new FormControl(this.restrictionModel.aggregateLimit),
        bagLimit: new FormControl(this.restrictionModel.bagLimit, [Validators.required]),
        gearRegulations: new FormControl(this.restrictionModel.gearRegulations),
        maxSize : new FormControl(this.restrictionModel.maxSize, [Validators.required]),
        minSize: new FormControl(this.restrictionModel.minSize, [Validators.required]),
        trophyLimit: new FormControl(this.restrictionModel.trophyLimit),
        trophySize: new FormControl(this.restrictionModel.trophySize),
        vesselLimit: new FormControl(this.restrictionModel.vesselLimit),
        measurementId : new FormControl(this.restrictionModel.measurementId),
        trophyUnit : new FormControl(this.restrictionModel.trophyUnit),
        maxSizeUnit : new FormControl(this.restrictionModel.maxSizeUnit),
        minSizeUnit : new FormControl(this.restrictionModel.minSizeUnit)

    })

    constructor() {
        this.restrictionForm.valueChanges.subscribe(val => {
            this.restrictionModel.aggregateLimit = val.aggregateLimit;
            this.restrictionModel.bagLimit = val.bagLimit;
            this.restrictionModel.gearRegulations = val.gearRegulations;
            this.restrictionModel.maxSize = val.maxSize;
            this.restrictionModel.minSize = val.minSize;
            this.restrictionModel.trophyLimit = val.trophyLimit;
            this.restrictionModel.trophySize = val.trophySize;
            this.restrictionModel.vesselLimit = val.vesselLimit;
            this.restrictionModel.measurementId = val.measurementId;
            this.restrictionModel.trophyUnit = val.trophyUnit;
            this.restrictionModel.minSizeUnit = val.minSizeUnit;
            this.restrictionModel.maxSizeUnit = val.maxSizeUnit;

      })
    }
    
    setModelVals(res: any) {
          this.restrictionForm.controls.aggregateLimit.setValue(res.aggregateLimit),
          this.restrictionForm.controls.bagLimit.setValue(res.bagLimit),
          this.restrictionForm.controls.gearRegulations.setValue(res.gearRegulations),
          this.restrictionForm.controls.maxSize.setValue(res.maxSize),
          this.restrictionForm.controls.minSize.setValue(res.minSize),
          this.restrictionForm.controls.trophyLimit.setValue(res.trophyLimit),
          this.restrictionForm.controls.trophySize.setValue(res.trophySize),
          this.restrictionForm.controls.vesselLimit.setValue(res.vesselLimit),
          this.restrictionForm.controls.measurementId.setValue(res.measurementId)
          this.restrictionForm.controls.trophyUnit.setValue(res.trophyUnit)
          this.restrictionForm.controls.minSizeUnit.setValue(res.minSizeUnit)
          this.restrictionForm.controls.maxSizeUnit.setValue(res.maxSizeUnit)
    }
 
}