import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzModalService, isTemplateRef } from 'ng-zorro-antd';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { TableService } from 'src/app/shared/services/table.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { StateModel } from 'src/app/shared/models/state-model';
import { InputSourceService } from 'src/app/shared/services/inputSource.service';

@Component({
  selector: 'app-input-source',
  templateUrl: './input-source.component.html',
  styleUrls: ['./input-source.component.css']
})
export class InputSourceComponent implements OnInit {

  indeterminate: boolean = false;
  search: any;
  displayData;
  InputSourceList:any;
  searchValue = '';
  searchAddress = [];
  sortName = null;
  sortValue = null;
  sortAttribute={ key: 'name', value: 'ascend'};
  regionLoading = false;
  iconVisible: boolean;
  OverlapRegionList:any;
  isContentVisible: boolean;
  formHeaderText: string;
  overlapMessage: string;
  inputSourceLinksById = [];

  constructor(private tableSvc: TableService,
    private msgService: NzMessageService,
    private modalService: NzModalService,
    private inputSourceService: InputSourceService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: NzMessageService,
  ) { 
       this.displayData = this.InputSourceList;
  }

  ngOnInit() {
    this.fetchInputSourceList();
  }

  AddEditRegion(inputSource) {

  }

  fetchInputSourceList() { 
    this.msgService.remove();
    this.regionLoading = true;
    this.inputSourceService.inputSourceList().subscribe((res) => {
      this.regionLoading = false;
      this.InputSourceList = res.body;
      this.displayData = this.InputSourceList;
    }
    );   
  }

  goToAddEditInputSource(item) {
    let navigationExtras: NavigationExtras = {
      state: {
        name: "lksmd",
        inputSource: item
      }
    };
    this.router.navigate(['/input-source', item.id], navigationExtras);
  }

  removeInputSource(id){
    this.InputSourceList.forEach( (region, index) => {
      if(region.id === id){
        this.InputSourceList.splice(index,1);
        return false;
      } 
    });

    this.InputSourceList = [...this.InputSourceList];
    this.displayData =this.tableSvc.sort(this.sortAttribute, this.InputSourceList);
 }


  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    if(this.sortAttribute.key == 'state') {
      this.displayData = this.tableSvc.sortInList(sortAttribute, this.InputSourceList);
    }
    else {
      this.displayData = this.tableSvc.sort(sortAttribute, this.InputSourceList);
    }
  }

  deleteInputSource(item){
        var msgId = this.msgService.loading("Deleting...",{ nzDuration: 0 }).messageId;
        this.inputSourceService.deleteByCountryAndState(item.country.id, item.state.id).subscribe((res) => {      
          this.msgService.remove(msgId);        
          this.removeInputSource(item.id);
        });
  }


  showDeleteConfirm(item): void {
    this.modalService.confirm({
      nzTitle: 'Delete this input-source?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteInputSource(item),
      nzCancelText: 'No'
    });
  }

  indexing(): void {
    var createMsgId = this.messageService.loading("Indexing in progress, please wait...").messageId;

    this.inputSourceService.indexing().subscribe(res => {
      this.messageService.remove(createMsgId);
    },
      err => {
      
    }
    )    
  }

  filterState(item){
    var isMatch = false;

    item.state.forEach(s => {
      if(this.search){
         if(s.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1){
          isMatch = true;
         } 
       }
    });

    return isMatch;  
  };

  /**
   * Reset the Data List
   */
  resetData(){
      this.fetchInputSourceList();
      this.search = null;
  }

  /* To copy any Text */
  copyValue(val: string){
  let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getSourceList(item){
    this.isContentVisible = true;
    this.inputSourceLinksById = item.source;
  }

}