import { Component, OnInit, AbstractType } from '@angular/core';
import { UserFeedbackService } from 'src/app/shared/services/user-feedback.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { DatePipe } from '@angular/common';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.css']
})
export class UserFeedbackComponent implements OnInit {
  feedbackLoading: boolean;
  userFeedbackList: any;
  displayData: any;
  sortAttribute = {key: 'name', value: 'ascend'};
  pageIndex = 1;
  pageSize = 10;
  total = 1;
  sortBy = "createdDate";
  sortDirection = "DESC";
  selectedSpecies: any = [];
  speciesFilterList: any = [];
  search:any
  isCheckedButton = false;
  searchObj = {
    "name":"",
    "email":"",
    "feedback":"",
    "createdDate": "",
    "speciesId":"",
    "isReplyed":"false",
    "category":""
  }; 
  searchObj1 = {
    "toMail":"",
    "mailContent":"",
    "userFeedbackId":"",
    "subject":"",
    "bcc":"",
    "requestFeedback":this.isCheckedButton
  };
  selectedRegions: any = [];
  isVisible: boolean;
  ToMail: any;
 // submitButton : boolean;
  formHeaderText: string;
  userFeedbackId: any;
  isContentVisible: boolean;
  mailContent: any;
  replyMessage : string;
  previewImage = null;
  previewVisible: boolean;

  //filteredSpecies: string;

  constructor(private userFeedbackService: UserFeedbackService,
    private datePipe: DatePipe,
    private speciesservice: SpeciesService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private msgService: NzMessageService) { 
    }

  ngOnInit() {
    this.fetchuserFeedbackList();
    this.getFilterSpeciesList();
  }

  /**
   * To get list of species for combobox dropdown
   */
  async getFilterSpeciesList() {
    this.speciesFilterList = await this.speciesservice.getAllLiteSpecies();
  }

  /**
   * To sort columns
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortBy=null;
    this.sortDirection=null;
    if(sortAttribute.value){
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
    }
    this.fetchuserFeedbackList(false);
  }
  
   /**
   * Responsible for searching on table
   */
  searchInData(column:any){
    this.fetchuserFeedbackList(true)
  }


  /**
   * This method is responsible for fetching data
   */
  fetchuserFeedbackList(reset: boolean = false){

    if (reset) {
      this.pageIndex = 1;
    }
    let searchRequest = {...this.searchObj};
    if(searchRequest['createdDate'][0]){
      searchRequest["createdMinDate"] = this.datePipe.transform(searchRequest['createdDate'][0], 'yyyy-MM-dd').concat(' 00:00:00');
      searchRequest["createdMaxDate"] =  this.datePipe.transform(searchRequest['createdDate'][1], 'yyyy-MM-dd').concat(' 23:59:59');
      searchRequest['createdDate'] ="";
    }

    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null ;
    let speciesFilter = null;
    if(filteredSpecies && filteredSpecies.trim().length>0){
      //speciesFilter={"species":filteredSpecies};
      searchRequest['speciesId']=filteredSpecies;
    }
  
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "search": this.search,
      "parameters": searchRequest
    }

    this.feedbackLoading = true;
    this.userFeedbackService.userFeedbackList(request).subscribe((res) => {
      this.feedbackLoading = false;
      if(res.body) {
        this.total = res.body.totalCount;
        this.userFeedbackList = res.body.data;
      } else {
        this.total = 0
        this.userFeedbackList = [];
      }
      this.displayData =  this.userFeedbackList;
    },
    (err) => {
      this.feedbackLoading = false;
    });
  }

  /**
   * Event on Reset Button in individual search
   * @param column 
   */
  reset(column){
    this.searchObj[column] = "";
    this.fetchuserFeedbackList(true);
  }

  /**
   * This method is responsible to link Species
   * @param species 
   */
  callSpecies(species){
    let navigationExtras: NavigationExtras = {
      state: {
        species: species
      }
    };
    this.router.navigate(['/species'], navigationExtras);
  }

  /**
   * This method is responsible to link Scans
   * @param scans 
   */
  callScans(scans) {

    if(scans != null){
      let navigationExtras: NavigationExtras = {
        state: {
          scans: scans,
          species: ''
        }
      };
      this.router.navigate(['/scans'], navigationExtras);
    }else{
      this.msgService.error("Scan details not available for this feedback.")
    }

  }

  /**
   * This method is responsible to View Regulation
   */
  ViewRegulation(species, region) {
    if(species != null && region != null) {
      this.selectedRegions.push(region.id);
      this.selectedSpecies.push(species.id);

      region.id = this.selectedRegions;
      species.id = this.selectedSpecies;

      let navigationExtras: NavigationExtras = {
        state: {
          species: species,
          region: region
        }
      };
      this.router.navigate(['/regulations'], navigationExtras);
    } else {
      this.msgService.error("Regulation details not available for this feedback.")
    }
  }

  /**
   * This method is responsibble to open Reply Modal Window
   * @param email 
   * @param id 
   */
  OpenReplyForm(email : any, id : any) {
   // this.submitButton = true;
    this.isVisible = true;
    this.formHeaderText = 'Reply';
    this.searchObj1.toMail = email;
    this.searchObj1.mailContent = null;
    this.searchObj1.userFeedbackId = id;
    this.searchObj1.bcc = 'support@fishverify.com';
    this.searchObj1.subject = 'FishVerify Feedback'
    this.isCheckedButton = false;
  }

  // /**
  //  * Disable - enable Submit button on modal window
  //  */
  // OnSubmitButton(email) { 
  //   console.log("email = "+email);
  //   if(this.searchObj1.mailContent != null && this.searchObj1.mailContent != ""  && 
  //     this.searchObj1.subject != null && this.searchObj1.subject != "") {
  //     this.submitButton = false;
  //   } else {
  //     this.submitButton = true;
  //   }
  // }

  /**
   * Handle Cancel button on Modal window
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Handle Submit button for Reply Modal window
   * @param tomail 
   * @param mailContent 
   * @param userFeedbackId 
   */
  onSubmit() {
    let searchRequest = {...this.searchObj1};
    searchRequest.requestFeedback = this.isCheckedButton;
    let request = {
      "parameters": searchRequest
    }
  //  this.submitButton = true;
    var updateMsgId = this.msgService.loading("Sending...", {nzDuration:0}).messageId;
    this.userFeedbackService.userFeedbackReply(request).subscribe((res) => {
      this.msgService.remove(updateMsgId);
      this.msgService.success("Reply sent successfully");
      this.modalService.closeAll();
      this.fetchuserFeedbackList();
  },
  (err)=>{
   // this.submitButton = false;
    this.msgService.remove(updateMsgId);
    this.msgService.error("Failed to send reply, please try again");
  });
  }

  /**
   * Open Reply Message
   * @param message 
   */
  OpenReply(message : any) {
    this.isContentVisible = false;
  if(message != null && message != '') {
    this.isContentVisible = true;
  } else {
    this.isContentVisible = false;
  }
  this.formHeaderText = 'Reply';
  var message1 = message.replace(/\||/g, "");
  this.replyMessage = message1;
  }

  /**
   * Open Feedback Message
   * @param message 
   */
  OpenFeedback(message : any) {
    if(message != null && message != '') {
      this.isContentVisible = true;
    } else {
      this.isContentVisible = false;
    }
    this.formHeaderText = 'Feedback';
    this.replyMessage = message;
  }

  /**
   * handles image preview
   * @param file 
   */
  handlePreview = (file) => {
    if(file){
      this.previewImage = file;
    }else{
      this.previewImage = null;
    }    
    this.previewVisible = true;
  }

  editorInit(quill: any){
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      let ops = []
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert
          })
        }
      })
      delta.ops = ops
      return delta
    })
  }

  /**
   * handle archive feedback event form
   */
  ArchiveFeedbackForm(feedbackId : String) {
    this.modalService.confirm({
      nzTitle: 'Archive feedback?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.archiveFeedback(feedbackId),
      nzCancelText: 'No'
    });
  }

  /**
   * handle archive feedback event
   * @param feedbackId 
   */
  archiveFeedback(feedbackId : String) {
    var messageId = this.msgService.loading("Archiving...").messageId;
    this.userFeedbackService.archiveFeedback(feedbackId).subscribe(res => {
      this.msgService.remove(messageId);
      this.removeFeedbackFromList(feedbackId);
    });
  }

  /**
   * Remove feedback from list
   * @param id
   */
  removeFeedbackFromList(feedbackId : String) {
    this.userFeedbackList.forEach((feedback, index) => {
      if(feedback.id === feedbackId) {
          this.userFeedbackList.splice(index,1);
          return false;
      }
    });
    this.userFeedbackList = [...this.userFeedbackList];
    this.displayData = this.userFeedbackList;
    this.fetchuserFeedbackList();
  }
}