import { Component, OnInit } from '@angular/core';
import { TripsService } from 'src/app/shared/services/trips.service';
import { NzMessageService } from 'ng-zorro-antd';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.css']
})
export class TripDetailsComponent implements OnInit {

  surveyQuestionsList = [];
  catchesList = [];
  locationsList = [];
  tripDetails: any;
  tripStatusColor: string= 'orange';
  dateFormat: any = 'MM-dd-yy HH:mm:ss';


  constructor(
    private tripsService : TripsService,
    private messageService: NzMessageService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    var loadMessage = this.messageService.loading("Loading...", {nzDuration:0}).messageId;
    this.tripsService.tripDetailsById(this.route.snapshot.params.tripId).subscribe((res) => {
      this.tripDetails = res.body;
      if(!this.tripDetails.tripSurveyStatus || this.tripDetails.tripSurveyStatus.trim() === '')
        this.tripDetails.tripSurveyStatus = 'NONE'
      if(this.tripDetails.tripSurveyStatus === 'SUBMITTED')
        this.tripStatusColor = 'green';
      else if(this.tripDetails.tripSurveyStatus === 'PARTIAL')
        this.tripStatusColor = 'geekblue'

      this.surveyQuestionsList = this.tripDetails!=null ? this.tripDetails.surveyDetails : [];
      this.catchesList = this.tripDetails!=null ? this.tripDetails.tripCatches : [];
      this.locationsList = this.locationsList !=null ? this.tripDetails.tripLocations : [];

      this.messageService.remove(loadMessage);

    });
  }

}
