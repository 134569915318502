import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { RegulationModel } from '../models/regulation-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AIRegulationService {

  private stateUrl = 'api/v1/admin/ai-regulation';
  private regulationUpdateURL = 'api/v1/admin/translate/module-field';
  private saveDraftURL = 'api/v1/admin/regulations';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addRegulation(regulationModel:RegulationModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl,regulationModel);
  }

  createDraftRegulation(): Observable<Response> {
    return this.httpRequest.postIndexing(this.stateUrl+'/generate-draft-regulation');
  }

  getAllRegulations(body:any):  Observable<any> {
    return this.httpRequest.post(this.stateUrl+"/list",body);
  }

  deleteById(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + '/' + id)
  }

  findById(regulationId:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/'+regulationId);
  }

  updateRegulation(regulationModel:RegulationModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl+'/'+regulationModel.id,regulationModel);
  }

  saveDraftRegulation(regulationModel:RegulationModel): Observable<Response> {
    return this.httpRequest.put(this.saveDraftURL+'/'+regulationModel.actualReguId,regulationModel);
  }
}
