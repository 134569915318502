import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { RegulationModel } from '../models/regulation-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegulationService {

  private stateUrl = 'api/v1/admin/regulations';
  private auditUrl = 'api/v1/admin/audit';
  private regulationUpdateURL = 'api/v1/admin/translate/module-field';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addRegulation(regulationModel:RegulationModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl,regulationModel);
  }

  getAllRegulations(body:any):  Observable<any> {
    return this.httpRequest.post(this.stateUrl+"/list",body);
  }

  deleteById(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + '/' + id)
  }

  findById(regulationId:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/'+regulationId);
  }

  updateRegulation(regulationModel:RegulationModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl+'/'+regulationModel.id,regulationModel);
  }

  duplicateRegulation(body:any): Observable<Response> {
    return this.httpRequest.post(this.stateUrl +'/duplicate' +'/'+body.id, body);
  }

  quickEditRegulation(body : any): Observable<Response> {
    return this.httpRequest.post(this.stateUrl +'/quickEdit' + '/' +body.id, body);
  }

  getLastChangeSummery(dataId : any): Observable<any> {
    return this.httpRequest.get(this.auditUrl +'/last-changes?dataId='+dataId+'&type=Regulation');
  }

  addToWatchList(id: String, watchlist: boolean): Observable<Response> {
    return this.httpRequest.put(this.stateUrl + '/add-to-watchlist'+'/'+id+ '/'+watchlist, {});
  }
  //Edit for transalate in spanice language
  editRegulationFeild(id:string, body : any): Observable<Response>{
    const editTransURL = this.httpRequest.post(this.regulationUpdateURL+ '/'+id, body);
    return  editTransURL;
  }
  // update translate module feilds for regulation
  updateRegulationFeild(id: string, data: any): Observable<any> {
    const rgbURL = this.httpRequest.put(this.regulationUpdateURL + '/'+id,data);
    return rgbURL;
  }
}
