import { Component, OnInit } from '@angular/core';
import { StateRecordModel, StateRecordModelFormBuilder } from 'src/app/shared/models/stateRecord-model';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { FormGroup } from '@angular/forms';
import { StateService } from 'src/app/shared/services/state.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { StateRecordService } from 'src/app/shared/services/state-record.service';
import { TableService } from 'src/app/shared/services/table.service';

@Component({
  selector: 'app-state-records',
  templateUrl: './state-records.component.html',
  styleUrls: ['./state-records.component.css']
})
export class StateRecordsComponent implements OnInit {

  //stateRecordModel: StateRecordModel;
  isVisible: boolean;
  formHeaderText: String;
  submitButton: boolean;
  loadingData: boolean;
  speciesList: [];
  countryList: [];
  statesList = [];
  loadingStates: boolean;
  stateRecord: StateRecordModelFormBuilder = new StateRecordModelFormBuilder();
  stateRecordModel: StateRecordModel = this.stateRecord.stateRecord; 
  stateRecordForm: FormGroup = this.stateRecord.stateRecordForm;
  recordLoading = false;
  StateRecordList: any;
  displayData: any=[];
  sortAttribute = {key: 'name', value: 'ascend'};
  pageIndex = 1;
  pageSize = 10;
  total = 1;
  sortBy = "species.name";
  sortDirection = "ASC";
  selectedSpecies: any = [];
  speciesFilterList: any = [];
 
  constructor(private speciesservice: SpeciesService,
    private countryservice: CountryService,
    private stateservice: StateService,
    private modalService: NzModalService,
    private messageservice: NzMessageService,
    private tableService: TableService,
    private staterecordservice: StateRecordService) { 
    this.stateRecordModel = new StateRecordModel();
  }

  ngOnInit() {
    this.fetchStateRecordList();
    this.getFilterSpeciesList();
  }
 
  /**
   * Get State Record List
   * @param reset 
   */
  fetchStateRecordList(reset: boolean = false) {
    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null ;
    let speciesFilter = null;
    if(filteredSpecies && filteredSpecies.trim().length>0){
      speciesFilter={"species":filteredSpecies};
    }
    this.pageIndex = reset ? 1 : this.pageIndex;
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "parameters": speciesFilter
    }

    this.recordLoading = true;
    this.staterecordservice.stateRecordList(request).subscribe((res) => {
      this.recordLoading = false;
      if(res.body) {
        this.total = res.body.totalCount;
        this.StateRecordList = res.body.data;
      } else {
        this.total = 0
        this.StateRecordList = [];
      }
      this.displayData =  this.StateRecordList;
    },
    (err) => {
      this.recordLoading = false;
    }
    );
  }

  /**
   * Open Model window for add and update
   * @param recordsData 
   */
  openModelWindow(recordsData: any) {
    this.isVisible = true;
    if (recordsData == null) {
      this.formHeaderText = 'Create New State Record';
      this.getSpecies();
      this.getCountries();
      this.stateRecordModel = new StateRecordModel();
     // const recordDataClone = Object.assign({}, this.stateRecordModel);
     // this.stateRecordModel = recordDataClone;
   //  this.stateRecordModel.countryId    
    // this.stateRecordForm.reset();
      this.submitButton = true;    
    } else {
      this.formHeaderText = 'Update State Record';
      const recordDataClone = Object.assign({}, recordsData);
      this.stateRecordModel = recordDataClone;
      this.getSpecies();
      this.getCountries();
      this.countryChange(this.stateRecordModel.countryId, false);
      this.submitButton = false;
      
    }
  }

  /**
   * Get list of Species
   */
  async getSpecies() {
    this.loadingData = true;
    this.speciesList = await this.speciesservice.getAllLiteSpecies();
    this.loadingData = false;
  }

  /**
   * Get list of Countries
   */
  async getCountries() {
    this.loadingData = true;
    this.countryList = await this.countryservice.fetchCountries();
    this.loadingData = false;
  }

  /**
   * On Change country State List will be Upadted
   * @param $event 
   * @param resetSelectedState 
   */
  countryChange($event, resetSelectedState) {
    if ($event != null) {
      this.loadingStates = true;
      if(resetSelectedState){
        this.statesList = [];
        this,this.stateRecordModel.stateId = null;
      }
        this.stateservice.fetchStatesOfCountry($event.id).subscribe((res) => {
        this.statesList = res;
        this.loadingStates = false;
        this.OnSubmitButton();
      });
    }
  }

  /**
   * Compare StateRecordModel Object and fill data in update State Record
   * @param obj1 
   * @param obj2 
   */
  compareFn(obj1: StateRecordModel, obj2: StateRecordModel) {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }
 
/**
 * Disable Submit Button
 */
 OnSubmitButton() {
  if (this.stateRecordModel.speciesId != null && this.stateRecordModel.countryId != null && this.stateRecordModel.stateId != null && 
    this.stateRecordModel.majorValue != null && this.stateRecordModel.majorValue != "" && this.stateRecordModel.majorUnit != null) {
    this.submitButton = false;
  } else {
    this.submitButton = true;
  }
}

/**
 * Handle Model Cancel button event
 */
onCancel() {
  this.isVisible = false;
}

/**
 * Handle Model Submit button event 
 */
onSubmit() { 
  if (this.stateRecordModel.id != null) {
    var updateMsgId = this.messageservice.loading('Updating...').messageId;
    this.staterecordservice.updateStateRecord(this.stateRecordModel).subscribe((res) => {
      this.messageservice.remove(updateMsgId);
      this.modalService.closeAll();
      this.fetchStateRecordList();
    });
  } else {
    this.submitButton = true;
    const loadingData = this.messageservice.loading('Saving...').messageId;
    this.staterecordservice.saveStateRecord(this.stateRecordModel).subscribe((res) => {
      this.messageservice.remove(loadingData);
      this.modalService.closeAll();
      this.fetchStateRecordList();
   },(res) => {  
    this.submitButton = false;
 });
  }
}

/**
 * Delete State Record
 * @param id 
 */
deleteStateRecord(id) {
  var deleteMsgId = this.messageservice.loading("Deleting...").messageId;
  this.staterecordservice.deleteStateRecord(id).subscribe((res) => {
    this.messageservice.remove(deleteMsgId); 
    this.removeStateRecordFromList(id);
  });
}

/**
 * Delete StateRecord Model window
 * @param id 
 */
deleteStateRecordForm(id): void {
  this.modalService.confirm({
    nzTitle: 'Delete this State Record ?',
    nzOkText: 'Yes',
    nzOkType: 'danger',
    nzOnOk: () => this.deleteStateRecord(id),
    nzCancelText: 'No'
  });
}

/**
 * Remove one State Record from list 
 * @param id 
 */
removeStateRecordFromList(id) {
  this.StateRecordList.forEach((record, index) => {
    if(record.id === id) {
        this.StateRecordList.splice(index,1);     
        return false;
    }
  });
  this.StateRecordList = [...this.StateRecordList];
  this.displayData =  this.StateRecordList;
}

/**
 * Sort column
 * @param sortAttribute 
 */
// sort(sortAttribute: any) {
//   this.sortAttribute = sortAttribute;
//   this.displayData = this.tableService.sort(sortAttribute, this.StateRecordList);
// }

sort(sortAttribute: any) {
  this.sortBy=null;
  this.sortDirection=null;
  if(sortAttribute.value){
    this.sortBy = sortAttribute.key;
    this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
  }
  this.fetchStateRecordList(false);
}

 /**
   * To get list of species for combobox dropdown
   */
  async getFilterSpeciesList() {
    this.speciesFilterList = await this.speciesservice.getAllLiteSpecies();
  }
}
