import { FormGroup, FormControl, Validators } from "@angular/forms";
import { StateModel } from "./state-model";

export interface RegionModel {
    id: string;
    name: string;
    country: {
        id:string,
        name:string,
        code:string
    };
    // state: {
    //     id:string,
    //     name:string,
    //     code:string,
    //     country:string
    // };
    state:StateModel[];
    type:string;
    zonePriority:string;
    comments: string;
    kmlFilePath:any;
    fileName:string;
    cmId:string;
    defaultLat: string;
    defaultLong: string;
    published: boolean;
}

export class RegionFormBuilder {

    regionModel: RegionModel = {
        id: null,
        name: '',
        country: null,
        state: [],
        type: '',
        zonePriority:'',
        comments: '',
        kmlFilePath : '',
        fileName:'',
        cmId:null,
        defaultLat: null,
        defaultLong: null,
        published :false
    };

    regionForm: FormGroup = new FormGroup({
        name: new FormControl(this.regionModel.name, [Validators.required]),
        country: new FormControl(this.regionModel.country, [Validators.required]),
        state: new FormControl(this.regionModel.state, [Validators.required]),
        type: new FormControl(this.regionModel.type, [Validators.required]),
        zonePriority: new FormControl(this.regionModel.zonePriority),
        comments: new FormControl(this.regionModel.comments),
        kmlFilePath:  new FormControl(this.regionModel.kmlFilePath),
        id:  new FormControl(this.regionModel.id),
        fileName : new FormControl(this.regionModel.fileName),
        cmId : new FormControl(this.regionModel.cmId),
        defaultLat: new FormControl(this.regionModel.defaultLat),
        defaultLong: new FormControl(this.regionModel.defaultLong),
        published : new FormControl(this.regionModel.published)
    });

    constructor() {
            this.regionForm.valueChanges.subscribe(val => {
                this.regionModel.id = val.id;
                this.regionModel.name = val.name;
                this.regionModel.country = val.country;
                this.regionModel.state = val.state;
                this.regionModel.type = val.type;
                this.regionModel.zonePriority = val.zonePriority;
                this.regionModel.comments = val.comments;
                this.regionModel.kmlFilePath = val.kmlFilePath;
                this.regionModel.fileName = val.fileName;
                this.regionModel.cmId = val.cmId;
                this.regionModel.defaultLat = val.defaultLat;
                this.regionModel.defaultLong = val.defaultLong;
                this.regionModel.published = val.published;
        })
    }
   setModelVals(res:any) {
        this.regionForm.controls.id.setValue(res.id),
        this.regionForm.controls.name.setValue(res.name),
        this.regionForm.controls.country.setValue(res.country),
        this.regionForm.controls.state.setValue(res.state),
        this.regionForm.controls.type.setValue(res.type),
        this.regionForm.controls.zonePriority.setValue(res.zonePriority),
        this.regionForm.controls.comments.setValue(res.comments),
        this.regionForm.controls.kmlFilePath.setValue(res.kmlFilePath),
        this.regionForm.controls.fileName.setValue(res.fileName),
        this.regionForm.controls.cmId.setValue(res.cmId),
        this.regionForm.controls.defaultLat.setValue(res.defaultLat),
        this.regionForm.controls.defaultLong.setValue(res.defaultLong)
        this.regionForm.controls.published.setValue(res.published);
    }

    

}