import { FormGroup, FormControl, Validators } from "@angular/forms";

export class UsersReviewModel {

    id: string;
    userName: string;
    rating: number;
    review: String;
    imageUrl: string;
    removedImages: [string];
}

export class UsersReviewModelFormBuilder {
    usersReviewModel: UsersReviewModel = {
        id: null,
        userName: null,
        rating:0,
        review: null,
        imageUrl: null,
        removedImages: null
    }

    usersReviewForm: FormGroup = new FormGroup({
        id: new FormControl(this.usersReviewModel.id),
        userName: new FormControl(this.usersReviewModel.userName),
        rating: new FormControl(this.usersReviewModel.rating),
        review: new FormControl(this.usersReviewModel.review),
        imageUrl: new FormControl(this.usersReviewModel.imageUrl)
    });

    constructor() {
        this.usersReviewForm.valueChanges.subscribe(val => {
            this.usersReviewModel.id = val.id;
            this.usersReviewModel.userName = val.userName;
            this.usersReviewModel.rating = val.rating;
            this.usersReviewModel.review = val.review;
            this.usersReviewModel.imageUrl = val.imageUrl;
        });
    }

    setModelVals(res: any) {
        this.usersReviewForm.controls.id.setValue(res.id),
            this.usersReviewForm.controls.userName.setValue(res.userName),
            this.usersReviewForm.controls.rating.setValue(res.rating),
            this.usersReviewForm.controls.review.setValue(res.review),
            this.usersReviewForm.controls.imageUrl.setValue(res.imageUrl);
    }
}


