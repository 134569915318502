import { Component, OnInit } from '@angular/core';
import { DefaultRegulationModel } from 'src/app/shared/models/defaultRegulation-model';
import { RegionService } from 'src/app/shared/services/region.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DefaultRegulationService } from 'src/app/shared/services/default-regulation.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { StateService } from 'src/app/shared/services/state.service';

@Component({
  selector: 'app-default-regulation',
  templateUrl: './default-regulation.component.html',
  styleUrls: ['./default-regulation.component.css']
})
export class DefaultRegulationComponent implements OnInit {
  isVisible: boolean;
  formHeaderText: string;
  defaultRegulationModel : any;
  submitButton: boolean;
  loadingData: boolean;
  regionList: any;
  RegulationLoading: boolean;
  regulationList: any;
  displayData: any = [];
  sortAttribute = {key: 'defaultLimit', value: 'ascend'};
  pageIndex = 1;
  pageSize = 10;
  total = 1;
  sortBy = "region.name";
  sortDirection = "ASC";
  selectedRegions : any = [];
  filteredRegionList : any;
  filterDataOfRegion = () => true;
  disabledRegions = false;
  isDefaultVisible: boolean;
  selectedStates : any = [];
  stateFilterList : any = [];

  constructor(private regionService: RegionService,
    private msgService: NzMessageService,
    private modalService : NzModalService,
    private defaultRegulationService: DefaultRegulationService,
    private utilityService: UtilityService,
    private stateService : StateService) { 
    this.defaultRegulationModel = new DefaultRegulationModel();
  }

  ngOnInit() {
    this.getRegions();
    this.fetchDefaultRegulationList();
    this.getFilterStateList();
  }

  /**
   * Open model window for add and update
   * @param regulationData 
   */
  openRegulationForm(regulationData: any) {
    this.isVisible = true;
    if (regulationData == null) {
      this.submitButton = true;
      this.disabledRegions = false;
      this.formHeaderText = 'Create New Default Regulation';
      this.defaultRegulationModel = new DefaultRegulationModel();
    } else {
      this.submitButton = false;
      this.disabledRegions = true;
      this.formHeaderText = 'Update Default Regulation';
      const regulationClone = Object.assign({}, regulationData);
      this.getRegions();
      this.defaultRegulationModel = regulationClone;
      let regions: any = [];
      regions.push(regulationData.region.id);
      this.defaultRegulationModel.regions = regions;
    }
  }

  /**
   * Get all Regions
   */
  getRegions() {
    this.loadingData = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionList = res.body;
      this.filteredRegionList = this.regionList;
      this.loadingData = false;
    });
  }

  /**
   * Handle cancel button on model window
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * Handle submit button on model window
   */
  onSubmit() {
    if (this.defaultRegulationModel.id != null) {
      var updateMsg = this.msgService.loading('Updating...').messageId;
      this.defaultRegulationService.updateRegulation(this.defaultRegulationModel).subscribe(res => {
        this.msgService.remove(updateMsg);
        this.modalService.closeAll();
        this.fetchDefaultRegulationList();
      });
    } else {
      var saveMsg = this.msgService.loading('Saving...').messageId;
      this.defaultRegulationService.saveRegulation(this.defaultRegulationModel).subscribe(res => {
        this.msgService.remove(saveMsg);
        this.modalService.closeAll();
        this.fetchDefaultRegulationList();
      });
    }
  }

  /**
   * get all default regulation list
   * @param reset 
   */
  fetchDefaultRegulationList(reset: boolean = false){
    let filteredRegions = this.selectedRegions.length > 0 ? this.selectedRegions.join() : null ;
    let filteredStates = this.selectedStates.length > 0 ? this.selectedStates.join() : null ;
    let filter = null;
    if((filteredRegions && filteredRegions.trim().length > 0) || (filteredStates && filteredStates.trim().length > 0) ) {
      filter = {"regions" : filteredRegions,
                "states": filteredStates}
    }
    this.pageIndex = reset ? 1 : this.pageIndex;
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "parameters": filter
    }
    this.RegulationLoading = true;
    this.defaultRegulationService.getAllRegulations(request).subscribe((res) => {
    this.RegulationLoading = false;
      if(res.body) {
        this.total = res.body.totalCount;
        this.regulationList = res.body.data;
      } else {
        this.total = 0
        this.regulationList = [];
      }
      this.displayData =  this.regulationList;
    },
    (err) => {
      this.RegulationLoading = false;
    });
  }
 
  /**
   * Delete Regulation confirmation model window 
   * @param id 
   */
  deleteRegulationForm(id): void {
    this.modalService.confirm({
      nzTitle: 'Delete this Regulation ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteRegulation(id),
      nzCancelText: 'No'
    });
  }
  
  /**
   * Delete Regulation
   * @param id 
   */
  deleteRegulation(id) {
    var deleteMsgId = this.msgService.loading("Deleting...").messageId;
    this.defaultRegulationService.deleteRegulation(id).subscribe((res) => {
      this.msgService.remove(deleteMsgId); 
      this.removeRegulationFromList(id);
    });
  }

  /**
   * Remove regulation from list 
   * @param id 
   */
  removeRegulationFromList(id) {
    this.regulationList.forEach((record, index) => {
      if(record.id === id) {
          this.regulationList.splice(index,1);     
          return false;
      }
    });
    this.regulationList = [...this.regulationList];
    this.displayData =  this.regulationList;
    this.fetchDefaultRegulationList();
  }

  /**
   * Sort column
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortBy=null;
    this.sortDirection=null;
    if(sortAttribute.value){
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
    }
    this.fetchDefaultRegulationList(false);
  }

  /**
   * handle searching in Region list
   * @param search 
   */
  searchInRegionList(search:string) {
    this.filteredRegionList = this.utilityService.searchInRegionsWithCountryAndState(search,this.regionList);
  } 

  /**
   * handle model window for duplicate regulation
   * @param regulationId 
   */
  openDuplicateForm(regulationId : any) {
    this.isDefaultVisible = true;
    this.submitButton = true;
    this.formHeaderText = "Duplicate Regulation"
    this.defaultRegulationModel.id = regulationId;
    this.defaultRegulationModel.regions = null;
  }

  /**
   * handle cancel button on duplicate model window
   */
  onDuplicateCancel() {
    this.isDefaultVisible = false;
  }

  /**
   * handle submit button to duplicate regulation
   */
  onDuplicate() {
    let duplicateMsg = this.msgService.loading("Duplicating..").messageId;
    this.defaultRegulationService.duplicateRegulation(this.defaultRegulationModel).subscribe(res => {
      this.msgService.remove(duplicateMsg);
      this.modalService.closeAll();
      this.fetchDefaultRegulationList();
    });
  }

  OnSubmitButton() {
    if (this.defaultRegulationModel.regions != null &&  this.defaultRegulationModel.regions != "" && this.defaultRegulationModel.defaultLimit != null && this.defaultRegulationModel.defaultLimit != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  OnDuplicateSubmitButton() {
    if (this.defaultRegulationModel.regions != null &&  this.defaultRegulationModel.regions != "" ) {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
   * This method is used to get All states 
   */
  async getFilterStateList() {
    this.stateFilterList = await this.stateService.getAllLiteStates();
  }
}
