// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
      apiKey: "AIzaSyBsciaqoyuXw7_0XvUzmC52pnAUsCjQdBk",
      authDomain: "fishverifygcp.firebaseapp.com",
      databaseURL: "https://fishverifygcp.firebaseio.com",
      projectId: "fishverifygcp",
      storageBucket: "fishverifygcp.appspot.com",
      messagingSenderId: "232395965406",
      appId: "1:232395965406:web:a6e4b2955f71a2b72c934f",
      measurementId: "G-3642NTH4FV"
  },
  apiUrl:'https://app.fishverify.com/fishverifyapi/'
};

/*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
