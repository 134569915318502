import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { SpeciesFormBuilder, SpeciesModel } from 'src/app/shared/models/species.model';
import { FormGroup } from '@angular/forms';
import { BaitsAndLuresService } from 'src/app/shared/services/baits-and-lures.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { RegionService } from 'src/app/shared/services/region.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.css']
})
export class SpeciesComponent implements OnInit {

  view: string = 'listView';
  // newProject: boolean = false;
    speciesList: SpeciesModel[];
    regionFilterList: any = [];
    speciesFilterList: any = [];
    pageIndex = 1;
    pageSize = 10;
    sortBy = "name";
    sortDirection = "ASC";
    total = 1;
    loading = false;
    loadingSpeciesList = false;
    selectedRegions: any = [];
    selectedSpecies: any = [];
    baitsNLuresList : any;
    checkUserRole : boolean;
    speciesFormBuilder: SpeciesFormBuilder= new SpeciesFormBuilder();
  speciesModel: SpeciesModel = this.speciesFormBuilder.speciesModel;
  speciesForm: FormGroup = this.speciesFormBuilder.speciesForm;
  filteredRegionListForSearch:any = this.regionFilterList;
  filterDataOfRegion = () => true;
     


  countryList = [];
  //baitsNLuresList = [];

  previewImage = null;
  previewVisible = false;

  constructor (
    private router: Router,
    private speciesService: SpeciesService,
    private msgService: NzMessageService,
    private countryService: CountryService,
    private regionService: RegionService,
    private baitsNLuresService: BaitsAndLuresService,
    private modalService: NzModalService,
    private utilityService : UtilityService,
    private authenticationService : AuthenticationService,
    private route: ActivatedRoute
   // private projectListSvc: AppsService, private modalService: NzModalService
    ) {
        this.checkUserRole = this.authenticationService.isAmin();
        this.route.queryParams.subscribe(params => {
          if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
            this.selectedSpecies.push(this.router.getCurrentNavigation().extras.state.species);
          }
        });
    }

  ngOnInit(): void {
    this.fetchCountries();
    this.fetchAllBaitsNLures();
    this.fetchAllSpecies();
    this.getRegions();
    this.getFilterSpeciesList();
  }

  fetchAllSpecies( reset: boolean = false){

    this.pageIndex = reset ? 1 : this.pageIndex;
    
    let filteredRegions = this.selectedRegions ? this.selectedRegions.join() : null ;
    let filteredSpecies = this.selectedSpecies ? this.selectedSpecies.join() : null ;
    let request = {
      "pageNumber":this.pageIndex,
      "pageSize":this.pageSize,
      "sortBy":this.sortBy,
      "sortDirection":this.sortDirection,
      "boundaries":filteredRegions,
      "species":filteredSpecies
    }
      this.loadingSpeciesList =true
      this.speciesService.getAllSpecies(request).subscribe((res) => {
          //this.msgService.remove(msgId);
          this.loadingSpeciesList =false;

          if(res.body){
            this.total = res.body.totalCount;
            this.speciesList = res.body.data;
          }else{
            this.speciesList = [];
          }
        },
        (err) => {
          this.loadingSpeciesList =false;
        });
  }


  sort(sortAttribute: any) {
    this.sortBy=null;
    this.sortDirection=null;
    if(sortAttribute.value){
      this.sortBy = sortAttribute.key;
      this.sortDirection = sortAttribute.value == "ascend" ? "ASC" : (sortAttribute.value == "descend" ? "DESC":"");  
    }
    this.fetchAllSpecies(false);
  }
  
  showDeleteConfirmation(id, name){
    this.modalService.confirm({
      nzTitle: 'Delete ' +name+ '?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.removeSpecies(id),
      nzCancelText: 'No'
    });
  }
  removeSpecies(id){
    var msgId = this.msgService.loading("Deleting...",{ nzDuration: 0 }).messageId;
    this.speciesService.deleteSpecies(id).subscribe((res) => {
      this.msgService.remove(msgId);        
      this.speciesService.clearAllLiteSpecies();
      this.fetchAllSpecies(false)
    });
  }
  // compareLocation(id: any, id2: any){
  //   return id && id2 ? id === id2 : id === id2;
  // }
   /**
   * Fetch all the countries
   */
  async fetchCountries() {
    //this.countryService.fetchCountries().subscribe((res) => {
      this.countryList = await this.countryService.fetchCountries();
   // });
  }

  fetchAllBaitsNLures(){
    this.baitsNLuresService.fetchAllBaitsNLures().subscribe((res) => {
      this.baitsNLuresList = res;
    });
  }

  collapseExpand(item){

    item.expand = item.expand ? false: true;
    if(item.expand){
      
      this.speciesService.findById(item.id).subscribe((res) => {        
        var response = res.body;
        item.saltWater = response.saltWater;
        item.freshWater = response.freshWater;
        item.baitsAndLures = response.baitsAndLures;
        item.locationsExcluded = response.locationsExcluded;      
        //this.speciesFormBuilder.setModelValues(res.body);
      });   
    };
        // if (item) {
        //   this.speciesFormBuilder.setModelValues(item);
        // } else {
        //     return;
        // }
  }

  /**
   * This function is used to update the current species.
   * 
   * @param updatedSpecies
   */
  submitSpeciesForm(updatedSpecies) {
    let tempSpecies: SpeciesModel = this.speciesFormBuilder.speciesModel;
    tempSpecies.baitsAndLures=updatedSpecies.baitsAndLures;
    tempSpecies.commonName=updatedSpecies.commonName;
    tempSpecies.scientificName = updatedSpecies.scientificName;
    tempSpecies.edibility = updatedSpecies.ediblity;
    tempSpecies.kosher = updatedSpecies.kosher;
    tempSpecies.poisonous = updatedSpecies.poisonous;
    tempSpecies.saltWater = updatedSpecies.saltWater;
    tempSpecies.freshWater = updatedSpecies.freshWater;
    tempSpecies.locationsExclude = updatedSpecies.locationsExcluded;


    var saveMsgId = this.msgService.loading("Updating...", {nzDuration:0}).messageId;            
    this.speciesService.quickUpdateSpecies(tempSpecies,updatedSpecies.id).subscribe((res) => {
        this.msgService.remove(saveMsgId);
        this.msgService.success("Successfully updated.",{nzDuration:1000});
        //clear species cache
        this.speciesService.clearAllLiteSpecies();
      }
    );
  }

  goToAddEditSpecies(species){
      let navigationExtras: NavigationExtras = {
        state: {
          species: species
        }
      };
      this.router.navigate(['/species', species.id], navigationExtras);
  }

  callRegulation(species){
    this.selectedSpecies.push(species.id);
    species.id = this.selectedSpecies;
    
    let navigationExtras: NavigationExtras = {
      state: {
        species: species,
        region:''
      }
    };
    this.router.navigate(['/regulations'], navigationExtras);
  }

  /**
   * Check the scans for selected species
   * @param species 
   */
  callScans(species){
    let navigationExtras: NavigationExtras = {
      state: {
        species: species,
        scans: ''
      }
    };
    this.router.navigate(['/scans'], navigationExtras);
  }

  /**
   * To get list of species for combobox dropdown
   */
  async getFilterSpeciesList() {
    //this.loading= true;
    this.speciesFilterList = await this.speciesService.getAllLiteSpecies();
      //this.loading= false;
  }
  
  /**
   * To get list of regions for combobox dropdown
   */
  getRegions() {
    //this.loading = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionFilterList = res.body;
      this.filteredRegionListForSearch = this.regionFilterList;
      //this.loading = false;
    });
  }  

  handlePreview = (file) => {
    if(file && file.filePath){
      this.previewImage = file.filePath;
    }else{
      this.previewImage = null;
    }    
    this.previewVisible = true;
  }

   /**
   * This method is responsible for searching on region list.
   * @param search 
   */  
  searchInRegionList(search:string) {
    this.filteredRegionListForSearch = this.utilityService.searchInRegionsWithCountryAndState(search,this.regionFilterList);
  } 

  // collapse(item,$event){
  //     if ($event) {
  //       if (item) {
  //         this.speciesFormBuilder.setModelValues(item);
  //       } else {
  //           return;
  //       }
  //     }
  // }
}
