import { Component, OnInit } from '@angular/core';
import { RegulationModelFormBuilder, RegulationModel } from 'src/app/shared/models/regulation-model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router'
import { SeasonFormBuilder, SeasonModel } from 'src/app/shared/models/season-model';
import { RestrictioFormBuilder, RestrictionModel } from 'src/app/shared/models/restriction-model';
import { RegulationService } from 'src/app/shared/services/regulation.service';
import { NzMessageService } from 'ng-zorro-antd';
import { RegionService } from 'src/app/shared/services/region.service';
import { SpeciesService } from 'src/app/shared/services/species.service';
import { ReturnStatement } from '@angular/compiler';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-edit-regulation',
  templateUrl: './add-edit-regulation.component.html',
  styleUrls: ['./add-edit-regulation.component.css']
})
export class AddEditRegulationComponent implements OnInit {

  dateRange: any[];
  seasonLoadIndex: number = 0;
  selectedSeason: number = 0;
  showAutoTranslate: boolean;
  feildNamesList = [];
  gearRegulations: []
  updateTransalate = [];
  translateSpane = {};
  transformedData: any[] = [];
  gearing: boolean;
  snagging: boolean;
  spears: boolean;
  multipleHooks: boolean;
  visibleHarvestOverrideForm: boolean = false;
  isVisibleRegulation: boolean = false;
  regionList: any = [];
  spanishRegulationKey: String = '';
  measurementList: any = [];
  speciesList: any = [];
  loadingData: boolean = false;
  processSubmitForm = false;
  prevSeasonIndex: number = 0;
  disableAllForm: boolean = false
  regulationModel: RegulationModel = new RegulationModelFormBuilder().regulationModel;
  seasonModel: SeasonModel = new SeasonFormBuilder().seasonModel
  restrictionModel: RestrictionModel = new RestrictioFormBuilder().restrictionModel;
  disableAllFormControls = false;
  seasonDateValid: boolean = true;
  checkedGearRegulations;
  filteredRegionListForSearch: any = this.regionList;
  filterDataOfRegion = () => true;
  isAutoTranslateRegulationControl= new FormControl(false);
  selectedRegions: any;
  selectedSpecies: any;
  selectedStates: any;
  pageIndex: number;
  selectedDates: any;
  selectedDatesTemp : any = [];
  //Pop-Up Form Creation
  regulationESForm: FormGroup;
  setRegularField: any;
  updatedReguId: any;

  /**
   * Constructor
   * @param formBuilder 
   * @param regulationService 
   * @param messageService 
   * @param regionService 
   * @param speciesService 
   */
  constructor(
    private messageService: NzMessageService, private regionService: RegionService, private regulationService: RegulationService,
    private speciesService: SpeciesService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private router: Router,
    private datePipe: DatePipe) {
    this.getRegions();
    this.getSpecies();
    this.getMeasurements();

    this.route.queryParams.subscribe(params => {
      // if (Object.keys(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state.selectedRegions).length > 0) {
      //   this.selectedRegions = this.router.getCurrentNavigation().extras.state.selectedRegions;
      // }
      // if (Object.keys(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state.selectedSpecies).length > 0) {
      //   this.selectedSpecies = this.router.getCurrentNavigation().extras.state.selectedSpecies;
      // }
      // if (Object.keys(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state.selectedStates).length > 0) {
      //   this.selectedStates = this.router.getCurrentNavigation().extras.state.selectedStates;
      // }
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
          if(this.router.getCurrentNavigation().extras.state.selectedRegions){
              const selectedRegions = this.router.getCurrentNavigation().extras.state.selectedRegions;
              if (Object.keys(selectedRegions).length > 0) {
                this.selectedRegions = selectedRegions;
              }
          }
          if(this.router.getCurrentNavigation().extras.state.selectedSpecies){
            const selectedSpecies = this.router.getCurrentNavigation().extras.state.selectedSpecies;
            if (Object.keys(selectedSpecies).length > 0) {
              this.selectedSpecies = selectedSpecies;
            }
          }
          if(this.router.getCurrentNavigation().extras.state.selectedStates){
            const selectedStates = this.router.getCurrentNavigation().extras.state.selectedStates;
            if (Object.keys(selectedStates).length > 0) {
              this.selectedStates = selectedStates;
            }
          }
          if(this.router.getCurrentNavigation().extras.state.pageIndex){
            const pageIndex = this.router.getCurrentNavigation().extras.state.pageIndex;
              this.pageIndex = pageIndex;
          }
          if(this.router.getCurrentNavigation().extras.state.selectedDates){
              this.selectedDatesTemp = this.router.getCurrentNavigation().extras.state.selectedDates;
              this.selectedDates = this.selectedDatesTemp;
          }
      }      
    });

    // this.route.queryParams.subscribe(params => {
    //   if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
    //        this.router.getCurrentNavigation().extras.state.regulation;
    //   }
    // });

    // => Form Cretion:
    this.regulationESForm = this.fb.group({
      fieldName: new FormControl(null, [Validators.required]),
      fieldValue: new FormControl(null, [Validators.required])
    });
  }

  /**
   *  On component initialze
   */
  ngOnInit() {
    var isEditMode = this.route.snapshot.params.regulationId ? true : false;
    this.showAutoTranslate = this.route.snapshot.params.regulationId ? true : false;
    var newSeason = {
      index: this.seasonLoadIndex, id: '', startDate: null, endDate: null, startDateStr: '', endDateStr: '', type: 'OPEN', isDefault: true, dateRange: null, restrictions: {
        aggregateLimit: null,
        bagLimit: null,
        gearRegulations: [],
        maxSize: null,
        minSize: null,
        trophyLimit: null,
        trophySize: null,
        vesselLimit: null,
        dailyLimit: null,
        measurementId: null,
        trophyUnit: '\u0022',
        maxSizeUnit: '\u0022',
        minSizeUnit: '\u0022'
      } as RestrictionModel
    };

    this.regulationModel.seasons = [newSeason];
    this.updateSeasonIndexes();
    if (isEditMode) {
      this.findRegulationById(this.route.snapshot.params.regulationId);
    }
  }

  /**
  * Add new form groups for dynamic form generation of season 
  */
  addSeason() {
    var nextIndex = 0
    if (this.regulationModel.seasons != null) {
      nextIndex = this.regulationModel.seasons.length;
    }
    var newSeason = {
      index: nextIndex, id: '', startDate: null, endDate: null, startDateStr: '', endDateStr: '', type: '', isDefault: false, dateRange: null, restrictions: {
        aggregateLimit: this.regulationModel.seasons[0].restrictions.aggregateLimit,
        bagLimit: this.regulationModel.seasons[0].restrictions.bagLimit,
        gearRegulations: [],
        maxSize: this.regulationModel.seasons[0].restrictions.maxSize,
        minSize: this.regulationModel.seasons[0].restrictions.minSize,
        trophyLimit: this.regulationModel.seasons[0].restrictions.trophyLimit,
        trophySize: this.regulationModel.seasons[0].restrictions.trophySize,
        vesselLimit: this.regulationModel.seasons[0].restrictions.vesselLimit,
        measurementId: null,
        trophyUnit: this.regulationModel.seasons[0].restrictions.trophyUnit,
        maxSizeUnit: this.regulationModel.seasons[0].restrictions.maxSizeUnit,
        minSizeUnit: this.regulationModel.seasons[0].restrictions.minSizeUnit,
      } as RestrictionModel
    };
    this.regulationModel.seasons.push(newSeason);
    this.selectedSeason = newSeason.index;
    this.updateSeasonIndexes();
  }

  /**
   * To remove dynamic form for season
   * @param index 
   */
  removeSeason(season: any) {


    this.regulationModel.seasons.splice(season.index, 1);
    //  if(this.selectedSeason == season.index){
    //   this.selectedSeason = season.index-1;
    // }
    this.updateSeasonIndexes();
    //  this.visibleHarvestOverrideForm = false;
  }

  updateSeasonIndexes() {
    if (this.regulationModel.seasons) {
      var seasons = this.regulationModel.seasons
      seasons.forEach(function (season, index) {
        season.index = index;
      });
    }
  };

  modelChanged(value) {
    this.selectedSeason = value;
  }

  // isSeasonSelected(pointIndex){
  //   return this.selectedSeason == pointIndex;
  // }
  /**
   * To add new regulation
   */
  addRegulation() {
    this.regulationModel.seasons.forEach(season => {
      if (season.dateRange && season.dateRange.length > 1) {
        // season.startDate = season.dateRange[0].toString();
        // season.endDate = season.dateRange[1].toString();
        season.startDate = null;
        season.endDate = null;
        var startD = new Date(season.dateRange[0]);
        var endD = new Date(season.dateRange[1]);
        season.startDateStr = startD.getFullYear() + "-" +
          ("0" + (startD.getMonth() + 1)).slice(-2) + "-" +
          ("0" + startD.getDate()).slice(-2) + " " +
          "00:00:00";
        season.endDateStr = endD.getFullYear() + "-" +
          ("0" + (endD.getMonth() + 1)).slice(-2) + "-" +
          ("0" + endD.getDate()).slice(-2) + " " +
          "23:59:59";
      }
    });
    this.regulationModel.id = this.route.snapshot.params.regulationId ? this.route.snapshot.params.regulationId : null;
    if (this.regulationModel.type == 'PROHIBITED' || this.regulationModel.type == 'DEFAULT') {
      this.regulationModel.customLimit = null;
      this.regulationModel.measurementId = null;
    } else if (this.regulationModel.type == 'CUSTOM') {
      this.regulationModel.measurementId = null;
    } else {
      this.regulationModel.customLimit = null;

      this.regulationModel.seasons.filter(function (season) {
        if (season.isDefault && season.restrictions.measurementId != null) {
          this.regulationModel.measurementId = season.restrictions.measurementId;
        }
      })
    }

    //If auto translate for regulation check box checked then called API
     if (this.isAutoTranslateRegulationControl.value) {
     const regsID = this.route.snapshot.params.regulationId;
      this.feildNamesList = ['addInfo', 'addInfo2'];
      
      //Create json for indivituals regulation feilds
      this.feildNamesList.forEach(fieldName => {
        const transformedObject = {
          adminModuleName: 'REGULATION',
          fieldName: `${fieldName}`,
          value: this.regulationModel[fieldName],
          targetLanguage: 'ES',
          targetText: '',
          sourceLanguage: ''
        };
        this.transformedData.push(transformedObject)
      });
      //Update Service called
      this.regulationService.updateRegulationFeild(regsID, this.transformedData).subscribe((res) => {
        const datas = res.body;
      }, error => {
        this.messageService.error("Something was Wrong, Try Again", { nzDuration: 4000 });
      })
    }else {
      this.isAutoTranslateRegulationControl.setValue(false);
    }

    if (this.regulationModel.id) {
      var updateMessageId = this.messageService.loading("Updating...", { nzDuration: 0 }).messageId;
      this.regulationService.updateRegulation(this.regulationModel).subscribe((res) => {
        this.messageService.remove(updateMessageId);
        this.messageService.success("updated");
        this.isAutoTranslateRegulationControl.setValue(false);
        this.transformedData = [];
        let navigationExtras: NavigationExtras = {
          state: {
            region: this.selectedRegions,
            species: this.selectedSpecies,
            state: this.selectedStates,
            pageIndex: this.pageIndex,
            selectedDates: this.selectedDates
          }
        }
        this.router.navigate(['/regulations'], navigationExtras);
      });
    } else {
      var saveMessageId = this.messageService.loading("Saving...", { nzDuration: 0 }).messageId;
      this.regulationService.addRegulation(this.regulationModel).subscribe((res) => {
        this.messageService.remove(saveMessageId);
        this.messageService.success("saved");
        this.router.navigate(['/regulations']);
      });
    }
  }


  /**
   * Set the value of gear regulation to restriction model
   * @param value 
   */
  addGearRegulation(gearRegulations: [], season) {
    // alert("get");
    season.restrictions.gearRegulations = gearRegulations;
  }
  isGearRegularionIn(season, selectedGear) {

    return season.restrictions.gearRegulations ? season.restrictions.gearRegulations.includes(selectedGear) : false;

  }
  /**
   * For visiblity of harvest override form
   */
  // showHarvestOverrideForm(index:any) {
  //   this.visibleHarvestOverrideForm = true;
  // }

  /**
   * To get list of regions for combobox dropdown
   */
  getRegions() {
    this.loadingData = true;
    this.regionService.regionLiteList().subscribe(res => {
      this.regionList = res.body;
      this.filteredRegionListForSearch = this.regionList;
      this.loadingData = false;
    });
  }

  /**
   * To get list of species for combobox dropdown
   */
  async getSpecies() {
    this.loadingData = true;
    this.speciesList = await this.speciesService.getAllLiteSpecies();
    this.loadingData = false;
  }
  // getSpecies() {
  //   this.loadingData = true;
  //   this.speciesService.getAllLiteSpecies().subscribe(res => {
  //     this.speciesList = res.body;
  //     this.loadingData = false;
  //   });
  // }

  /**
   * To get list of measurments for combobox dropdown 
   */
  getMeasurements() {
    this.speciesService.getAllMeasurements().subscribe(res => {
      this.measurementList = res.body;
    })
  }

  /**
   * This method is responsible for redirecting to regions page
   */
  forwardToRegulationsPage() {
    let navigationExtras: NavigationExtras = {
      state: {
        region: this.selectedRegions,
        species: this.selectedSpecies,
        state: this.selectedStates,
        pageIndex: this.pageIndex,
        selectedDates: this.selectedDates
      }
    }
    this.router.navigate(['/regulations'], navigationExtras);
  }

  /**
   * Season date validation
   */
  // seasonDateValidation() {

  //   this.regulationModel.seasons.forEach(season => {

  //     if (season.startDate >= season.endDate && season.index >= 1) {
  //       this.messageService.error("End date should be greater then start date.");
  //       this.seasonDateValid = false;
  //     }

  //   })
  // }

  /**
   *  Append measurement units to restricition property
   * @param seasons 
   */
  // appendMeasurementUnitsToField(seasons: SeasonModel[]) {

  //   if (seasons.length == 1) {
  //     if (seasons[0].restrictions.trophySize != null && seasons[0].restrictions.minSize != null && seasons[0].restrictions.maxSize != null) {
  //       seasons[0].restrictions.trophySize = seasons[0].restrictions.trophySize.toString().concat(seasons[0].restrictions.trophyUnit);
  //       seasons[0].restrictions.minSize = seasons[0].restrictions.minSize.toString().concat(seasons[0].restrictions.minSizeUnit);
  //       seasons[0].restrictions.maxSize = seasons[0].restrictions.maxSize.toString().concat(seasons[0].restrictions.maxSizeUnit);
  //     }
  //   }
  //   else {
  //     seasons.forEach(season => {
  //       if (season.restrictions.trophySize != null && season.restrictions.minSize != null && season.restrictions.maxSize != null) {
  //         season.restrictions.trophySize = season.restrictions.trophySize.toString().concat(season.restrictions.trophyUnit);
  //         season.restrictions.minSize = season.restrictions.minSize.toString().concat(season.restrictions.minSizeUnit);
  //         season.restrictions.maxSize = season.restrictions.maxSize.toString().concat(season.restrictions.maxSizeUnit);
  //       }
  //     });
  //   }
  // }

  /**
   * Disable all form controls in case of inProhibited 
   * @param isProhibited 
   */
  disableFormControls(type: string) {
    if (type == 'PROHIBITED' || type == 'CUSTOM' || type == 'DEFAULT') {
      this.disableAllForm = true;
      this.disableAllFormControls = true;
    } else {
      this.disableAllForm = false;
      this.disableAllFormControls = false;
    }
  }

  findRegulationById(id: string) {
    var fetchMessageId = this.messageService.loading("Loading...").messageId;
    this.regulationService.findById(id).subscribe((res) => {
      this.messageService.remove(fetchMessageId);
      this.setRegulationValue(res.body);
    });
  }
  datePickerModelChanged(seasonDate: Date[]) {
  }
  setRegulationValue(res) {
    res.seasons.forEach(season => {
      season.restrictions.trophyUnit = season.restrictions.trophyUnit != null ? season.restrictions.trophyUnit : '\u0022';
      season.restrictions.maxSizeUnit = season.restrictions.maxSizeUnit != null ? season.restrictions.maxSizeUnit : '\u0022';
      season.restrictions.minSizeUnit = season.restrictions.minSizeUnit != null ? season.restrictions.minSizeUnit : '\u0022';
      season.restrictions.aggregateLimit = this.replaceNoneWithZero(season.restrictions.aggregateLimit);
      season.restrictions.bagLimit = this.replaceNoneWithZero(season.restrictions.bagLimit);
      season.restrictions.vesselLimit = this.replaceNoneWithZero(season.restrictions.vesselLimit);
      season.restrictions.trophyLimit = this.replaceNoneWithZero(season.restrictions.trophyLimit);
      season.restrictions.trophySize = this.replaceNoneWithZero(season.restrictions.trophySize);
      season.restrictions.minSize = this.replaceNoneWithZero(season.restrictions.minSize);
      season.restrictions.maxSize = this.replaceNoneWithZero(season.restrictions.maxSize);
      if (season && season.isDefault == false) {
        //  season.startDate = season.startDate.toUTCString();
        //  season.endDate = season.endDate.toUTCString();
        //var offset =  new Date().getTimezoneOffset();
        ;
        //  var seasonStartDate =  new Date(season.startDate); //1-1-2020 00:00:00
        //  var seasonEndDate =  new Date(season.endDate);  //31-1-2020 23:59:59
        //  if(offset >0){            
        //   seasonStartDate.setTime(seasonStartDate.valueOf() + (offset*60*1000));
        //  }else{           
        //   seasonEndDate.setTime(seasonEndDate.valueOf() + (offset*60*1000));
        //  }
        //   //season.endDate
        //   //seasonStartDate.setTime(seasonStartDate.getUTCMilliseconds());
        //   //seasonEndDate.setTime(seasonEndDate.getUTCMilliseconds());
        //season.startDateStr = season.startDateStr.replace(/-/g, '/');
        //season.endDateStr = season.endDateStr.replace(/-/g, '/');

        //season.startDateStr = this.formatDate(season.startDateStr);
        //season.endDateStr = this.formatDate(season.endDateStr);

        //   //season.endDate = seasonEndDate.getMinutes();
        const browser = this.getBrowserName();
        if (browser === 'safari') {

          // For safari
          season.startDate = this.datePipe.transform(season.startDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
          season.endDate = this.datePipe.transform(season.endDateStr, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
        }
        else {
          // For other browser
          season.startDate = this.datePipe.transform(season.startDateStr, 'yyyy-MM-ddTHH:mm:ss');
          season.endDate = this.datePipe.transform(season.endDateStr, 'yyyy-MM-ddTHH:mm:ss');
        }
        season.dateRange = [season.startDate, season.endDate];
      }
    });
    this.regulationModel = res;
    this.updateSeasonIndexes();
    this.disableFormControls(this.regulationModel.type);
  }

  getDefaultSeason() {
    var defaultSeason = null;
    if (this.regulationModel.seasons) {
      defaultSeason = this.regulationModel.seasons.filter(function (season) {
        return season.isDefault;
      })
    }
    if (defaultSeason) {
      defaultSeason = defaultSeason[0];
    }
    return defaultSeason;
  }

  replaceNoneWithZero(value) {
    return value == "None" ? null : value;
  }

  /**
   * This method is responsible for searching on region list.
   * @param search 
   */
  searchInRegionList(search: any) {
    this.filteredRegionListForSearch = this.utilityService.searchInRegionsWithCountryAndState(search, this.regionList);
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  onEditText(field: string) {
    if (field == 'addInfo') {
      this.setRegularField = this.regulationModel.addInfo;
    } else if (field == 'addInfo2') {
      this.setRegularField = this.regulationModel.addInfo2;
    }

    const regID = this.route.snapshot.params.regulationId;
    const feild_Name = field
    const feild_Value = this.setRegularField

    let bodyObj = {
      "adminModuleName": "REGULATION",
      "fieldName": `${feild_Name}`,
      "value": "",
      "targetLanguage": "ES",
      "targetText": `${feild_Value}`,
      "sourceLanguage": "ES"
    }
    //transalate spanice call service:
    this.regulationService.editRegulationFeild(regID, bodyObj).subscribe((res) => {
      this.translateSpane = res.body;
      this.regulationESForm.patchValue({
        fieldName: field,
        fieldValue: this.translateSpane['value']
      });
      switch (field) {
        case 'addInfo':
          this.spanishRegulationKey = 'Additional Info-1';
          this.isVisibleRegulation = true;
          break;
        case 'addInfo2':
          this.spanishRegulationKey = 'Additional Info-2';
          this.isVisibleRegulation = true;
          break;
        default:
          this.isVisibleRegulation = false;
          break;
      }
    }, error => {
      this.messageService.error("something missing, Try Again", { nzDuration: 4000 });
    })
  }
  onUpdateRegulation() {
    const fieldN = this.regulationESForm.get('fieldName').value;
    const fieldV = this.regulationESForm.get('fieldValue').value;
    const rID = this.route.snapshot.params.regulationId;
    let objs = {
      "adminModuleName": "REGULATION",
      "fieldName": `${fieldN}`,
      "value": "",
      "targetLanguage": "ES",
      "targetText": `${fieldV}`,
      "sourceLanguage": "ES"
    }
    this.updateTransalate.push(objs);
    this.regulationService.updateRegulationFeild(rID, this.updateTransalate).subscribe((res) => {
      const data = res.body;
      this.isVisibleRegulation = false;
      this.messageService.success("Successfully updated.", { nzDuration: 4000 });
    }, error => {
      this.messageService.error("Your Text is not update, Try Again", { nzDuration: 4000 });
    })

  }
  handleCancel() {
    this.isVisibleRegulation = false;
  }

  onAutoRegulationTranslate(event: any) {
    this.isAutoTranslateRegulationControl.setValue(event.target.checked);
 }
}

