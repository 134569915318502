import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { publishReplay, refCount } from 'rxjs/operators';
import { BaitsModel } from '../models/baits-model';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  private featuresUrl = 'api/v1/admin/features';
  private translationURL = 'api/v1/admin/translate/module-field';

  constructor(private httpRequest: HttpRequestService) {
  }

  fetchAllFeatures(): Observable<Response> {
    return this.httpRequest.get(this.featuresUrl);
  }

  saveFeatures(formData: FormData): Observable<Response> {
    return this.httpRequest.filePost(this.featuresUrl, formData);
  }

  updateFeatures(formData: FormData): Observable<Response> {
    return this.httpRequest.filePut(this.featuresUrl, formData);
  }

  deleteFeatures(id: String): Observable<Response> {
    return this.httpRequest.delete(this.featuresUrl + "/" + id);
  }

  findById(featureId:any): Observable<any> {
    return this.httpRequest.get(this.featuresUrl+'/'+featureId);
  }

  fetchTranslation(id: String, body : any): Observable<Response> {
    return this.httpRequest.post(this.translationURL + "/" + id, body);
  }

  updateTranslation(id: string, data: any): Observable<any> {
    const rgbURL = this.httpRequest.put(this.translationURL + '/'+id, data);
    return rgbURL;
  }
}

