import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { FishingMethodModel } from '../models/fishingMethod-model';

@Injectable({
  providedIn: 'root'
})
export class FishingMethodService {

  private stateUrl = 'api/v1/admin/fishing-method';

  constructor(private httpRequest: HttpRequestService) { }

  fishingMethodList(): Observable<any> {
    return this.httpRequest.get(this.stateUrl);
  }

  updateFishingMethod(fishingModel : FishingMethodModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl, fishingModel);
  }

  saveFishingMethod(fishingModel : FishingMethodModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl, fishingModel);
  }

  deleteFishingMethod(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + "/" + id);
  }
}
