import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { publishReplay, refCount } from 'rxjs/operators';
import { BaitsModel } from '../models/baits-model';

@Injectable({
  providedIn: 'root'
})
export class BaitsAndLuresService {

  private baitsNLuresUrl = 'api/v1/admin/baits';

  //baitsNLures: Observable<Response[]>;

  constructor(private httpRequest: HttpRequestService) {
  }

  // fetchAllBaitsNLures(): Observable<Response[]>{
  //    if(!this.baitsNLures){
  //      this.baitsNLures = this.httpRequest.get(this.baitsNLuresUrl).pipe(
  //       publishReplay(1),
  //       refCount()
  //      )
  //    }
  //    return this.baitsNLures;
  // }

  fetchAllBaitsNLures(): Observable<Response> {
    return this.httpRequest.get(this.baitsNLuresUrl);
  }

  saveBaits(formData: FormData): Observable<Response> {
    return this.httpRequest.filePost(this.baitsNLuresUrl, formData);
  }

  updateBaits(formData: FormData): Observable<Response> {
    return this.httpRequest.filePut(this.baitsNLuresUrl, formData);
  }

  deleteBaits(id: String): Observable<Response> {
    return this.httpRequest.delete(this.baitsNLuresUrl + "/" + id);
  }
}

