import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PingService {
  private stateUrl = 'api/v1/admin/ping';

  
  constructor(
    private httpRequest: HttpRequestService
  ) {

   }

   
  ping(): Observable<Response[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  
}
