import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { StateRecordModel } from '../models/stateRecord-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StateRecordService {

  private stateUrl = 'api/v1/admin/state-records';

  constructor(private httpRequest: HttpRequestService) { }

  saveStateRecord(stateRecordModel: StateRecordModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl, stateRecordModel);
  }

  updateStateRecord(stateRecordModel: StateRecordModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl, stateRecordModel);
  }

  deleteStateRecord(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + "/" + id);
  }

  stateRecordList(body: any): Observable<any> {
    return this.httpRequest.post(this.stateUrl+"/list", body);
  }

}
