import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private stateUrl = 'api/v1/admin/states';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

    states: any = [];

  fetchStatesOfCountry(country:String): Observable<Response[]> {
    return this.httpRequest.get(this.stateUrl+'/'+country);
  }

  async getAllLiteStates() {
    let response = await this.httpRequest.get(this.stateUrl+'/list').toPromise();
    if(response) {
      this.states = response.body;
    }
    return this.states;
  }
}
