import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private stateUrl = 'api/v1/admin/dashboard';

  constructor(private httpRequest: HttpRequestService) { }

  overView(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl +"/overview");
  }

  participantList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl +"/participant");
  }

  tripList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl +"/trip");
  }

  catcheList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl +"/catches");
  }
  
}
