import { FormControl, Validators, FormGroup } from '@angular/forms';
import { RestrictionModel } from './restriction-model';

export interface SeasonModel {
    index:number,
    dateRange: Date[],
    startDateStr: string,
    endDateStr: string,
    startDate: string,
    endDate: string,
    type: string,
    isDefault: boolean,
    restrictions: RestrictionModel
    id:string
}

export class SeasonFormBuilder {

    seasonModel: SeasonModel = {
        id: null,
        startDate: null,
        endDate: null,
        type: null,
        isDefault: true,
        restrictions : {} as RestrictionModel, 
        index:0,
        dateRange : null,
        startDateStr:null,
        endDateStr:null
    };

    seasonForm: FormGroup = new FormGroup({
        id : new FormControl(this.seasonModel.id),
        startDate: new FormControl(this.seasonModel.startDate, [Validators.required]),
        endDate: new FormControl(this.seasonModel.endDate, [Validators.required]),
        type: new FormControl(this.seasonModel.type, [Validators.required]),
        isDefault: new FormControl(this.seasonModel.isDefault, [Validators.required]),
        restrictions: new FormControl(this.seasonModel.restrictions, [Validators.required]),
    });

    constructor() {
        this.seasonForm.valueChanges.subscribe(val => {
            this.seasonModel.id = val.id
            this.seasonModel.endDate = val.endDate;
            this.seasonModel.startDate = val.startDate;
            this.seasonModel.type = val.type;
            this.seasonModel.isDefault = val.isDefault;
            this.seasonModel.restrictions = val.restrictions;
        })
    }
    setModelVals(res: any) {
        this.seasonForm.controls.id.setValue(res.id),
             this.seasonForm.controls.endDate.setValue(res.endDate),
            this.seasonForm.controls.startDate.setValue(res.startDate),
            this.seasonForm.controls.type.setValue(res.type),
            this.seasonForm.controls.isDefault.setValue(res.isDefault),
            this.seasonForm.controls.restrictions.setValue(res.restrictions)
    }
}