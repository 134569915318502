import { Component, OnInit } from '@angular/core';
import { ParticipantModelFormBuilder, ParticipantModel } from 'src/app/shared/models/participant-model';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { ParticipantService } from 'src/app/shared/services/participant.service';

@Component({
  selector: 'app-add-edit-participant',
  templateUrl: './add-edit-participant.component.html',
  styleUrls: ['./add-edit-participant.component.css']
})
export class AddEditParticipantComponent implements OnInit {

 
  participant: ParticipantModelFormBuilder = new ParticipantModelFormBuilder();
  participantModel: ParticipantModel = this.participant.participantModel;
  participantForm: FormGroup = this.participant.participantForm;
  processSubmitForm:boolean;
  
  ngOnInit() {
   }

  constructor(
    private messageService: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private participantService:ParticipantService
   )
      {
        if (this.route.snapshot.params.participantId) {
          if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
            this.participant.setModelVals(this.router.getCurrentNavigation().extras.state.participant);
          }
          else{
            this.findParticipantById(this.route.snapshot.params.participantId);
          }  
        }
  }

  /**
   * Add and update the region form
   */
  submitParticipantForm() {

      this.processSubmitForm = true;
      for (const i in this.participantForm.controls) {
          this.participantForm.controls[i].markAsDirty();
          this.participantForm.controls[i].updateValueAndValidity();
      }
      if (this.participantModel.id) {
          var updateMsgId = this.messageService.loading("Updating...", { nzDuration: 0 }).messageId;
          this.participantService.updateParticipant(this.participantModel.id,this.participantModel).subscribe((res) => {
              this.messageService.remove(updateMsgId);
              this.router.navigate(['/participants']);
          },
              err => {
                  this.processSubmitForm = false;
              }
          );
      } else {
          var saveMsgId = this.messageService.loading("Saving...", { nzDuration: 0 }).messageId;
          this.participantService.addParticipant(this.participantModel).subscribe((res) => {
              this.messageService.remove(saveMsgId);
              this.router.navigate(['/participants']);
          },
              err => {
                  this.processSubmitForm = false;
              }
          );
      }   
}

  /**
   * find participant by id
   */
  findParticipantById(participantId) {
    var loadMessage = this.messageService.loading("Loading...", {nzDuration:0}).messageId;
    this.participantService.findById(participantId).subscribe((res) => {
      this.participant.setModelVals(res.body);
      this.messageService.remove(loadMessage);
    });
  }

  /**
   * This method is responsible for redirecting to participant page
   */
  forwardToParticipantsPage(){
    this.router.navigate(['/participants']);
  }

}
