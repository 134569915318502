import { Component, OnInit } from '@angular/core';
import { FishingMethodService } from 'src/app/shared/services/fishing-method.service';
import { TableService } from 'src/app/shared/services/table.service';
import { FishingMethodModel } from 'src/app/shared/models/fishingMethod-model';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Data } from '@angular/router';

@Component({
  selector: 'app-fishing-method',
  templateUrl: './fishing-method.component.html',
  styleUrls: ['./fishing-method.component.css']
})
export class FishingMethodComponent implements OnInit {
  FishingMethodLoading: boolean;
  fishingMethodList: any;
  displayData: any;
  sortAttribute: any;
  formHeaderText: String;
  isVisible: boolean;
  fishingModel : FishingMethodModel;
  submitButton: boolean;

  constructor(private fishingMethosService : FishingMethodService,
    private tableService : TableService,
    private messageService : NzMessageService,
    private modalService : NzModalService) { 
      this.fishingModel = new FishingMethodModel();
    }

  ngOnInit() {
    this.fetchFishingMethodList();
  }

/**
 * Get all Fishing Method List
 */
  fetchFishingMethodList(){
    this.FishingMethodLoading = true;
    this.fishingMethosService.fishingMethodList().subscribe((res) => {
      this.FishingMethodLoading = false;
      this.fishingMethodList = res.body;
      this.displayData =  this.fishingMethodList;
    },
    (err) => {
      this.FishingMethodLoading = false;
    });
  }

  /**
   * Sorting List
   * @param sortAttribute 
   */
  sort(sortAttribute: any) {
    this.sortAttribute = sortAttribute;
    this.displayData = this.tableService.sort(sortAttribute, this.fishingMethodList);
  }

  /**
   * Open Model window for Add or Update
   * @param fishingData 
   */
  openFishingMethodForm(fishingData: any) {
    this.isVisible = true;
    if (fishingData == null) {
      this.formHeaderText = 'Create New Fishing Method';
      this.fishingModel = new FishingMethodModel();
      this.submitButton = true;
      
    } else {
      this.formHeaderText = 'Update Fishing Method';
      const fishingClone = Object.assign({}, fishingData);
      this.fishingModel = fishingClone;
      this.submitButton = false;
    }
  }

  /**
   * handle cancel button on model window
   */
  onCancel() {
    this.isVisible = false;
  }

  /**
   * handle Submit button on Model Window 
   */
  saveFishingMethod() {
    if (this.fishingModel.id != null) {
      var updateMsg = this.messageService.loading('Updating...').messageId;
      this.fishingMethosService.updateFishingMethod(this.fishingModel).subscribe(res => {
        this.messageService.remove(updateMsg);
        this.modalService.closeAll();
        this.fetchFishingMethodList();
      });
    } else {
      this.submitButton = true;
      var saveMsg = this.messageService.loading('Saving...').messageId;
      this.fishingMethosService.saveFishingMethod(this.fishingModel).subscribe(res => {
        this.messageService.remove(saveMsg);
        this.modalService.closeAll();
        this.fetchFishingMethodList();
      });
    }
  }

  /**
   * Disable Submit button on model window
   */
  OnSubmitButton() {
    if (this.fishingModel.name != null && this.fishingModel.name != "") {
      this.submitButton = false;
    } else {
      this.submitButton = true;
    }
  }

  /**
   * Delete Confirmation Model
   * @param id 
   */
  DeleteFishingMethodForm(id): void {
    this.modalService.confirm({
      nzTitle: 'Delete this Fishing Method ?',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => this.deleteFishingMethod(id),
      nzCancelText: 'No'
    });
  }

  /**
   * Delete Fishing Method
   * @param id 
   */
  deleteFishingMethod(id) {
    var deleteMsg = this.messageService.loading("Deleting...").messageId;
    this.fishingMethosService.deleteFishingMethod(id).subscribe((res) => {
      this.messageService.remove(deleteMsg);
      this.removeMethodFromList(id);
    });
  }

  /**
   * Remove Fishing Method from list
   * @param id 
   */
  removeMethodFromList(id) {
    this.fishingMethodList.forEach((method, index) => {
      if (method.id === id) {
        this.fishingMethodList.splice(index, 1);
        return false;
      }
    });
    this.displayData = this.fishingMethodList;
  }
}
