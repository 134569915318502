import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SeasonModel } from "./season-model";

export interface RegulationModel {

    regions:any[],
    species:any[],
 //   isProhibited: boolean,
    record: string,
    addInfo:string,
    addInfo2: string,
    measurementId:string,
    seasons:SeasonModel[],
    id: string,
    type: string,
    customLimit: string,
    hideSeason: boolean,
   // isDefault: boolean,
    defaultLimit: string,
    watchList: boolean,
    actualReguId: String
}

export class RegulationModelFormBuilder {

    regulationModel: RegulationModel = {
        regions: []  as any,
        species: [] as any,
    //    isProhibited: false,
        record: null,
        addInfo: null,
        addInfo2: null,
        measurementId: null,
        seasons: [],
        id: null,
        type: 'NORMAL',
        customLimit: null,
        hideSeason: false,
       // isDefault: false
       defaultLimit: null,
       watchList: false,
       actualReguId: null
    };

    regulationForm: FormGroup = new FormGroup({
        regions: new FormControl(this.regulationModel.regions,[Validators.required]),
        species: new FormControl(this.regulationModel.species,[Validators.required]),
     //   isProhibited: new FormControl(this.regulationModel.isProhibited,[Validators.required]),
        record: new FormControl(this.regulationModel.record),
        addInfo: new FormControl(this.regulationModel.addInfo),
        addInfo2: new FormControl(this.regulationModel.addInfo2),
        measurementId: new FormControl(this.regulationModel.measurementId),
        seasons: new FormControl(this.regulationModel.seasons),
        type: new FormControl(this.regulationModel.type),
        customLimit: new FormControl(this.regulationModel.customLimit),
        hideSeason: new FormControl(this.regulationModel.hideSeason),
      //  isDefault: new FormControl(this.regulationModel.isDefault)
      defaultLimit: new FormControl(this.regulationModel.defaultLimit),
      watchList: new FormControl(this.regulationModel.watchList),
      actualReguId: new FormControl(this.regulationModel.actualReguId)
    });

    constructor() {
        this.regulationForm.valueChanges.subscribe(val => {
            this.regulationModel.regions = val.regions;
            this.regulationModel.species = val.species;
         //   this.regulationModel.isProhibited = val.isProhibited;
            this.regulationModel.record = val.record;
            this.regulationModel.addInfo = val.addInfo;
            this.regulationModel.addInfo2 = val.addInfo2;
            this.regulationModel.measurementId = val.measurementId;
            this.regulationModel.seasons = val.seasons;
            this.regulationModel.id = val.id;
            this.regulationModel.type = val.type;
            this.regulationModel.customLimit = val.customLimit;
            this.regulationModel.hideSeason = val.hideSeason;
         //   this.regulationModel.isDefault = val.isDefault;
            this.regulationModel.defaultLimit = val.defaultLimit;
            this.regulationModel.watchList = val.watchList;
            this.regulationModel.actualReguId = val.actualReguId
        })
    }
    
    setModelVals(res: any) {
        this.regulationForm.controls.regions.setValue(res.regions);
        this.regulationForm.controls.species.setValue(res.species);
     //   this.regulationForm.controls.isProhibited.setValue(res.isProhibited);
        this.regulationForm.controls.record.setValue(res.record);
        this.regulationForm.controls.addInfo.setValue(res.addInfo);
        this.regulationForm.controls.addInfo2.setValue(res.addInfo2);
        this.regulationForm.controls.measurementId.setValue(res.measurementId);
        this.regulationForm.controls.seasons.setValue(res.seasons);
        this.regulationForm.controls.type.setValue(res.type);
        this.regulationForm.controls.customLimit.setValue(res.customLimit);
        this.regulationForm.controls.hideSeason.setValue(res);
      //  this.regulationForm.controls.isDefault.setValue(res.isDefault);
        this.regulationForm.controls.defaultLimit.setValue(res.defaultLimit);
        this.regulationForm.controls.watchList.setValue(res.watchList);
        this.regulationForm.controls.actualReguId.setValue(res.actualReguId);
    }
  
}