import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { RegionFormBuilder, RegionModel } from 'src/app/shared/models/region-model';
import { CountryService } from 'src/app/shared/services/country.service';
import { StateService } from 'src/app/shared/services/state.service';
import { RegionService } from 'src/app/shared/services/region.service';

@Component({
  selector: 'app-add-edit-region',
  templateUrl: './add-edit-region.component.html',
  styleUrls: ['./add-edit-region.component.css']
})
export class AddEditRegionComponent implements OnInit {

  region: RegionFormBuilder = new RegionFormBuilder();
  regionModel: RegionModel = this.region.regionModel;
  regionForm: FormGroup = this.region.regionForm;
  countries = [];
  states = [];
  loadingStates: boolean = false;
  kmlFile: any = null;
  fileList: UploadFile[] = [];
  fileName;
  isStateLoaded:boolean= false
  processSubmitForm:boolean;
  
  ngOnInit() {
    var isEditMode = this.route.snapshot.params.regionId ? true : false;
    if(isEditMode){
      if(this.regionModel.id){
          this.countries.push(this.regionModel.country)
          this.states.push(this.regionModel.state);
          this.fileName = this.regionModel.fileName;
          this.countryChange(this.regionModel.country, false);
      }else{
        this.findRegionById(this.route.snapshot.params.regionId);
      }    
    }
    this.fetchCountries();
  }

  constructor(private countryService: CountryService,
    private stateService: StateService,
    private regionService: RegionService,
    private messageService: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,

   ) {
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) {
          this.region.setModelVals(this.router.getCurrentNavigation().extras.state.region);
        }
      });
  }

 compareFn(obj1: RegionModel, obj2: RegionModel) {
   return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
 }


  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  /**
   * Add and update the region form
   */
  submitRegionForm() {

    this.processSubmitForm = true;
    
    for (const i in this.regionForm.controls) {
      this.regionForm.controls[i].markAsDirty();
      this.regionForm.controls[i].updateValueAndValidity();
    }

    // Preparing Model and file to formData
    var regionDTOBlob = new Blob([JSON.stringify(this.regionModel)], {
      type: 'application/json'
    });
    let formData: FormData = new FormData();
    formData.append('regionDTO', regionDTOBlob);
    this.fileList.forEach((file: any) => {
      formData.append('kmlFile', file);
    });
  
    // Add or Update will be proccesed from here
   
        if (this.regionModel.id) {
                var updateMsgId = this.messageService.loading("Updating...", {nzDuration:0}).messageId;
                this.regionService.updateRegion(formData).subscribe((res) => {
                this.messageService.remove(updateMsgId);
                this.router.navigate(['/regions']);
              },
              err => {
                      this.processSubmitForm = false;
              }
              );
        } else {
            var saveMsgId = this.messageService.loading("Saving...", {nzDuration:0}).messageId;
            this.regionService.addRegion(formData).subscribe((res) => {
            this.messageService.remove(saveMsgId);
            this.router.navigate(['/regions']);
          },
            err => {
                      this.processSubmitForm = false;
              }
          );
      }   
}


  /**
   * Fetch all the countries
   */
 async fetchCountries() {
    //var countries;
  //  this.countryService.fetchCountries().subscribe((res) => {
     this.countries = await this.countryService.fetchCountries();
   // });
  }

  /**
   * When the country change states will be fetched
   */
  countryChange($event, resetSelectedState) {

    //this.validateState++;
    if ($event != null) {
      this.loadingStates = true;
      //this.states = [{id:"", name:""}];
      if(resetSelectedState){
        this.states = [];
        this.regionForm.controls.state.setValue(null);
      }
      this.stateService.fetchStatesOfCountry($event.id).subscribe((res) => {
        this.states = res;
        this.loadingStates = false;
      });
    }
  }

  /**
   * find region by id
   */
  findRegionById(regionId) {
    var loadMessage = this.messageService.loading("Loading...", {nzDuration:0}).messageId;
    this.regionService.findById(regionId).subscribe((res) => {
      this.region.setModelVals(res.body);
      //this.country = this.regionModel.country;
      this.fileName = this.regionModel.fileName;
      this.messageService.remove(loadMessage);
    });
  }

  /**
   * This method is responsible for redirecting to regions page
   */
  forwardToRegionsPage(){
    this.router.navigate(['/regions']);
  }


  kmlFileFilter: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {
        if(fileList[0].name && !fileList[0].name.toLowerCase().endsWith("kml")){
          this.messageService.error("Only KML file upload is supported", { nzDuration: 2000 });
          return []; 
        }
        return fileList;
      }
    }];


	//  checkExistStateOfCountry(){
  //     console.log(this.regionModel.state);
  //     this.states.includes(this.regionModel.state);
  //     this.states.forEach( state => {
  //          if (state.code.includes(this.regionModel.state)) {
  //              this.validStateOfCountry = true;
  //          }
  //     });
  //  }
}
