import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from './http-request.service';

@Injectable({
  providedIn: 'root'
})
export class ScansService {
  private scansUrl = 'api/v1/admin/scans';
  
  constructor(private httpRequest: HttpRequestService) { }

  
  getAllScans(body:any):  Observable<any> {
    return this.httpRequest.post(this.scansUrl+"/list",body);
  }

  migrateScans(body : any): Observable<any> {
    return this.httpRequest.post(this.scansUrl + "/catch-migration", body);
  }

  downloadScansImgs(body): Observable<any> {
    return this.httpRequest.fileDownload(this.scansUrl + "/download-scans-image", body);
  }

}
