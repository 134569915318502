import { Injectable } from '@angular/core';
import { HttpRequestService } from './http-request.service';
import { Observable } from 'rxjs';
import { ParticipantModel } from '../models/participant-model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  private stateUrl = 'api/v1/admin/participants';
  constructor(
    private httpRequest: HttpRequestService
  ) { }

  addParticipant(participantModel:ParticipantModel): Observable<Response> {
    return this.httpRequest.post(this.stateUrl,participantModel);
  }

  participantList(): Observable<Response> {
    return this.httpRequest.get(this.stateUrl);
  }

  updateParticipant(id:String,participantModel:ParticipantModel): Observable<Response> {
    return this.httpRequest.put(this.stateUrl + '/'+id,participantModel);
  }

  deleteById(id: String): Observable<Response> {
    return this.httpRequest.delete(this.stateUrl + '/' + id)
  }

  findById(participantId:any): Observable<any> {
    return this.httpRequest.get(this.stateUrl+'/'+participantId);
  }

}
