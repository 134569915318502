import { FormGroup, FormControl, Validators } from "@angular/forms";

export interface ParticipantModel {
    email: string;
    name: string;
    mobile:string;
    id:string;
    status:string;
}

export class ParticipantModelFormBuilder {

    participantModel: ParticipantModel = {
        email: null,
        name: null,
        mobile: null,
        id:null,
        status:null

    };

    participantForm: FormGroup = new FormGroup({
        email: new FormControl(this.participantModel.email, [Validators.required,Validators.email]),
        name: new FormControl(this.participantModel.name,Validators.required),
        mobile: new FormControl(this.participantModel.mobile),
        id: new FormControl(this.participantModel.id),
    //    status: new FormControl(this.participantModel.status),

    });

    constructor() {
            this.participantForm.valueChanges.subscribe(val => {
                this.participantModel.email = val.email;
                this.participantModel.mobile = val.mobile;
                this.participantModel.id = val.id;
                this.participantModel.name = val.name;
              //  this.participantModel.status = val.status;
        })
    }


   setModelVals(res:any) {
        this.participantForm.controls.email.setValue(res.email);
        this.participantForm.controls.mobile.setValue(res.mobile);
        this.participantForm.controls.id.setValue(res.id);
        this.participantForm.controls.name.setValue(res.name);
     //   this.participantForm.controls.status.setValue(res.status);
    }
}